import "./Details.css";
import Button from "../../../components/Button";
import React from "react";
import BannerInterior from "../../../components/BannerInterior/BannerInterior";
import banner from "../../../img/banners/redes-oficinas.jpg";
import { Trans } from 'react-i18next';


function Details(props) {
    function ActiveScreen() {
        window.scrollTo({ top: 0, left: 0, behavior: "instant", });
        return (<div class="row">
            <div class="col-md-4">
                <div class="ratio ratio-4x3">
                    {<img class="img-cover" alt={""} src={props.currentPost.image} />}
                </div>
            </div>
            <div class="col-md-8">
                <div dangerouslySetInnerHTML={{ __html: props.currentPost.body }}></div>
            </div>
            <div class="col-12 mt-3 text-end">
                <div className="buttons">
                    <Button onClick={() => { props.navigate("/Rede-de-Oficinas") }}
                        type="button"
                        buttonStyle="button-loginscreen-solid"
                        buttonSize="button-size-large">
                        <Trans i18nKey="Keywords.makeSchedule" />
                    </Button>
                    <Button onClick={() => { props.setViewedPage(1); }}
                        type="button" buttonStyle="btn-black" buttonSize="button-size-large">
                        <Trans i18nKey="Keywords.back" />
                    </Button>
                </div>
            </div>
        </div>);
    }

    return (
        <div>
            <BannerInterior name={props.currentPost.title} imagem={banner} />
            <div class="container my-5">{ActiveScreen()}</div>
        </div>
    );
}

export default Details;
