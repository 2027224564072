import "./Tag.css";
import Button from "../../../../components/Button";
import { useNavigate } from "react-router-dom";
import React from "react";
import BannerInterior from "../../../../components/BannerInterior/BannerInterior";
import banner from "../../../../img/banners/redes-oficinas.jpg";
import etiquetaPneus from "../../../../img/pneus/etiqueta-pneus/etiqueta-pneus.jpg";
import backgroundPneu from "../../../../img/pneus/etiqueta-pneus/pneu-bg.png";

function Tag() {
  let navigate = useNavigate();

  return (
    <div className="App">
      <BannerInterior name="Etiquetas de Pneus" imagem={banner} />
      <div class="section compromisso py-20">
        <img
          class="bg-etiquetas position-absolute bottom-0"
          src={backgroundPneu}
        />
        <div class="container">
          <div class="row g-5">
            <div class="col-lg-6 col-md-12">
              <img src={etiquetaPneus} />
            </div>
            <div class="col-lg-6">
              <div class="section_title fw-bold mb-4">
                Etiquetas de Pneus da União Europeia
              </div>
              <div class="detail mb-5">
                <p>
                  A nova etiqueta de pneus da EU fornece informações ambientais
                  e de segurança importantes de cada pneu. Pode ajudar a
                  comparar pneus quanto à aderência em piso molhado, eficiência
                  de combustível e ruído.
                </p>
              </div>
              <div class="accordion accordion-flush" id="accordionEtiqueta">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOneEtiqueta">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOneEtiqueta"
                      aria-expanded="false"
                      aria-controls="flush-collapseOneEtiqueta"
                    >
                      Onde a posso encontrar?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOneEtiqueta"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOneEtiqueta"
                    data-bs-parent="#accordionEtiqueta"
                  >
                    <div class="accordion-body">
                      <p>
                        É obrigatório por lei incluir uma etiqueta de pneus da
                        EU e deverá poder vê-la no pneu. Se não a conseguir
                        encontrar, contate a sua oficina RedService ou procure
                        no nosso site.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwoEtiqueta">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwoEtiqueta"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwoEtiqueta"
                    >
                      A eficiência de combustível
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwoEtiqueta"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwoEtiqueta"
                    data-bs-parent="#accordionEtiqueta"
                  >
                    <div class="accordion-body">
                      <p>
                        Sabia que os pneus representam até 20% do consumo de
                        combustível do seu veículo? A escolha de pneus com uma
                        classificação de eficiência de combustível elevada irá
                        proporcionar-lhe mais quilómetros em cada depósito e
                        menos emissões de CO2.
                      </p>
                      <p>
                        <b>Como é classificada a eficiência de combustível?</b>
                      </p>
                      <p>
                        A eficiência de combustível é classificada de A a G numa
                        escala codificada por cores.
                      </p>
                      <ul>
                        <li>
                          A (verde) = classificação de eficiência de combustível
                          mais alta
                        </li>
                        <li>
                          G (vermelho) = classificação de eficiência de
                          combustível mais baixa
                        </li>
                      </ul>
                      <p>
                        A classificação D não é utilizada para veículos de
                        passageiros.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThreeEtiqueta">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThreeEtiqueta"
                      aria-expanded="false"
                      aria-controls="flush-collapseThreeEtiqueta"
                    >
                      A aderência em piso molhado
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThreeEtiqueta"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingThreeEtiqueta"
                    data-bs-parent="#accordionEtiqueta"
                  >
                    <div class="accordion-body">
                      <p>
                        Os pneus com uma classificação de aderência em piso
                        molhado elevada irão travar mais rapidamente ao aplicar
                        os travões a fundo.
                      </p>
                      <p>
                        <b>Como é classificada a aderência em piso molhado?</b>
                      </p>
                      <p>
                        A aderência em piso molhado é classificada de A a F:
                      </p>
                      <ul>
                        <li>A (verde) = classificação mais alta</li>
                        <li>F (vermelho) = classificação mais baixa</li>
                      </ul>
                      <p>
                        As classificações D e G não são utilizadas para veículos
                        de passageiros.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFourEtiqueta">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFourEtiqueta"
                      aria-expanded="false"
                      aria-controls="flush-collapseFourEtiqueta"
                    >
                      Ruído
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFourEtiqueta"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingFourEtiqueta"
                    data-bs-parent="#accordionEtiqueta"
                  >
                    <div class="accordion-body">
                      <p>
                        Parte do ruído de passagem de um veículo é gerado pelos
                        pneus. Ao escolher pneus com uma boa classificação de
                        ruído, poderá reduzir o impacto da sua condução no
                        ambiente circundante. O que é medido pela classificação
                        de ruído da UE?
                      </p>
                      <p>
                        A classificação de ruído da UE mede as emissões de ruído
                        externo do pneu em decibéis.
                      </p>
                      <p>
                        Dado que muitas pessoas não estão familiarizadas com os
                        valores em decibéis, é apresentada uma imagem de um
                        altifalante com ondas pretas para representar o nível de
                        ruído do pneu.
                      </p>
                      <p>
                        Quanto mais ondas apresentar, mais ruidoso é o pneu.
                      </p>
                      <ul>
                        <li>
                          1 onda preta: Silencioso (3 dB ou mais, abaixo do
                          limite europeu)
                        </li>
                        <li>
                          2 ondas pretas: Moderado (entre o limite europeu e
                          menos de 3 dB)
                        </li>
                        <li>
                          3 ondas pretas: Ruidoso (acima do limite europeu)
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tag;
