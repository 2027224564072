import "./Forgot.css";
import React, { useState } from "react";
import BannerInterior from "../../../components/BannerInterior/BannerInterior";
import banner from "../../../img/banners/redes-oficinas.jpg";
import Button from "../../../components/Button";
import toast, { Toaster } from "react-hot-toast";
import axios from 'axios';
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from "react";
import { Loading } from "../../../utils/Utilities";

function Forgot() {
    var emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //Conte @ e .
    const [resetPw, setResetPw] = useState({ token: "", password: "", confirmPassword: "" });
    const [sendReset, setSendReset] = useState({ email: "" });
    const [view, setView] = useState("sendReset");
    const [searchParams, setSearchParams] = useSearchParams();
    const [load, setLoad] = useState(false);
    const [haveToken, setHaveToken] = useState(false);

    const dispatch = useDispatch();
    let navigate = useNavigate();



    const handleResetPwChange = (e) => {
        var { id, value } = e.target;
        setResetPw({ ...resetPw, [id]: value });
    }

    const handleSendResetChange = (e) => {
        var { id, value } = e.target;
        setSendReset({ ...sendReset, [id]: value });
    }


    useEffect(() => {
        var email = searchParams.get("email")
        var token = searchParams.get("token")
        if (email !== null) {
            setView("sendReset");
            setSendReset({ ...setSendReset, email: email });
        } else if (token !== null) {
            setView("resetPw");
            setResetPw({ ...resetPw, token: token })
            setHaveToken(true);
        } else {
            setView("sendReset");
        }
    }, []);

    const forgotPassword = (data) => {
        setLoad(true);
        axios.post(`${global.api_url}Accounts/forgot-password`, data)
            .then((response) => {
                setLoad(false);
                if (response.status === 200) {
                    toast.success("Email enviado com sucesso!", { duration: 4000 });
                }
            }).catch(error => {
                setLoad(false);
                toast.error("Ocurreu um erro a enviar o email.", { duration: 4000 });
            })
    }

    const validate = () => {

        if (resetPw.token.trim() === "") {
            toast.error("Tem de preencher o token.");
        } else if (resetPw?.password.trim() === "") {
            toast.error("Tem de preencher uma password");
        } else if (resetPw?.confirmPassword.trim() === "") {
            toast.error("Tem de preencher a confirmação da password");
        } else if (resetPw?.password.trim().length < 6) {
            toast.error("A password tem de conter pelo menos 6 carateres.");
        } else if (resetPw?.confirmPassword.trim() !== resetPw?.password.trim()) {
            toast.error("As password não correspondem");
        } else {
            return true;
        }
    };

    const resetPassword = (data) => {
        setLoad(true);
        axios.post(`${global.api_url}Accounts/reset-password`, data)
            .then((response) => {
                setLoad(false);
                if (response.status === 200) {
                    toast.success("Password alterada com sucesso!", { duration: 4000 });
                    navigate("/login");
                }
            }).catch(error => {
                setLoad(false);
                toast.error("Ocorreu um erro a efetuar a alteração de password.", { duration: 4000 });
            })
    }


    return (
        <div className="section">
            <BannerInterior name="Recuperar Password" imagem={banner} />

            {view === 'sendReset' &&
                <div class="section join-us py-5">
                    <div class="container py-5">
                        <div class="row pb-0">
                            <div class="col-md-12">
                                <div class="section_sub_title text-red text-uppercase mb-4">
                                    Recuperar password
                                </div>
                            </div>
                        </div>

                        <div class="row g-4 py-4">
                            <div class="col-lg-6 col-md-12">

                                <div class="col-12 py-1">
                                    <div class="label text-gray text-uppercase">Email</div>
                                    <div className="Step1-displayshop">
                                        <input
                                            onChange={handleSendResetChange}
                                            class="form-control"
                                            type="text"
                                            id="email"
                                            value={sendReset?.email}
                                        />
                                    </div>
                                </div>

                                {Loading(load)}
                                {load === false &&
                                    <div class="col-lg-12 py-1">
                                        <div className="buttons">
                                            <div class="d-flex">
                                                <Button
                                                    onClick={() => {
                                                        forgotPassword(sendReset);
                                                    }}
                                                    type="button"
                                                    buttonStyle="button-loginscreen-solid"
                                                    buttonSize="button-size-large"
                                                >
                                                    Enviar
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        navigate("/login")
                                                    }}
                                                    type="button"
                                                    buttonStyle="btn-black"
                                                    buttonSize="button-size-large"
                                                >
                                                    Cancelar
                                                </Button>
                                            </div>
                                        </div>


                                    </div>
                                }
                            </div>

                        </div>
                    </div>

                </div>
            }

            {view === 'resetPw' &&
                <div class="section join-us py-5">
                    <div class="container py-5">
                        <div class="row pb-0">
                            <div class="col-md-12">
                                <div class="section_sub_title text-red text-uppercase mb-4">
                                    Redefinir nova password
                                </div>
                            </div>
                        </div>

                        <div class="row g-4 py-4">
                            <div class="col-lg-6 col-md-12">

                                <div class="col-12 py-1">
                                    <div class="label text-gray text-uppercase">Token</div>
                                    <div className="Step1-displayshop">
                                        <input
                                        onChange={handleResetPwChange}
                                        class="form-control"
                                        type="text"
                                        id="token"
                                        value={resetPw?.token}
                                        disabled={haveToken}
                                        />
                                    </div>
                                </div>
                                <div class="col-lg-12 py-1">
                                    <div class="label text-gray text-uppercase">Nova Password</div>
                                    <div className="Step1-inputbox">
                                        <div className="Step1-datecol">
                                            <input
                                                onChange={handleResetPwChange}
                                                type="password"
                                                id="password"
                                                value={resetPw?.password}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 py-1">
                                    <div class="label text-gray text-uppercase">Confirmação Nova Password</div>
                                    <div className="Step1-inputbox">
                                        <div className="Step1-datecol">
                                            <input
                                                onChange={handleResetPwChange}
                                                type="password"
                                                id="confirmPassword"
                                                value={resetPw?.confirmPassword}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {Loading(load)}
                                {load === false &&
                                    <div class="col-lg-12 py-1">
                                        <div className="buttons">
                                            <div class="d-flex">
                                                <Button
                                                    onClick={() => {
                                                        if (validate()) {
                                                            resetPassword(resetPw);
                                                        }
                                                    }}
                                                    type="button"
                                                    buttonStyle="button-loginscreen-solid"
                                                    buttonSize="button-size-large"
                                                >
                                                    Submeter
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        navigate("/login")
                                                    }}
                                                    type="button"
                                                    buttonStyle="btn-black"
                                                    buttonSize="button-size-large"
                                                >
                                                    Cancelar
                                                </Button>
                                            </div>
                                        </div>


                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>

    );

}
export default Forgot;
