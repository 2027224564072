import React from 'react';
import { Badge, Media } from "reactstrap";
import Moment from 'moment';
import 'moment/locale/pt'
Moment.locale('pt');


const ScheduleRow = ({ schedule, scheduleDetail }) => {

    const colorstatus = (status) => {
        switch (status) {
            case 'Cancelado':
                return <div style={{ color: 'red' }}>{schedule?.status}</div>
            case 'Aceite':
                return <div style={{ color: 'orange' }}>{schedule?.status}</div>
            case 'Entregue':
                return <div style={{ color: 'green' }}>{schedule?.status}</div>
            case 'Concluido':
                return <div style={{ color: 'darkolivegreen' }}>{schedule?.status}</div>
            case 'Oficina':
                return <div style={{ color: 'blue' }}>{schedule?.status}</div>
            default:
                return status;

        }

    }


    return (
        <tr>
            <th scope="row">
                {schedule?.workShopName}
            </th>
            <td>
                {Moment(schedule?.start).format('LLL')}
            </td>
            <td>
                {Moment(schedule?.end).format('LLL')}
            </td>
            <td>
                {colorstatus(schedule?.status)}
            </td>
            <td>
                {schedule?.plate}
            </td>
            <td onClick={(e) => { e.preventDefault(); scheduleDetail(schedule); }}>
                <i class="fa-solid fa-eye" style={{ color: '#E2241A' }}></i>
            </td>
        </tr>
    );
}

export default ScheduleRow;