import "./Services.css";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import React from "react";
import BannerInterior from "../../../components/BannerInterior/BannerInterior";
import banner from "../../../img/banners/redes-oficinas.jpg";
import servico1 from "../../../img/services/service-1.jpg";
import servico2 from "../../../img/services/service-2.jpg";
import servico3 from "../../../img/services/service-3.jpg";
import servico4 from "../../../img/services/service-4.jpg";
import servico5 from "../../../img/services/service-5.jpg";
import servico6 from "../../../img/services/service-6.jpg";
import imgSeparatorLeft from '../../../img/main/block3/img-1.jpg';
import imgSeparatorRight from '../../../img/main/block3/img-2.jpg';
import { useTranslation, Trans } from 'react-i18next';

function Services() {
    let navigate = useNavigate();
    const handleButtonClick = () => { navigate("/Rede-de-Oficinas"); };
    const { t } = useTranslation();

    const services = t("RedService.Services.BlockServices", { returnObjects: true });

    return (
        <div className="section footer-no-clip">
            <BannerInterior /*name="Serviços"*/ name={t("RedService.Services.title")} imagem={banner} />
            <div class="container py-20">
                <div class="row g-5">
                    {Array.isArray(services) && services.map(serv => {
                        return (
                            <div class="col-md-6 col-lg-4 service" key={Math.random()}>
                                <div class="ratio ratio-16x9">
                                    <img class="img-cover" src={serv.image} />
                                </div>
                                <div class="category bg-red d-flex align-items-center">
                                    <div class="category-text text-white">{serv.title}</div>
                                </div>
                                <div class="resume mt-3">
                                    {serv.description}
                                </div>
                            </div>
                        );
                    })}







                    {/*<div class="col-md-6 col-lg-4 service">*/}
                    {/*    <div class="ratio ratio-16x9">*/}
                    {/*        <img class="img-cover" src={servico1} />*/}
                    {/*    </div>*/}
                    {/*    <div class="category bg-red d-flex align-items-center">*/}
                    {/*        <div class="category-text text-white">Diagnóstico Eletrónico</div>*/}
                    {/*    </div>*/}
                    {/*    <div class="resume mt-3">*/}
                    {/*        Aqui fica uma pequena descrição sobre este serviço da RedService,*/}
                    {/*        aqui fica uma pequena descrição.*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div class="col-md-6 col-lg-4 service">*/}
                    {/*    <div class="ratio ratio-16x9">*/}
                    {/*        <img class="img-cover" src={servico2} />*/}
                    {/*    </div>*/}
                    {/*    <div class="category bg-red d-flex align-items-center">*/}
                    {/*        <div class="category-text text-white">*/}
                    {/*            Manutenção Periódica e Check-up*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div class="resume mt-3">*/}
                    {/*        Aqui fica uma pequena descrição sobre este serviço da RedService,*/}
                    {/*        aqui fica uma pequena descrição.*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div class="col-md-6 col-lg-4 service">*/}
                    {/*    <div class="ratio ratio-16x9">*/}
                    {/*        <img class="img-cover" src={servico3} />*/}
                    {/*    </div>*/}
                    {/*    <div class="category bg-red d-flex align-items-center">*/}
                    {/*        <div class="category-text text-white">Serviços Rápidos</div>*/}
                    {/*    </div>*/}
                    {/*    <div class="resume mt-3">*/}
                    {/*        Aqui fica uma pequena descrição sobre este serviço da RedService,*/}
                    {/*        aqui fica uma pequena descrição.*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div class="col-md-6 col-lg-4 service">*/}
                    {/*    <div class="ratio ratio-16x9">*/}
                    {/*        <img class="img-cover" src={servico4} />*/}
                    {/*    </div>*/}
                    {/*    <div class="category bg-red d-flex align-items-center">*/}
                    {/*        <div class="category-text text-white">Mecânica Geral</div>*/}
                    {/*    </div>*/}
                    {/*    <div class="resume mt-3">*/}
                    {/*        Aqui fica uma pequena descrição sobre este serviço da RedService,*/}
                    {/*        aqui fica uma pequena descrição.*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div class="col-md-6 col-lg-4 service">*/}
                    {/*    <div class="ratio ratio-16x9">*/}
                    {/*        <img class="img-cover" src={servico5} />*/}
                    {/*    </div>*/}
                    {/*    <div class="category bg-red d-flex align-items-center">*/}
                    {/*        <div class="category-text text-white">Climatização</div>*/}
                    {/*    </div>*/}
                    {/*    <div class="resume mt-3">*/}
                    {/*        Aqui fica uma pequena descrição sobre este serviço da RedService,*/}
                    {/*        aqui fica uma pequena descrição.*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div class="col-md-6 col-lg-4 service">*/}
                    {/*    <div class="ratio ratio-16x9">*/}
                    {/*        <img class="img-cover" src={servico6} />*/}
                    {/*    </div>*/}
                    {/*    <div class="category bg-red d-flex align-items-center">*/}
                    {/*        <div class="category-text text-white">Travagem</div>*/}
                    {/*    </div>*/}
                    {/*    <div class="resume mt-3">*/}
                    {/*        Aqui fica uma pequena descrição sobre este serviço da RedService,*/}
                    {/*        aqui fica uma pequena descrição.*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div class="col-md-6 col-lg-4 service">*/}
                    {/*    <div class="ratio ratio-16x9">*/}
                    {/*        <img class="img-cover" src={servico3} />*/}
                    {/*    </div>*/}
                    {/*    <div class="category bg-red d-flex align-items-center">*/}
                    {/*        <div class="category-text text-white">*/}
                    {/*            Eletricidade/Eletrónica*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div class="resume mt-3">*/}
                    {/*        Aqui fica uma pequena descrição sobre este serviço da RedService,*/}
                    {/*        aqui fica uma pequena descrição.*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div class="col-md-6 col-lg-4 service">*/}
                    {/*    <div class="ratio ratio-16x9">*/}
                    {/*        <img class="img-cover" src={servico2} />*/}
                    {/*    </div>*/}
                    {/*    <div class="category bg-red d-flex align-items-center">*/}
                    {/*        <div class="category-text text-white">Serviços de Pneus</div>*/}
                    {/*    </div>*/}
                    {/*    <div class="resume mt-3">*/}
                    {/*        Aqui fica uma pequena descrição sobre este serviço da RedService,*/}
                    {/*        aqui fica uma pequena descrição.*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div class="col-md-6 col-lg-4 service">*/}
                    {/*    <div class="ratio ratio-16x9">*/}
                    {/*        <img class="img-cover" src={servico1} />*/}
                    {/*    </div>*/}
                    {/*    <div class="category bg-red d-flex align-items-center">*/}
                    {/*        <div class="category-text text-white">*/}
                    {/*            Deslocação do seu Automóvel ao I.P.O.*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div class="resume mt-3">*/}
                    {/*        Aqui fica uma pequena descrição sobre este serviço da RedService,*/}
                    {/*        aqui fica uma pequena descrição.*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div class="container-fluid content-subfooter overflow-hidden p-0">
                <div class="row g-0 justify-content-center align-self-center">
                    <div class="col-md-6 position-relative">
                        <div class="position-relative">
                            <div class="h-105 w-105 position-absolute top-0 background-cover">
                                <div class="h-100 w-100 position-absolute top-0 overlay-90 bg-red"></div>
                                <img src={imgSeparatorLeft} />
                            </div>
                            <div class="content-modulo">
                                <h5 class="section_sub_title text-white">{t('Main.Block3.sub_title')}</h5>
                                <h2 class="section_title text-white mb-4">
                                    {t('Main.Block3.title')}
                                </h2>
                                <button class="btn btn-white btn-lg" type="button" onClick={handleButtonClick}>
                                    {t('Main.Block3.location')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 position-relative">
                        <div class="h-100 w-100 position-absolute top-0 background-cover">
                            <div class="div-clip-path">
                                <div class="h-100 w-100 position-absolute top-0 overlay-60 bg-darker"></div>
                                <img src={imgSeparatorRight} />
                            </div>
                        </div>
                        <div class="content-modulo d-flex h-100">
                            <div class="row justify-content-center align-self-center">
                                <h5 class="section_sub_title text-white">{t('Main.Block3.sub_title_m')}</h5>
                                <h2 class="section_title text-white">{t('Main.Block3.title_m')}</h2>
                                <p class="text-white mb-4">
                                    {t('Main.Block3.description')}
                                </p>
                                <div class="mt-auto">
                                    <Button
                                        onClick={handleButtonClick}
                                        type="button"
                                    >
                                        {t('Main.Block3.schedule')}
                                    </Button>
                                </div>
                            </div>
                            {/*<span class="acordos-overlay-text fw-bold position-absolute start-0 bottom-0 overlay-5">*/}
                            {/*    Acordos*/}
                            {/*</span>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;
