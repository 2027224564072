import "./RedeDeOficinas.css";
import BannerInterior from "../../components/BannerInterior/BannerInterior";
import banner from "../../img/banners/redes-oficinas.jpg";
import iconMapPin from "../../img/rede-oficinas/icons/map-pin.svg";
import iconCellphone from "../../img/rede-oficinas/icons/cellphone.svg";
import iconEmail from "../../img/rede-oficinas/icons/email.svg";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import SimpleMap from "../../components/GoogleMap/SimpleMap";
import { useState } from "react";
import { useTranslation, Trans } from 'react-i18next';
import axios from 'axios';
import { useEffect } from "react";
import toast from "react-hot-toast";
import Select from 'react-select';
import { CurrentUser, Loading } from "../../utils/Utilities";
import Moment from 'moment';
import 'moment/locale/pt';
Moment.locale('pt');

function RedeDeOficinas(props) {
    let navigate = useNavigate();
    const [points, setPoints] = useState([]);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const currentUser = CurrentUser();
    const [pointClicked, setPointClicked] = useState(null);

    useEffect(() => {
        getAllWorkshops();
    }, [])

    const getAllWorkshops = async () => {
        setLoading(true);
        await axios.get(global.api_url + "Workshops/GetAllFront")
            .then((response) => {
                var data = response.data;
                data?.forEach(d => {
                    d.lat = isNaN(+d.lat) === false ? +d.lat : 0;
                    d.lng = isNaN(+d.lng) === false ? +d.lng : 0;
                    try {
                        const aux = JSON.parse(d.scheduleAux);
                        d.scheduleWeek = "Segunda a sexta:";
                        d.morningHours = aux?.timeW1 + (aux?.timeW2 === "" ? "" : " às " + aux?.timeW2);
                        d.afternoonHours = (aux?.timeW3 === "" ? "" : aux?.timeW3 + " às ") + aux?.timeW4;
                        d.scheduleSaturday = "Sábado: " + (aux.timeWD1 !== "" ? aux?.timeWD1 + " às " + aux?.timeWD2 : "Encerrado");
                    } catch (e) { console.log('invalid json'); }
                });

                setLoading(false);
                setPoints(data);
            }).catch(error => {
                setPoints([]);
                setLoading(false);
                toast.error(error, { duration: 4000, });
            })
    }

    const selectWorkShop = () => {

        if (currentUser !== null) {
            var w = points.find(x => x?.id.toString() === currentUser?.defaultWorkshop?.toString());
            if (w === undefined) {
                setPointClicked(points[0]);
                getImageWorkshop(points[0]);
            } else {
                setPointClicked(w);
                getImageWorkshop(w);
            }
        } else {
            var ws = points[Math.floor(Math.random() * ((points.length - 1) - 0 + 1)) + 0]
            if (ws === undefined) {
                setPointClicked(points[0]);
                getImageWorkshop(points[0]);
            } else {
                setPointClicked(ws);
                getImageWorkshop(ws);
            }
        }
    }

    useEffect(() => {
        if (points.length > 0) {
            selectWorkShop();
        }
    }, [points])



    const appointment = () => {
        props.setAppointmentData({
            ...props.appointmentData,
            shopId: pointClicked,
            shop: pointClicked,
            schedule: {
                title: "Marcação Site",
                start: Moment(new Date().toUTCString()).toISOString().replace('Z', ''),
                end: Moment(new Date().toUTCString()).toISOString().replace('Z', ''),
                accountId: 0,
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                nif: "",
                vehiculeId: 0,
                plate: "",
                brand: "",
                model: "",
                kms: 0,
                workShopId: pointClicked.id,
                notes: "",
                serviceTypeId: 0,
                services: []
            }
        });
        navigate("/Marcacao");
    };

    const budget = () => {
        props.setAppointmentData({
            ...props.appointmentData,
            workShop: pointClicked,
            shop: pointClicked.title,
            schedule: {
                title: "Pedido de Orçamento",
                start: Moment(new Date().toUTCString()).toISOString().replace('Z', ''),
                end: Moment(new Date().toUTCString()).toISOString().replace('Z', ''),
                accountId: currentUser !== null ? currentUser?.id : 0,
                firstName: currentUser !== null ? currentUser?.firstName : "",
                lastName: currentUser !== null ? currentUser?.lastName : "",
                email: currentUser !== null ? currentUser?.email : "",
                phone: "",
                nif: "",
                vehiculeId: 0,
                plate: "",
                brand: "",
                model: "",
                kms: 0,
                workShopId: pointClicked.id,
                notes: "",
                serviceTypeId: 0,
                services: []
            }
        });
        navigate("/Orcamento");
    };

    const getImageWorkshop = (data) => {
        if (!data?.image?.startsWith("data")) {
            if (data !== undefined) {
                data.image = data?.image !== undefined ? data?.image : "no-image.png"

                axios.get(global.api_url + "WorkShops/Image/" + data?.image)
                    .then(response => {
                        setPointClicked({ ...data, image: response.data?.base64 });
                    }).catch(error => {
                        toast.error(error)
                        setPointClicked(data)
                    });
            }
        } else { setPointClicked(data) }
    }
    return (
        <div className="Main-Class">
            <BannerInterior name={t("WorkShopNetWork.banner_name")} imagem={banner} />
            <div class="section redes-oficinas pt-xxl-4 pt-5 pb-xxl-1 pb-5">
                <div class="container-fluid ps-lg-0">
                    <div class="row align-items-xxl-center g-5">
                        {Loading(loading, 30)}
                        {loading === false && points.length > 0 &&
                            <>
                                <div class="col-lg-5">
                                    <SimpleMap points={points} pointClicked={pointClicked} setPointClicked={(data) => { getImageWorkshop(data); }} />
                                </div>
                                {pointClicked !== null &&

                                    <div class="col-xxl-5 col-lg-7 content-left">
                                        <div class="block-side-map">
                                            <div class="title fw-bold mb-5">
                                                <Trans i18nKey="WorkShopNetWork.title" />
                                            </div>
                                            <div class="title fw-bold mb-5">
                                                <Select
                                                    classNamePrefix="select"
                                                    className="form-control"
                                                    isSearchable={true}
                                                    value={pointClicked}
                                                    placeholder={t("Keywords.search")}
                                                    options={points}
                                                    getOptionLabel={(option) => `${option.title}`}
                                                    onChange={(e) => { getImageWorkshop(e); }}
                                                />
                                            </div>
                                            <div class="card oficina rounded-0 mb-5">
                                                <div class="row g-0">
                                                    <div class="col-sm-7">
                                                        <div class="card-body">
                                                            <div class="name font-poppins text-red fw-bold mb-3">
                                                                {pointClicked?.title}
                                                            </div>
                                                            <div class="details">
                                                                <div class="d-flex align-items-start mb-3">
                                                                    <img alt={pointClicked?.title} class="icon-map me-2" src={iconMapPin} />
                                                                    <div class="d-block">
                                                                        <div class="address">
                                                                            {pointClicked?.address}
                                                                        </div>
                                                                        {pointClicked?.postalCode + " " + pointClicked?.locality}
                                                                    </div>
                                                                </div>

                                                                <div class="d-flex align-items-middle mb-3">
                                                                    <img alt={"Localização " + pointClicked?.title} class="me-2" src={iconMapPin} />
                                                                    <div><a href={`https://www.google.com/maps/search/?api=1&query=${pointClicked?.lat}%2C${pointClicked?.lng}`} target="_blank">{pointClicked?.lat + " , " + pointClicked?.lng}</a></div>
                                                                </div>

                                                                <div class="d-flex align-items-middle mb-3">
                                                                    <img alt={"Telefone " + pointClicked?.title} class="me-2" src={iconCellphone} />
                                                                    <div><a href={`tel:${pointClicked?.cellphone}`}>{pointClicked?.cellphone}</a></div>
                                                                </div>

                                                                {pointClicked?.phone !== "" &&
                                                                    <div class="d-flex align-items-middle mb-3">
                                                                        <img alt={"Telefone " + pointClicked?.title} class="me-2" src={iconCellphone} />
                                                                        <div><a href={`tel:${pointClicked?.phone}`}>{pointClicked?.phone}</a></div>
                                                                    </div>
                                                                }

                                                                {pointClicked?.email !== "" &&
                                                                    <div class="d-flex align-items-middle mb-3">
                                                                        <img alt={"Email " + pointClicked?.title} class="me-2" src={iconEmail} />
                                                                        <div><a href={`mailto:${pointClicked?.email}`}>{pointClicked?.email}</a></div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <p class="horario fw-bold mb-1">{t("Keywords.schedule")}</p>
                                                            <div class="block-horario d-flex mb-2">
                                                                <div>{pointClicked?.scheduleWeek}</div>
                                                                <div class="d-block ms-1">
                                                                    {pointClicked?.morningHours}
                                                                    <div class="d-block mt-1">
                                                                        {pointClicked?.afternoonHours}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>{pointClicked?.scheduleSaturday}</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-5">
                                                        <div class="img-oficina h-100">
                                                            {pointClicked?.image !== undefined && pointClicked?.image?.startsWith("data") ?
                                                                <img className="" style={{ height: '100%', width: '100%', resize: 'both' }} src={pointClicked?.image} /> :
                                                                Loading(loading, 30)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {pointClicked?.available === true ?
                                                <div className="buttons">
                                                    <div class="d-flex">
                                                        <Button onClick={() => { appointment(); }}
                                                            type="button"
                                                            buttonStyle="button-loginscreen-solid"
                                                            buttonSize="button-size-large">
                                                            {t("WorkShopNetWork.button_schedule")}
                                                        </Button>
                                                        <Button onClick={() => { budget(); }}
                                                            type="button" buttonStyle="btn-black" buttonSize="button-size-large">
                                                            {t("WorkShopNetWork.button_budget")}
                                                        </Button>
                                                    </div>
                                                </div>
                                                : <>Serviço de agendamento temporáriamente indisponível.</>}
                                        </div>
                                    </div>
                                }
                            </>}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default RedeDeOficinas;
