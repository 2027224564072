import "./Budget.css";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";
import BannerInterior from "../../components/BannerInterior/BannerInterior";
import banner from "../../img/banners/redes-oficinas.jpg";
import toast from "react-hot-toast";
import axios from 'axios';
import { CurrentUser, GetHeader, Loading } from "../../utils/Utilities";
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import 'moment/locale/pt';

function Budget(props) {
    const currentUser = CurrentUser();
    const [serviceTypes, setServiceTypes] = useState([]);
    const [load, setLoad] = useState(false);
    const [services, setService] = useState([]);
    const [vehiculeList, setVehiculeList] = useState([]);
    const [vehiculeSelected, setVehiculeSelected] = useState(null);
    const { t } = useTranslation();

    const [schedule, setSchedule] = useState(null);
    const [agreement, setAgreement] = useState(props.appointmentData.agreement);
    const [points, setPoints] = useState([]);
    useEffect(() => {
        getAllWorkshops();
        setSchedule(props?.appointmentData?.schedule !== undefined ? props?.appointmentData?.schedule : {
            title: "Pedido de orçamento",
            start: Moment(new Date().toUTCString()).toISOString().replace('Z', ''),
            end: Moment(new Date().toUTCString()).toISOString().replace('Z', ''),
            accountId: currentUser !== null ? currentUser?.id : 0,
            firstName: currentUser !== null ? currentUser?.firstName : "",
            lastName: currentUser !== null ? currentUser?.lastName : "",
            email: currentUser !== null ? currentUser?.email : "",
            phone: "",
            nif: "",
            vehiculeId: 0,
            plate: "",
            brand: "",
            model: "",
            kms: 0,
            workShopId: currentUser !== null ? currentUser?.defaultWorkshop : "",
            notes: "",
            serviceTypeId: 0,
            services: []
        })
    }, []);
    useEffect(() => {
        if (schedule !== null) {
            setSchedule({ ...schedule, accountId: currentUser?.id, firstName: currentUser.firstName, lastName: currentUser?.lastName, email: currentUser?.email, workShopId: currentUser?.defaultWorkshop })
        }
    }, [currentUser !== null && schedule !== null])

    const handleAgreementChange = (e) => { setAgreement(!agreement); };

    const handleChangeSchdule = (e) => {
        const { id, value } = e.target;
        setSchedule({ ...schedule, [id]: value });
        if (id === 'plate') { if (value.length === 8) { GetPlate(value).then(response => { if (response !== null) { setSchedule({ ...schedule, plate: value, vehiculeId: 0, brand: response?.marca, model: response?.modelo, year: response?.anoFab }) } }) } }
    }


    //Step 1 Variables
    const timesList = [{ value: 1, text: "Manhã" }, { value: 2, text: "Tarde" }];
    const [time, setTime] = useState(props.appointmentData.time);
    const [brandsList, setBrandsList] = useState([]);
    //Step 4 Variables
    var emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //Conte @ e .


    const handleDateChange = (e) => {
        setSchedule({ ...schedule, start: e.target.value, end: e.target.value });
    };

    const getAllWorkshops = async () => {

        await axios.get(global.api_url + "Workshops/GetAllFront")
            .then((response) => {
                var data = response.data;
                setPoints(data);
            }).catch(error => {
                setPoints([]);
                toast.error(error, { duration: 4000, });
            })
    }

    const handleSelectChange = (e) => {
        var index = e.nativeEvent.target.selectedIndex;
        var textSelect = e.nativeEvent.target[index].text;
        setTime([{ value: e.target.value, text: textSelect }]);
        setSchedule({
            ...schedule,
            start: Moment(new Date(schedule.start).setHours(e.nativeEvent.target.value, 0, 0)).format(),
            end: index === 1 ? Moment(new Date(schedule?.end).setHours(11, 0, 0)).format() : Moment(new Date(schedule?.end).setHours(16, 0, 0)).format()
        })
    };

    const handleModelPlate = (e) => {
        if ((e.target.value.length === 2 && (!schedule?.plate?.includes("-", 2))) || (e.target.value.length === 5 && (!schedule?.plate.includes("-", 5)))) {
            e.target.value = e.target.value + "-";
            setSchedule({ ...schedule, plate: e.target.value.toUpperCase() });
        } else {
            setSchedule({ ...schedule, plate: e.target.value.toUpperCase() });
        }

        if (e.target.value.length === 8) {
            GetPlate(e.target.value).then(response => {
                if (response !== null) {
                    setSchedule({ ...schedule, plate: e.target.value.toUpperCase(), model: response?.modelo, brand: response?.marca });
                }
            })
        }
    };
    const GetTecDoc = async (functionName, parameters) => {
        return axios.get(global.api_url + "TecDoc/" + functionName, { params: parameters })
            .then((response) => {
                if (response.status === 200) {
                    switch (functionName) {
                        case 'getManufacturers':
                            var carBrands = JSON.parse(response.data.result.data)
                            return carBrands;
                        case 'getModelSeries':
                            var carModels = JSON.parse(response.data.result.data)
                            return carModels;
                    }

                }
                return null;
            }).catch(error => {
                toast.error(error, { duration: 4000, });
                return null;
            })
    }

    const GetPlate = async (plate) => {
        return axios.get(`${global.api_url}TecDoc/GetByMatricula/${plate}`)
            .then((response) => {
                if (response.status === 200) {
                    return response.data;
                }
                return null;
            }).catch(error => {
                toast.error("Não foi possível obter os dados do veículo.", { duration: 4000, });
                return null;
            })
    }
    const GetServiceTypes = () => {
        axios.get(global.api_url + "ServiceTypes")
            .then((response) => {
                var data = response.data;
                data.map(d => { d.check = false; })
                setServiceTypes(data);
            }).catch(error => {
                setServiceTypes([]);
                toast.error(error, { duration: 4000, });
            })
    }

    const GetVehicules = async (userId) => {
        var headers = GetHeader();
        return axios.get(`${global.api_url}Vehicules/Account/${userId}`, { headers: headers })
            .then((response) => {
                if (response.status === 200) {
                    return response.data;
                }
                return [];
            }).catch(error => {
                toast.error(error);
                return [];
            })
    }

    useEffect(() => {
        GetTecDoc("getManufacturers", { articleCountry: "PT", country: "pt", lang: "pt", linkingTargetType: "P" }).then(response => { setBrandsList(response); });
        GetServiceTypes();
        if (currentUser !== null) { GetVehicules(currentUser.id).then(response => { setVehiculeList(response); }); }
    }, [currentUser !== null])


    let navigate = useNavigate();

    const validate = () => {
        if (schedule.start.trim() === "") {
            toast.error("Tem de preencher a data.");
        } else if (schedule?.brand.value === "") {
            toast.error("Tem de selecionar a marca.");
        } else if (schedule?.model.trim() === "") {
            toast.error("Tem de preencher o modelo.");
        } else if (schedule?.year?.toString().trim() === "") {
            toast.error("Tem de preencher o ano.");
        } else if (schedule?.kms?.toString().trim() === "0") {
            toast.error("Tem de preencher os kilómetros.");
        } else if (schedule?.firstName?.trim() === "") {
            toast.error("Tem de preencher o nome.");
        } else if (schedule?.lastName?.trim() === "") {
            toast.error("Tem de preencher o apelido.");
        } else if (schedule?.phone?.trim() === "") {
            toast.error("Tem de preencher o contacto.");
        } else if (schedule?.email.trim() === "") {
            toast.error("Tem de preencher a email.");
        } else if (!agreement) {
            toast.error("Tem de aceitar os termos & condições e a política de privacidade.");
        } else if (!schedule.email.match(emailPattern)) {
            toast.error("Email inválido!");
        } else if (schedule.services.length === 0) {
            toast.error("Precisa de selecionar pelo menos um serviço.");
        } else {
            return true;
        }
    };

    const sendData = () => {
        setSchedule({ ...schedule, services: services });
        if (validate()) {
            setLoad(true);
            axios.post(`${global.api_url}Schedules/New`, schedule)
                .then((response) => {
                    setLoad(false);
                    if (response.status === 200) {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "instant",
                        });
                        toast.success("Orçamento pedido com sucesso", { duration: 4000, });
                        navigate("/")
                    }
                }).catch(error => {
                    setLoad(false);
                    toast.error("Erro a efetuar a marcação!", { duration: 4000 });
                })
        }


    };



    return (
        <div className="Schedule-main">
            <BannerInterior name="Orçamento" imagem={banner} ></BannerInterior>

            <div class="section marcacao py-5">
                <div class="container pb-5">
                    <div class="row">
                        <div class="col-12 mb-4">
                            <div class="title-form fw-bold my-4">
                                Pedido de Orçamento do Serviço de Oficina
                            </div>
                        </div>
                    </div>
                    <div class="row border-bottom g-2 py-4">
                        <div class="col-lg-3">
                            <div class="text-gray text-uppercase mb-4">Oficina</div>
                        </div>
                        <div class="col-lg-9">
                            <div class="row g-3">
                                <div class="col-12">
                                    <input
                                        class="form-control"
                                        type="text"
                                        value={schedule?.title}
                                        aria-label={props.appointmentData.shop}
                                        disabled
                                        readonly
                                    />
                                </div>
                                <div class="col-12">
                                    <select
                                        class="form-select"
                                        value={schedule?.workShopId}
                                        onChange={(e) => { setSchedule({ ...schedule, workShopId: e.target.value }); }}>
                                        <option value="" selected>
                                            Selecionar uma oficina
                                        </option>
                                        {points?.map((item) => {
                                            return (<option key={item.id} value={item.id} label={item.title}>{item.title}</option>);
                                        })}
                                    </select>
                                </div>
                                <div class="col-sm-6">
                                    <input
                                        value={Moment(schedule?.start).format('yyyy-MM-DD')}
                                        type="date"
                                        onChange={handleDateChange}
                                        placeholder="dd/mm/aaaa"
                                        id="start"
                                        className="form-control"

                                    />
                                </div>
                                <div class="col-sm-6">
                                    <select
                                        class="form-select"
                                        value={Moment(schedule?.start).format('LT').toString().startsWith("14") ? timesList[1].value : timesList[0].value}
                                        onChange={handleSelectChange}
                                    >
                                        <option value="" disabled selected>
                                            Selecionar um Período
                                        </option>
                                        {timesList?.map((item) => {
                                            return (
                                                <option
                                                    key={item.value}
                                                    value={item.value}
                                                    label={item.text}
                                                >
                                                    {item.text}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row border-bottom g-2 py-4">
                        <div class="col-lg-3">
                            <div class="text-gray text-uppercase mb-4">Dados Pessoais</div>
                        </div>
                        <div class="col-lg-9">
                            <div class="row g-3">
                                <div class="col-6">
                                    <input
                                        value={schedule?.firstName}
                                        onChange={handleChangeSchdule}
                                        placeholder="Nome"
                                        type="text"
                                        id="firstName"
                                        className="form-control"
                                    />
                                </div>
                                <div class="col-6">
                                    <input
                                        value={schedule?.lastName}
                                        onChange={handleChangeSchdule}
                                        placeholder="Nome"
                                        type="text"
                                        id="lastName"
                                        className="form-control"
                                    />
                                </div>
                                <div class="col-sm-6">
                                    <input
                                        value={schedule?.phone}
                                        onChange={handleChangeSchdule}
                                        placeholder="Contacto"
                                        type="text"
                                        id="phone"
                                        className="form-control"
                                    />
                                </div>
                                <div class="col-sm-6">
                                    <input
                                        onChange={handleChangeSchdule}
                                        value={schedule?.email}
                                        placeholder="Email"
                                        type="text"
                                        id="email"
                                        disabled={currentUser !== null ? true : false}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row border-bottom g-2 py-4">
                        <div class="col-lg-3">
                            <div class="text-gray text-uppercase mb-4">Dados da Viatura</div>
                        </div>
                        <div class="col-lg-9">
                            <div class="row g-3">

                                {currentUser !== null &&

                                    <div class="col-12">
                                        <Select
                                            classNamePrefix="select"
                                            className="form-control"
                                            isSearchable={true}
                                            isClearable={true}
                                            value={vehiculeSelected}
                                            placeholder={t("Keywords.search")}
                                            options={vehiculeList}
                                            getOptionLabel={(option) => `${option.plate + " - " + option.brand + " " + option.model}`}
                                            onChange={(e) => {
                                                setVehiculeSelected(e);
                                                setSchedule({ ...schedule, plate: e?.plate, model: e?.model, brand: e?.brand });


                                                if (e !== null) {
                                                    GetPlate(e?.plate).then(response => {
                                                        if (response !== null) {
                                                            setSchedule({ ...schedule, plate: e?.plate, model: response?.modelo, brand: response?.marca });
                                                        }
                                                    })
                                                }
                                            }}
                                        />

                                    </div>
                                }


                                <div class="col-sm-6">
                                    <input
                                        value={schedule?.plate}
                                        placeholder="Indique uma matrícula"
                                        type="text"
                                        id="plate"
                                        maxLength={8}
                                        className="form-control"
                                        onChange={handleModelPlate}
                                        disabled={schedule?.vehiculeId !== 0 ? true : false}
                                    />
                                </div>
                                <div class="col-sm-6">
                                    <input
                                        value={schedule?.brand}
                                        type="text"
                                        id="brand"
                                        className="form-control"
                                        onChange={handleChangeSchdule}
                                        disabled={schedule?.vehiculeId !== 0 ? true : false}
                                    />
                                </div>
                                <div class="col-sm-6">
                                    <input
                                        value={schedule?.model}
                                        onChange={handleChangeSchdule}
                                        placeholder="Indique o modelo"
                                        type="text"
                                        id="model"
                                        className="form-control"
                                        disabled={schedule?.vehiculeId !== 0 ? true : false}
                                    />
                                </div>
                                <div class="col-sm-6">
                                    <input
                                        onChange={handleChangeSchdule}
                                        value={schedule?.kms !== 0 ? schedule?.kms : ""}
                                        placeholder=" Km's"
                                        type="text"
                                        id="kms"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row border-bottom g-2 py-4">
                        <div class="col-lg-3">
                            <div class="text-gray text-uppercase mb-4">
                                Observações
                                <span class="d-block small my-2">(Opcional)</span>
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <div class="row justify-content-between gy-3">
                                {serviceTypes !== null && serviceTypes.map(serv => {
                                    if (!serv.name.startsWith("Outro")) {

                                        return (
                                            <div class="col-md-4">
                                                <div class="form-check form-check-inline">
                                                    <input
                                                        checked={serv?.check}
                                                        onChange={() => {
                                                            serv.check = serv?.check === false ? true : false;

                                                            if (serv?.check === true) {
                                                                setSchedule({ ...schedule, services: [...schedule.services, { id: serv?.id, name: serv?.name, description: serv?.description }] });
                                                            } else {
                                                                var aux = schedule?.services?.filter(x => x.id !== serv.id);
                                                                setSchedule({ ...schedule, services: aux });
                                                            }
                                                        }}
                                                        type="checkbox"
                                                        id={serv?.id}
                                                        className="form-check-input"
                                                    />
                                                    <label class="form-check-label" for={serv?.id}>
                                                        {serv?.name}
                                                    </label>
                                                </div>
                                            </div>
                                        );


                                    } else {

                                        return (<>
                                            <div class="col-md-12 mt-2">
                                                <div class="form-check form-check-inline w-100 d-flex align-items-center">
                                                    <input
                                                        checked={serv?.check}
                                                        onChange={() => {

                                                            serv.check = serv?.check === false ? true : false;

                                                            if (serv?.check === true) {
                                                                setSchedule({ ...schedule, services: [...schedule.services, { id: serv?.id, name: serv?.name, description: serv?.description }] });
                                                            } else {
                                                                var aux = schedule?.services?.filter(x => x.id !== serv.id);
                                                                setSchedule({ ...schedule, services: aux })
                                                            }
                                                        }}
                                                        type="checkbox"
                                                        id="outro"
                                                        className="form-check-input"
                                                    />
                                                    <label class="form-check-label ms-2" for="outro">
                                                        Outro
                                                    </label>
                                                    <input
                                                        value={serv?.check === true ? serv?.description : ""}
                                                        onChange={(e) => {
                                                            if (serv?.check === true) {
                                                                const newState = schedule?.services?.map(obj => {
                                                                    if (obj.id === serv.id) { return { ...obj, description: e.target.value }; }
                                                                    return obj;
                                                                });
                                                                setSchedule({ ...schedule, services: newState })
                                                            }
                                                        }}
                                                        type="text"
                                                        id="outro"
                                                        className="form-control outro ms-2"
                                                    />
                                                </div>
                                            </div>
                                        </>);
                                    }
                                })}

                                <div class="col-md-12 mt-4">
                                    <p class="text-gray">
                                        <span class="fw-bold text-dark font-poppins">NOTA:</span> Os valores
                                        apresentados podem sofrer alterações consoante a escolha
                                        de serviços adicionais ou existindo alguma alteração
                                        devidamente informada.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row g-2 py-4">
                        <div class="col-lg-3">
                            <div class="text-gray text-uppercase">
                                Observações
                                <span class="d-block small mt-2">(Opcional)</span>
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <div class="row g-3">
                                <div class="col-12">
                                    <textarea
                                        value={schedule?.notes}
                                        onChange={handleChangeSchdule}
                                        id="notes"
                                        placeholder="Observações"
                                        className="form-control"
                                    />
                                </div>
                                <div class="col-12">
                                    <div class="form-check form-check-inline">
                                        <input
                                            checked={agreement}
                                            onChange={handleAgreementChange}
                                            type="checkbox"
                                            id="agreement"
                                            className="form-check-input"
                                        />
                                        <label class="form-check-label" for="agreement">
                                            Li e aceito os Termos & Condições e a Política de
                                            Privacidade.
                                        </label>
                                    </div>
                                </div>
                                {Loading(load)}
                                {load === false &&
                                    <div class="col-12 mt-5">
                                        <div className="marcacao-buttons text-end">
                                            <Button
                                                onClick={() => {
                                                    sendData();
                                                }}
                                                type="button"
                                                buttonStyle="button-loginscreen-solid"
                                                buttonSize="button-size-medium"
                                            >
                                                Pedir Orçamento
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Budget;
