import './Main.css';
import Block1 from './Block1/Block1.js';
import Block2 from './Block2/Block2.js';
import Block3 from './Block3/Block3.js';
import Block4 from './Block4/Block4.js';
import Block5 from './Block5/Block5.js';
import Block6 from './Block6/Block6.js';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


function Main() {
  return (
    <div>
      <Block1/>
      <Block2/>
      <Block3/>
      <Block4/>
      <Block5/>
      <Block6/>
    </div>
  );
}

export default Main;