import BannerInteriorSmall from "../../../../components/BannerInterior/BannerInteriorSmall";
import banner from "../../../../img/banners/redes-oficinas.jpg";
import imgPackManutencao from "../../../../img/servicos-oficina/pack-manutencao.jpg";
import iconCheck from "../../../../img/servicos-oficina/pack-check.svg";
import Button from "../../../../components/Button";
import MaintenencePackPrices from "../../../../components/MaintenencePackPrices";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function TotalRevision(props) {
  let navigate = useNavigate();
  const [oilPriceOption1, setOilPriceOption1] = useState(110);
  const [oilPriceOption2, setOilPriceOption2] = useState(129);
  const [oilPriceOption3, setOilPriceOption3] = useState(150);
  const [extraPriceOption1, setextraPriceOption1] = useState(29);

  return (
    <div className="App">
      <BannerInteriorSmall name="" imagem={banner} />
      <div class="section packs py-5">
        <div class="container py-5">
          <div class="row gy-5">
            <div class="col-12">
              <div class="section_sub_title text-red text-uppercase mb-4">
                Pack Manutenção
              </div>
              <div class="section_title fw-bold mb-5">Revisão Total</div>
            </div>
            <div class="col-xl-6 col-lg-5">
              <img src={imgPackManutencao} class="mb-5" />
              <div class="pack-details font-roboto fw-normal">
                <div class="title text-uppercase fw-bold font-poppins mb-4">
                  Incluído no Pack:
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="d-flex align-items-baseline mb-3">
                      <img class="me-2" src={iconCheck} />
                      <div>Mudança de Óleo</div>
                    </div>
                    <div class="d-flex align-items-baseline mb-3">
                      <img class="me-2" src={iconCheck} />
                      <div>Mudança de Filtro de Óleo</div>
                    </div>
                    <div class="d-flex align-items-baseline mb-3">
                      <img class="me-2" src={iconCheck} />
                      <div>Mudança de Filtro de Combustível</div>
                    </div>
                    <div class="d-flex align-items-baseline mb-3">
                      <img class="me-2" src={iconCheck} />
                      <div>
                        Reposição dos níveis de líquido limpa vidros, do líquido
                        refrigerante
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="d-flex align-items-baseline mb-3">
                      <img class="me-2" src={iconCheck} />
                      <div>Mudança de Filtro de Ar</div>
                    </div>
                    <div class="d-flex align-items-baseline mb-3">
                      <img class="me-2" src={iconCheck} />
                      <div>Pressão dos pneus</div>
                    </div>
                    <div class="d-flex align-items-baseline mb-3">
                      <img class="me-2" src={iconCheck} />
                      <div>Check-up de 45 Pontos</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="note-revision-pack font-roboto mt-5">
                <p class="mb-0">
                  <span class="fw-bold">Nota:</span> Preços válidos para
                  mudanças de óleo até 5 litros. Os preços apresentados são de
                  produtos de equivalência original, sendo a Oficina RedService
                  a responsável pela seleção dos mesmos. Este pack não é válido
                  para viaturas: Porsche, Austin Martin, Jaguar, Bentley,
                  Ferrari, Masarati, Lamborghini e Lexus. Este pack não acumula
                  com outras campanhas ou com protocolos em vigor.
                </p>
              </div>
            </div>
            <div class="col-xl-6 col-lg-7">
              <MaintenencePackPrices
                 pack="Pack Revisão Total"
                appointmentData={props.appointmentData}
                setAppointmentData={props.setAppointmentData}
                oilPriceOption1={oilPriceOption1}
                oilPriceOption2={oilPriceOption2}
                oilPriceOption3={oilPriceOption3}
                extraPriceOption1={extraPriceOption1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalRevision;
