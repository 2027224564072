import "./Step2.css";
import Button from "../../../components/Button";
import { useState, useEffect } from "react";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { CurrentUser, GetHeader, Loading } from "../../../utils/Utilities";

function Step2(props) {
    let navigate = useNavigate();
    const [brandsList, setBrandsList] = useState([]);
    const [load, setLoad] = useState(false);
    const [schedule, setSchedule] = useState(null);
    const [vehiculeList, setVehiculeList] = useState([]);
    const currentUser = CurrentUser();

    useEffect(() => {
        setSchedule(props?.appointmentData?.schedule)
        //console.log(props?.appointmentData?.schedule)
        GetTecDoc("getManufacturers", { articleCountry: "PT", country: "pt", lang: "pt", linkingTargetType: "P" }).then(response => { setBrandsList(response) });
        if (currentUser !== null) { GetVehicules(currentUser.id).then(response => { setVehiculeList(response); }); }
    }, [])

    const handleDataChange = (e) => {
        const { id, value } = e.target;
        setSchedule({ ...schedule, [id]: value });
        if (id === 'plate') { if (value.length === 8) { GetPlate(value).then(response => { if (response !== null) { setSchedule({ ...schedule, plate: value, vehiculeId: 0, brand: response?.marca, model: response?.modelo, year: response?.anoFab }) } }) } }
    }

    const GetPlate = async (plate) => {
        setLoad(true);
        return axios.get(`${global.api_url}TecDoc/GetByMatricula/${plate}`)
            .then((response) => {
                setLoad(false);
                if (response.status === 200) { return response.data; }
                return null;
            }).catch(error => {
                setLoad(false);
                toast.error("Não foi possível obter os dados do veículo.", { duration: 4000, });
                return null;
            })
    }

    const GetTecDoc = async (functionName, parameters) => {
        return axios.get(global.api_url + "TecDoc/" + functionName, { params: parameters })
            .then((response) => {
                if (response.status === 200) {
                    switch (functionName) {
                        case 'getManufacturers':
                            var carBrands = JSON.parse(response.data.result.data)
                            return carBrands;
                        case 'getModelSeries':
                            var carModels = JSON.parse(response.data.result.data)
                            return carModels;
                    }
                }
                return null;
            }).catch(error => {
                toast.error(error, { duration: 4000, });
                return null;
            })
    }

    const GetVehicules = async (userId) => {
        var headers = GetHeader();
        return axios.get(`${global.api_url}Vehicules/Account/${userId}`, { headers: headers })
            .then((response) => {
                if (response.status === 200) { return response.data; }
                return [];
            }).catch(error => {
                toast.error(error);
                return [];
            })
    }


    const validate = () => {
        if (schedule?.brand === "") {
            toast.error("Tem de selecionar a marca.");
        } else if (schedule?.model.toString().trim() === "") {
            toast.error("Tem de preencher o modelo.");
        } else if (schedule?.year.toString().trim() === "") {
            toast.error("Tem de preencher o ano.");
        } else if (schedule?.kms.toString().trim() === "" || schedule?.kms.toString().trim() === "0") {
            toast.error("Tem de preencher os kilómetros.");
        } else {
            return true;
        }
    };

    const nextStep = () => {
        if (validate()) {
            //console.log(schedule);
            props.setAppointmentData({ ...props.appointmentData, schedule: schedule });
            props.setStepActive(3);
        }
    };
    const previousStep = () => { props.setStepActive(1); };



    return (
        <div className="Schedule-main">
            <div class="section marcacao py-20">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-md-4 col-lg-3">
                            <div class="d-flex d-md-block">
                                <div class="step">
                                    <div class="number">1</div>
                                    <div class="name">Oficina e data</div>
                                </div>
                                <div class="step active">
                                    <div class="number">2</div>
                                    <div class="name">Dados da viatura</div>
                                </div>
                                <div class="step">
                                    <div class="number">3</div>
                                    <div class="name">Serviços</div>
                                </div>
                                <div class="step">
                                    <div class="number">4</div>
                                    <div class="name">Dados pessoais</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-8 col-lg-8 offset-lg-1">
                            <div class="title-form fw-bold my-4">
                                Marcação do Serviço de Oficina
                            </div>
                            <div class="subtitle text-gray text-uppercase mb-4">Passo 2</div>
                            <div class="title text-red fw-bold lh-1">
                                Dados
                                <br />
                                da viatura
                            </div>
                            {Loading(load)}
                            <div class="steps-form mt-5">
                                <div class="row g-3">
                                    {currentUser !== null &&
                                        <>
                                            <div class="col-8">
                                                <div class="label text-gray text-uppercase mb-2">
                                                    Veículos
                                                </div>
                                                <select
                                                    class="form-select"
                                                    value={schedule?.vehiculeId?.toString()}
                                                    onChange={(data) => {
                                                        var e = vehiculeList.find(x => x.id.toString() === data.target.value.toString());
                                                        if (e) {
                                                            setSchedule({ ...schedule, plate: e?.plate, vehiculeId: e?.id, brand: e?.brand, model: e?.model, year: e?.year })
                                                            if (e !== null) {
                                                                GetPlate(e?.plate).then(response => { if (response !== null) { setSchedule({ ...schedule, plate: e?.plate, vehiculeId: e?.id, brand: response?.marca, model: response?.modelo, year: response?.anoFab }) } });
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <option value="">
                                                        Selecionar veículo
                                                    </option>
                                                    {vehiculeList?.map((item) => { return (<option key={item.id} value={item.id} label={item.plate + " - " + item.brand + " - " + item.model}>{item.title}</option>); })}
                                                </select>
                                            </div>
                                            <div class="col-4 text-end" style={{ marginTop: 40 }}>
                                                <Button onClick={() => { navigate("/Area-de-cliente") }} type="button" buttonSize="button-size-large">
                                                    Adicionar novo veículo
                                                </Button>
                                            </div>
                                        </>
                                    }
                                    <div class="col-sm-6">
                                        <div class="label text-gray text-uppercase mb-2">
                                            Matrícula
                                        </div>
                                        <input
                                            onChange={handleDataChange}
                                            value={schedule?.plate}
                                            placeholder="Indique uma matrícula"
                                            type="text"
                                            id="plate"
                                            maxLength={8}
                                            className="form-control"
                                        />
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="label text-gray text-uppercase mb-2">Marca</div>
                                        <select
                                            class="form-select"
                                            value={brandsList.find(x => x.manuName === schedule?.brand)?.manuId}
                                            onChange={handleDataChange}
                                        >
                                            <option value="" disabled selected>
                                                Selecionar uma Marca
                                            </option>
                                            {brandsList?.map((item) => {
                                                return (<option key={item.value} value={item.manuId}>{item.manuName}</option>);
                                            })}
                                        </select>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="label text-gray text-uppercase mb-2">
                                            Modelo
                                        </div>
                                        <input
                                            value={schedule?.model}
                                            onChange={handleDataChange}
                                            placeholder="Indique o modelo"
                                            type="text"
                                            id="model"
                                            className="form-control"
                                        />
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="label text-gray text-uppercase mb-2">Ano</div>
                                        <input
                                            onChange={handleDataChange}
                                            value={schedule?.year}
                                            placeholder="AAAA"
                                            maxLength={4}
                                            type="text"
                                            id="year"
                                            className="form-control"
                                        />
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="label text-gray text-uppercase mb-2">Km's</div>
                                        <input
                                            onChange={handleDataChange}
                                            value={schedule?.kms === 0 ? "" : schedule?.kms}
                                            placeholder=""
                                            type="number"
                                            id="kms"
                                            className="form-control"
                                        />
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="marcacao-buttons text-end">
                                            <Button onClick={() => { previousStep(); }} type="button" buttonStyle="btn-black" buttonSize="button-size-large">
                                                Voltar
                                            </Button>
                                            <Button onClick={() => { nextStep(); }} type="button" buttonSize="button-size-large">
                                                Seguinte
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Step2;
