import "./RegisterCard.css";
import BannerInterior from "../../components/BannerInterior/BannerInterior";
import banner from "../../img/banners/redes-oficinas.jpg";
//import cartaoRedservice from "../../img/cartao-redservice/cartao-redservice.png";
import cartaoRedservice from "../../img/cartao-redservice/CardFront.png";
import iconPackCheck from "../../img/servicos-oficina/pack-check.svg";
import Button from "../../components/Button";
//import { useNavigate } from "react-router-dom";
import { useState } from "react";
import React from "react";
import toast from "react-hot-toast";
import { useTranslation, Trans } from 'react-i18next';
import { useEffect } from "react";
import axios from 'axios';
import { LoadingFixed } from "../../utils/Utilities";

function RegisterCard() {
    const { t } = useTranslation();
    const points = t("RegisterCard.points", { returnObjects: true });
    const [load, setLoad] = useState(false);

    const [optionsDistrict, setOptionsDistrict] = useState(["Aveiro", "Beja", "Braga", "Bragnça", "Castelo Branco", "Coimbra", "Faro", "Guarda", "Leiria", "Lisboa", "Porto Alegre", "Porto", "R.A. Açores", "R.A. Madeira", "Santarém", "Setúbal", "Viana do Castelo", "Vila Real", "Viseu"]);


    //REGEX
    const regex_numbers = /^\d+$/;

    useEffect(() => {
        //GetGeoLocations();
    }, []);

    const GetGeoLocations = async () => {
        await axios.get(global.geo_locat + "distritos")
            .then((response) => {
                if (response.status === 200) {
                    setOptionsDistrict(response.data)
                }
            }).catch(error => {
                setOptionsDistrict([]);
                toast.error(error, { duration: 4000, });
            })
    }

    const GetCp = async (cp) => {
        return axios.get(global.geo_locat + "cp/" + cp)
            .then((response) => {
                if (response.status === 200) {
                    return response.data;
                } else {
                    return null;
                }
            }).catch(error => {
                toast.error("Erro a obter a Localidade.", { duration: 4000, });
                return null;
            })
    }


    var cleanForm = { cardNumber: "", name: "", bornDate: "", contact: "", postalCode: "", locality: "", district: "", dataShareAgreement: false, policyAgreement: false };
    const [form, setForm] = useState(cleanForm);

    const handleChangeData = (e) => {
        const { id, value } = e.target;
        setForm({ ...form, [id]: value });
        if (id === 'postalCode' && value.match(/^\d{4}(?:[-\s]\d{3})?$/)) {
            GetCp(value).then(response => {
                if (response !== null) {
                    setForm({ ...form, [id]: value, locality: response?.Concelho, district: response?.Distrito });
                }
            });
        }
        if (id === 'dataShareAgreement' || id === 'policyAgreement') {
            console.log("s")

            setForm({ ...form, [id]: value === 'on' ? true : false })
        }

    }

    var emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


    //Validate if age is 18
    const checkDateValidity = () => {
        // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
        var optimizedBirthday = form?.bornDate.replace(/-/g, "/");
        //set date based on birthday
        var userBirthday = new Date(optimizedBirthday);
        // set current day
        var currentDate = new Date().toJSON().slice(0, 10) + " 01:00:00";
        // calculate age comparing current date and birthday
        var userAge = ~~((Date.now(currentDate) - userBirthday) / 31557600000);
        if (userAge < 18) {
            return false;
        } else {
            return true;
        }
    };

    const sendData = () => {
        if (validateData()) {
            setLoad(true);
            createCard(form).then(response => {
                setLoad(false);
                if (response === true) {
                    setForm(cleanForm);
                }

            });
        }
    };


    const createCard = async (card) => {
        return axios.post(global.api_url + "Card", card)
            .then((response) => {
                if (response.status === 200) {
                    toast.success("Registo de Cartão feito com sucesso!", { duration: 4000, });
                    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
                    return true;
                }
                return false;
            }).catch(error => {
                toast.error(error?.response?.data?.message, { duration: 4000, });
                return false;
            })

    }


    const validateData = () => {
        
        if (form?.cardNumber.length === 0 || !regex_numbers.test(form?.cardNumber)) {
            toast.error("Tem de preencher o número de cartão");
        } else if (form?.name?.trim() === "") {
            toast.error("Tem de preencher o nome");
        } else if (form?.bornDate?.trim() === "") {
            toast.error("Tem de preencher a data de nascimento");
        } else if (form?.contact?.trim() === "" || !regex_numbers.test(form?.contact)) {
            toast.error("Tem de preencher o contacto");
        } else if (form?.email?.trim() === "") {
            toast.error("Tem de preencher o email");
        } else if (form?.postalCode?.trim() === "") {
            toast.error("Tem de preencher o código postal");
        } else if (form?.locality?.trim() === "") {
            toast.error("Tem de preencher a localidade");
        } else if (form?.district === "") {
            toast.error("Tem de preencher o distrito");
        } else if (!form?.policyAgreement) {
            toast.error(
                "Tem de concordar com os Termos & Condições e a Política de Privacidade "
            );
        }
        // Verify if email has a @ and a .
        else if (!form?.email.match(emailPattern)) {
            toast.error("Email inválido!");
        } else if (!checkDateValidity()) {
            toast.error("Tem de ter 18 anos.");
        } else {
            return true;
        }
    };

    return (
        <div className="App">
            <BannerInterior name={t('RegisterCard.banner_name')} imagem={banner} />
            <div className="section join-us py-5">
                <div className="container py-5">
                    <div className="row pb-0">
                        <div className="col-md-12">
                            <div className="section_sub_title text-red text-uppercase mb-4">
                                {t('RegisterCard.sub_title')}
                            </div>
                        </div>
                    </div>
                    <div className="row gx-5 gy-lg-0 gy-5">
                        <div className="col-lg-6">
                            <div className="section_title fw-bold mb-4">{t('RegisterCard.title')}</div>
                            <div className="detail font-roboto mb-5">
                                <p>
                                    <Trans i18nKey="RegisterCard.description" />
                                </p>
                                <div className="features font-roboto">

                                    {Array.isArray(points) && points.map(point => {
                                        return (
                                            <div className="d-flex align-items-baseline mb-3" key={Math.random(0, 500)}>
                                                <img className="me-2" src={iconPackCheck} />
                                                <div>
                                                    <Trans i18nKey={point?.text} />
                                                </div>
                                            </div>
                                        );

                                    })}

                                </div>
                                <img src={cartaoRedservice} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row g-3">
                                <div className="col-md-12">
                                    <div className="form-title text-gray text-uppercase mb-3">
                                        {t("RegisterCard.form.title")}
                                    </div>
                                    <input
                                        onChange={handleChangeData}
                                        value={form?.cardNumber}
                                        id="cardNumber"
                                        type="text"
                                        placeholder={t("RegisterCard.form.cardNumber")}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <input
                                        onChange={handleChangeData}
                                        type="text"
                                        value={form?.name}
                                        id="name"
                                        placeholder={t("RegisterCard.form.name")}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <input
                                        onChange={handleChangeData}
                                        value={form?.bornDate}
                                        id="bornDate"
                                        type="date"
                                        placeholder={t("RegisterCard.form.birthday")}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <input
                                        onChange={handleChangeData}
                                        value={form?.contact}
                                        id="contact"
                                        type="text"
                                        placeholder={t("RegisterCard.form.contact")}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <input
                                        type="text"
                                        value={form.email}
                                        id="email"
                                        onChange={handleChangeData}
                                        placeholder={t("RegisterCard.form.email")}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <hr className="form-divider" />
                                </div>
                                <div className="col-md-5">
                                    <input
                                        onChange={handleChangeData}
                                        value={form?.postalCode}
                                        id="postalCode"
                                        type="text"
                                        placeholder={t("RegisterCard.form.postalcode")}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-7">
                                    <input
                                        type="text"
                                        value={form?.locality}
                                        id="locality"
                                        onChange={handleChangeData}
                                        placeholder={t("RegisterCard.form.locality")}
                                        className="form-control"
                                    />
                                </div>



                                <div className="col-md-12">
                                    <select className="form-select" id="district" value={form?.district} onChange={handleChangeData} >
                                        <option value={""} disabled defaultChecked>
                                            {t("RegisterCard.form.select_city")}
                                        </option>
                                        {optionsDistrict !== null && optionsDistrict?.map((item) => {
                                            return (
                                                <option key={item?.distrito} value={item} label={item}>{item}</option>
                                            );
                                        })}
                                    </select>
                                </div>



                                <div className="col-md-12">
                                    <div className="form-check form-check-inline mt-4">
                                        <input
                                            onChange={handleChangeData}
                                            type="checkbox"
                                            id="dataShareAgreement"
                                            className="form-check-input"
                                        />
                                        <label className="form-check-label" htmlFor="dataShareAgreement">
                                            <Trans i18nKey="RegisterCard.form.data_agreement" />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-check form-check-inline">
                                        <input
                                            onChange={handleChangeData}
                                            type="checkbox"
                                            id="policyAgreement"
                                            className="form-check-input"
                                        />
                                        <label className="form-check-label" htmlFor="Agreement">
                                            {t("RegisterCard.form.agreement")}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-4">
                                    {LoadingFixed(load, 30)}

                                    {load === false &&
                                        <Button onClick={() => { sendData(); }} type="button" buttonSize="btn-lg">
                                            {t("Keywords.register")}
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegisterCard;
