import React, { useState, useEffect } from "react";
import BannerInterior from "../../components/BannerInterior/BannerInterior";
import banner from "../../img/banners/redes-oficinas.jpg";
import "./Conselhos.css";
import concelho3 from "../../img/main/block6/concelho-3.jpg";
//import axios from "axios";
import Posts from "../../components/Pagination/Posts";
import Pagination from "../../components/Pagination/Pagination";
import Details from "./Details/Details";
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const Conselhos = () => {
    const [posts, setPosts] = useState([]);

    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(3);
    const [viewedPage, setViewedPage] = useState(1);
    const [previousPost, setPreviousPost] = useState([{ title: "", src: "", category: "", body: "" }],);
    const [currentPost, setCurrentPost] = useState(null);
    const { t } = useTranslation();
    const articles = t("Advices.articles", { returnObjects: true });
    let navigate = useNavigate();


    useEffect(() => {
        const fetchPosts = async () => {
            setLoading(true);
            Array.isArray(articles) ? setPosts(articles) : setPosts([]);
            setLoading(false);
        };

        fetchPosts();
    }, [Array.isArray(articles)]);

    // Get current posts Pneus
    // const indexOfLastPostPneus = currentPage * postsPerPage;
    //const indexOfFirstPostPneus = indexOfLastPostPneus - postsPerPage;
    //const currentPostsPneus = postsPneus.slice(indexOfFirstPostPneus, indexOfLastPostPneus);

    // Get current posts Mecanica
    // const indexOfLastPostMecanica = currentPage * postsPerPage;
    //const indexOfFirstPostMecanica = indexOfLastPostPneus - postsPerPage;
    //const currentPostsMecanica = postsMecanica.slice(indexOfFirstPostMecanica, indexOfLastPostMecanica);

    // Get current posts Manutençao
    //const indexOfLastPostManutençao = currentPage * postsPerPage;
    //const indexOfFirstPostManutençao = indexOfLastPostManutençao - postsPerPage;
    //const currentPostsManutençao = postsManutençao.slice(indexOfFirstPostManutençao, indexOfLastPostManutençao);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    function ActiveScreen() {


        switch (viewedPage) {
            case 1:
                return (
                    <div className="conselhos-main">
                        <BannerInterior name={t("Advices.banner_name")} imagem={banner} />
                        <div class="section marcacao py-20">
                            <div class="container">
                                <Posts
                                    setViewedPage={setViewedPage}
                                    setCurrentPost={setCurrentPost}
                                    currentPost={currentPost}
                                    posts={articles}
                                    loading={loading}
                                />
                                {/*<Pagination*/}
                                {/*    currentPage={currentPage}*/}
                                {/*    postsPerPage={postsPerPage}*/}
                                {/*    totalPosts={posts.length}*/}
                                {/*    paginate={paginate}*/}
                                {/*/>*/}
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <Details currentPost={currentPost} navigate={navigate} setViewedPage={setViewedPage}></Details>
                );
        }
    }

    return <div>{ActiveScreen()}</div>;

};

export default Conselhos;
