import "./Button.css";

//tipos de estilos existentes
const STYLES = [
  "btn-red",
  "btn-white",
  "btn-white-text-dark",
  "btn-black",
  "btn-grey",
  "btn-grey icon",
  "btn-saber-mais",
];

//tipos de tamanhos existentes
const SIZES = ["btn-lg", "btn-sm"];

const Button = ({ children, type, onClick, buttonStyle, buttonSize }) => {
  //verifica se o estilo selecionado existe no array
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];
  //verifica se o tamanho selecionado existe no array
  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    <button
      className={`btn ${checkButtonStyle} ${checkButtonSize}`}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
