import React from "react";
import Button from "../Button";
import "./Posts.css";
import { Trans } from 'react-i18next';
import { useNavigate } from "react-router-dom";



const postsPneus = ({
    setViewedPage,
    setCurrentPost,
    //postsPneus,
    //postsMecanica,
    //postsManutençao,
    navigate,
    posts,
    loading,
}) => {
    if (loading) {
        return <h2>Loading...</h2>;
    }
    return (
        <div className="row g-4">

            {Array.isArray(posts) && posts.map((post) => (
                <div className="col-lg-4 mb-4" key={Math.random(0, 500)}>
                    <div className="concelho" key={post.id}>
                        <div className="ratio ratio-4x3">
                            <img className="img-cover" alt={post?.title} src={post?.image} />

                        </div>
                        <span className="category bg-red text-white">{post.category}</span>
                        <div className="p-2">
                            <h6>{post.title}</h6>
                            <p>{post.resume}</p>

                            <Button type="button" buttonStyle="btn-saber-mais"
                                onClick={() => {
                                    setCurrentPost(post)
                                    setViewedPage(2);
                                }}>
                                <Trans i18nKey="Keywords.more" />
                            </Button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default postsPneus;
