import React from 'react';
import { Badge, Media } from "reactstrap";


const VehiculeRow = ({ vehicule, vehiculeDetail }) => {
    return (
        <tr>
            <th scope="row">
                {vehicule?.brand}
            </th>
            <td>
                {vehicule?.model}
            </td>
            <td>
                {vehicule?.plate}
            </td>
            <td onClick={(e) => { e.preventDefault(); vehiculeDetail(vehicule); }}>
                <i class="fa-solid fa-eye" style={{ color: '#E2241A' }}></i>
            </td>
        </tr>
    );
}

export default VehiculeRow;