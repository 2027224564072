import "./AreaDeCliente.css";
import BannerInterior from "../../components/BannerInterior/BannerInterior";
import banner from "../../img/banners/redes-oficinas.jpg";
import { Table } from "reactstrap";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import React from "react";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { CurrentUser, GetHeader, Loading, Role, Status } from "../../utils/Utilities";
import VehiculeRow from "./Lists/VehiculeRow";
import ScheduleRow from "./Lists/ScheduleRow";
//import InvoiceRow from "./Lists/InvoiceRow";
import VehiculeDetailRow from "./Lists/VehiculeDetailRow";
import Moment from 'moment';
import 'moment/locale/pt';
import Pagination from "../../components/Pagination/Pagination";
import axios from 'axios';
import ScheduleDetailRow from "./Lists/ScheduleDetailRow";
import { useTranslation, Trans } from 'react-i18next';

function AreaDeCliente(props) {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const currentUser = CurrentUser();
    const [section, setSection] = useState(1);
    const [paginationVehicules, setPaginationVehicules] = useState(null);
    const [paginationSchedule, setPaginationSchedule] = useState(null);
    const pageSize = 15;
    const [load, setLoad] = useState(false);
    const [disable, setDisable] = useState(true);
    //Sections
    const [vehicules, setVehicules] = useState([]);
    const [vehiculesDetails, setVehiculesDetails] = useState(null);
    const [newVehicule, setNewVehicule] = useState(null);
    const [brandsList, setBrandsList] = useState([]);
    const [currentVehicule, setCurrentVehicule] = useState(null);


    const [schedules, setSchedules] = useState([]);
    const [schedulesDetails, setSchedulesDetails] = useState(null);
    const [currentSchedule, setCurrentSchedule] = useState(null);

    const [invoices, setInvoices] = useState([]);
    const [user, setUser] = useState(null);

    const [workShopList, setWorkShopList] = useState([])

    const HandleSection4 = (e) => {
        const { id, value } = e.target;
        setUser({ ...user, [id]: value });
    }


    useEffect(() => {
        if (currentUser === null) { navigate("/login"); }
        GetTecDoc("getManufacturers", { articleCountry: "PT", country: "pt", lang: "pt", linkingTargetType: "P" }).then(response => { setBrandsList(response) });
    }, []);


    useEffect(() => {
        setDisable(true);
        setSchedulesDetails(null)
        setNewVehicule(null);
        setVehiculesDetails(null);
        switch (section) {
            case 1:
                if (currentUser !== null) {
                    GetVehicules(currentUser?.id, 1, pageSize);
                }
                break;
            case 2:
                GetSchedules(1, pageSize);
                break;
            case 3:
                GetInvoices(1, pageSize);
                break;
            case 4:
                GetWorkShops();
                GetCurrentUser(currentUser?.id);
                break;
            default:
                setSection(1);
                GetVehicules(currentUser?.id);
                break;
        }

    }, [section]);

    const GetPlate = async (plate) => {
        setLoad(true)
        return axios.get(`${global.api_url}TecDoc/GetByMatricula/${plate}`)
            .then((response) => {
                setLoad(false);
                if (response.status === 200) {
                    return response.data;
                }
                return null;
            }).catch(error => {
                toast.error("Não foi possível obter os dados do veículo.", { duration: 4000, });
                setLoad(false);
                return null;
            })
    }

    const GetTecDoc = async (functionName, parameters) => {
        return axios.get(global.api_url + "TecDoc/" + functionName, { params: parameters })
            .then((response) => {
                if (response.status === 200) {
                    switch (functionName) {
                        case 'getManufacturers':
                            var carBrands = JSON.parse(response.data.result.data)
                            return carBrands;
                        case 'getModelSeries':
                            var carModels = JSON.parse(response.data.result.data)
                            return carModels;
                    }

                }
                return null;
            }).catch(error => {
                toast.error(error, { duration: 4000, });
                return null;
            })
    }

    const GetCurrentUser = (userId) => {
        setLoad(true);
        var headers = GetHeader();
        axios.get(global.api_url + "Accounts/" + userId, { headers })
            .then((response) => {
                if (response.status === 200) {
                    setUser(response.data);
                }
                setLoad(false);
            }).catch(error => {
                setLoad(false);
                console.log(error);
                setUser(null)
                toast.error("Erro a obter os dados do utilizador atual.", { duration: 4000, });
            })
    }

    const GetVehicules = (userId, pageNumber, pageSize) => {
        setLoad(true);
        var headers = GetHeader();
        axios.get(global.api_url + "Vehicules/Account/" + userId + "?PageNumber=" + pageNumber + "&PageSize=" + pageSize, { headers })
            .then((response) => {
                if (response.status === 200) {
                    if (response.headers["x-pagination"] !== undefined) {
                        setPaginationVehicules(JSON.parse(response.headers["x-pagination"]));
                    }
                    setVehicules(response.data);
                }
                setLoad(false);
            }).catch(error => {
                setLoad(false);
                console.log(error);
                setVehicules([])
                toast.error("Erro a obter os veículos.", { duration: 4000, });
            })
    }
    const GetSchedules = (pageNumber, pageSize) => {
        setLoad(true);
        var headers = GetHeader();
        axios.get(global.api_url + "Schedules/My?PageNumber=" + pageNumber + "&PageSize=" + pageSize, { headers })
            .then((response) => {
                if (response.status === 200) {
                    if (response.headers["x-pagination"] !== undefined) {
                        setPaginationSchedule(JSON.parse(response.headers["x-pagination"]));
                    }
                    setSchedules(response.data);
                }
                setLoad(false);
            }).catch(error => {
                setLoad(false);
                console.log(error);
                setSchedules([]);
                toast.error("Erro a obter os agendamentos.", { duration: 4000, });
                if (error?.response?.data?.code === 401) { navigate("/login"); }
            })
    }
    const GetInvoices = (userId) => {
        setLoad(true);
        var headers = GetHeader();
        axios.get(global.api_url + "Schedules/My", { headers })
            .then((response) => {
                if (response.status === 200) {
                    setInvoices(response.data);
                }
                setLoad(false);
            }).catch(error => {
                setLoad(false);
                console.log(error);
                setInvoices([]);
                toast.error("Erro a obter as faturas.", { duration: 4000, });
                if (error?.response?.data?.code === 401) { navigate("/login"); }
            })
    }

    const GetServices = (vehiculeID) => {
        var headers = GetHeader();
        setLoad(true);
        return axios.get(`${global.api_url}Services/Vehicule/${vehiculeID}`, { headers })
            .then((response) => {
                setLoad(false);
                if (response.status === 200) {
                    setVehiculesDetails(response.data);
                }
            }).catch(error => {
                setLoad(false);
                console.log(error);
                toast.error("Erro a obter os serviços.", { duration: 4000, });
                setVehiculesDetails(null);
                if (error?.response?.data?.code === 401) { navigate("/login"); }
            })
    }

    const GetServiceSchedule = (scheduleID) => {
        setLoad(true);
        var headers = GetHeader();
        axios.get(`${global.api_url}Services/${scheduleID}`, { headers })
            .then((response) => {
                setLoad(false)
                if (response.status === 200) {
                    setSchedulesDetails(response.data);
                }
            }).catch(error => {
                setLoad(false);
                console.log(error);
                toast.error("Erro a obter os serviços.", { duration: 4000, });
                setSchedulesDetails(null);
                if (error?.response?.data?.code === 401) { navigate("/login"); }
            })
    }

    const GetWorkShops = () => {
        setLoad(true);
        var headers = GetHeader();
        axios.get(`${global.api_url}WorkShops/GetAllFront`, { headers })
            .then((response) => {
                setLoad(false)
                if (response.status === 200) {
                    setWorkShopList(response.data);
                }
            }).catch(error => {
                setLoad(false);
                console.log(error);
                toast.error("Erro a obter as oficinas.", { duration: 4000, });
                setWorkShopList(null);
                if (error?.response?.data?.code === 401) { navigate("/login"); }
            })
    }

    const UpdateUser = (user) => {
        setLoad(true);
        var headers = GetHeader();
        axios.put(`${global.api_url}Accounts/Front`, user, { headers })
            .then((response) => {
                setLoad(false)
                if (response.status === 200) {
                    setUser(response.data);
                    setDisable(true);
                    toast.success("Atualizado com sucesso.", { duration: 4000, });
                }
            }).catch(error => {
                setLoad(false);
                if (error?.response?.data?.status === 400) {
                    var errors = "";
                    Object.keys(error?.response?.data?.errors).forEach(er => {
                        errors += " -" + er + "\n";
                    })
                    toast.error("Erro a atualizar o utilizador.\nCampo(s) em falta:\n" + errors, { duration: 4000, });
                }
                if (error?.response?.data?.code === 401) { navigate("/login"); }
            })
    }

    const CreateVehicule = (vehicule) => {
        setLoad(true);
        var headers = GetHeader();
        axios.post(`${global.api_url}Vehicules`, vehicule, { headers })
            .then((response) => {
                setLoad(false)
                if (response.status === 200) {
                    setNewVehicule(null);
                    GetVehicules(currentUser?.id, 1, pageSize);
                }
            }).catch(error => {
                setLoad(false);
                if (error?.response?.data?.status === 400) {
                    var errors = "";
                    Object.keys(error?.response?.data?.errors).forEach(er => {
                        errors += " -" + er + "\n";
                    })
                    toast.error("Erro a atualizar o Veículo.\nCampo(s) em falta:\n" + errors, { duration: 4000, });
                }
                if (error?.response?.status === 500) {
                    toast.error(error?.response?.data?.message, { duration: 4000, });
                }
                if (error?.response?.data?.code === 401) { navigate("/login"); }
            })
    }

    const RemoveVehicule = (vehicule) => {
        var confirm = window.confirm("Deseja eliminar o veículo? Os dados serão perdidos.")

        if (confirm) {
            setLoad(true);
            var headers = GetHeader();
            axios.get(`${global.api_url}Vehicules/Delete/${vehicule?.id}`, { headers })
                .then((response) => {
                    setLoad(false)
                    if (response.status === 200) {
                        setVehiculesDetails(null);
                        GetVehicules(currentUser?.id, 1, pageSize);
                        toast.success("Veículo removido com sucesso.", { duration: 4000, });
                    }
                }).catch(error => {
                    setLoad(false);
                    if (error?.response?.data?.status === 400) {
                        var errors = "";
                        Object.keys(error?.response?.data?.errors).forEach(er => {
                            errors += " -" + er + "\n";
                        })
                        toast.error("Erro a atualizar o Veículo.\nCampo(s) em falta:\n" + errors, { duration: 4000, });
                    }
                    if (error?.response?.status === 500) {
                        toast.error(error?.response?.data?.message, { duration: 4000, });
                    }
                    if (error?.response?.data?.code === 401) { navigate("/login"); }
                })
        } else {

        }
    }

    const CancelSchedule = (details) => {
        var headers = GetHeader();
        axios.post(`${global.api_url}Schedules/Soft/${details?.id}`, { id: details?.id }, { headers })
            .then((response) => {
                setLoad(false)
                if (response.status === 200) {
                    setCurrentSchedule(null);
                    setSchedulesDetails(null);
                    GetSchedules(1, pageSize);
                    toast.success("Agendamento cancelado com sucesso.", { duration: 4000, });
                }
            }).catch(error => {
                if (error?.response?.data?.code === 401) { navigate("/login"); }
            })
    }

    const handleNewVehiculeChange = (e) => {
        const { id, value } = e.target;
        if (id === 'plate') {

            if ((e.target.value.length === 2 && (!newVehicule.plate.includes("-", 2))) || (e.target.value.length === 5 && (!newVehicule.plate.includes("-", 5)))) {
                e.target.value = e.target.value + "-";
                setNewVehicule({ ...newVehicule, [id]: e.target.value.toUpperCase() });
            } else {
                setNewVehicule({ ...newVehicule, [id]: e.target.value.toUpperCase() });
            }

            if (e.target.value.length === 8) {
                GetPlate(e.target.value).then(response => {
                    if (response !== null) {
                        setNewVehicule({ ...newVehicule, plate: value, brand: response?.marca, model: response?.modelo, year: response?.anoFab, kms: 0, ktypnr: response?.ktypnr.toString() });
                    } else {
                        setNewVehicule({ ...newVehicule, plate: value, brand: "", model: "", year: "", kms: 0, ktypnr: "" });
                    }
                })
            }
        } else {
            setNewVehicule({ ...newVehicule, [id]: value });
        }
    }


    const vehiculeDetail = (data) => { setCurrentVehicule(data); GetServices(data?.id); }
    const scheduleDetail = (data) => { setCurrentSchedule(data); GetServiceSchedule(data?.id) }



    return (
        <div className="App">
            <BannerInterior name="Área de Cliente" imagem={banner} />
            <div className="Schedule-main">
                <div class="section marcacao py-20">
                    <div class="container">
                        <div class="row g-5">
                            <div class="col-md-4 col-lg-3">
                                <div class="d-flex d-md-block">
                                    {currentUser?.role !== undefined && [Role.Admin, Role.GestorOficina, Role.Mecanico, Role.Oficina].includes(currentUser.role) &&
                                        <div className={`step  ${section === 0 ? "active" : ""}`} onClick={(e) => { e.preventDefault(); window.open('https://wms.redservice.pt', '_blank') }}>
                                            <div class="number"><i class="fa-solid fa-user-gear"></i></div>
                                            <div class="name">WMS</div>
                                        </div>
                                    }

                                    <div className={`step  ${section === 1 ? "active" : ""}`} onClick={() => setSection(1)}>
                                        <div class="number"><i class="fa-solid fa-car-side"></i></div>
                                        <div class="name">Veículos</div>
                                    </div>
                                    <div className={`step  ${section === 2 ? "active" : ""}`} onClick={() => setSection(2)}>
                                        <div class="number"><i class="fa-solid fa-calendar-days"></i></div>
                                        <div class="name">Agenda</div>
                                    </div>
                                    <div className={`step  ${section === 3 ? "active" : ""}`} onClick={() => setSection(3)}>
                                        <div class="number"><i class="fa-solid fa-file-invoice"></i></div>
                                        <div class="name">Faturas</div>
                                    </div>
                                    <div className={`step  ${section === 4 ? "active" : ""}`} onClick={() => setSection(4)}>
                                        <div class="number"><i class="fa-solid fa-user"></i></div>
                                        <div class="name">Conta</div>
                                    </div>
                                </div>
                            </div>
                            {section === 1 &&

                                <div class="col-md-8 col-lg-8 offset-lg-1">
                                    <div class="title-form fw-bold my-4">
                                        Veículos
                                    </div>
                                    <div class="steps-form mt-5">
                                        {vehiculesDetails === null && newVehicule === null &&
                                            <>
                                                <Table className="align-items-center table-flush" responsive>
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">Marca</th>
                                                            <th scope="col">Modelo</th>
                                                            <th scope="col">Matrícula</th>
                                                            <th scope="col" />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {load === false && vehicules.map((vehicule, key) => { return <VehiculeRow key={key} vehicule={vehicule} vehiculeDetail={vehiculeDetail} /> })}
                                                    </tbody>
                                                </Table>
                                                {Loading(load, 30)}
                                                <div className="text-end">
                                                    <Button
                                                        onClick={() => { setNewVehicule({ id: 0, accountId: currentUser?.id, brand: "", model: "", year: 0, plate: "", kms: 0, ktypnr: "", kms_year: 0, ative: true, comercial: true }); }}
                                                        type="button"
                                                        buttonSize="button-size-large">
                                                        Novo veículo
                                                    </Button>
                                                    {paginationVehicules !== null &&
                                                        <Pagination
                                                            currentPage={paginationVehicules?.CurrentPage}
                                                            postsPerPage={paginationVehicules?.PageSize}
                                                            totalPosts={paginationVehicules?.TotalCount}
                                                            paginate={(number) => GetVehicules(currentUser?.id, number, pageSize)}
                                                            scrollTop={false}
                                                        />
                                                    }

                                                </div>
                                            </>}

                                        {newVehicule !== null && <>
                                            <div class="row g-3">
                                                <div class="col-sm-6">
                                                    <div class="label text-gray text-uppercase mb-2">
                                                        Matrícula
                                                    </div>
                                                    <input
                                                        onChange={handleNewVehiculeChange}
                                                        value={newVehicule.plate}
                                                        placeholder="Indique uma matrícula"
                                                        type="text"
                                                        id="plate"
                                                        maxLength={8}
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="label text-gray text-uppercase mb-2">Marca</div>
                                                    <select
                                                        class="form-select"
                                                        value={brandsList.find(x => x.manuName === newVehicule.brand)?.manuId}
                                                        onChange={handleNewVehiculeChange}
                                                    >
                                                        <option value="" disabled selected>
                                                            Selecionar uma Marca
                                                        </option>
                                                        {brandsList?.map((item) => {
                                                            return (
                                                                <option key={item.value} value={item.manuId}>
                                                                    {item.manuName}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="label text-gray text-uppercase mb-2">
                                                        Modelo
                                                    </div>
                                                    <input
                                                        value={newVehicule.model}
                                                        onChange={handleNewVehiculeChange}
                                                        placeholder="Indique o modelo"
                                                        type="text"
                                                        id="model"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="label text-gray text-uppercase mb-2">Ano</div>
                                                    <input
                                                        onChange={handleNewVehiculeChange}
                                                        value={newVehicule.year === 0 ? "" : newVehicule.year}
                                                        placeholder="AAAA"
                                                        maxLength={4}
                                                        type="text"
                                                        id="year"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="label text-gray text-uppercase mb-2">Km's</div>
                                                    <input
                                                        onChange={handleNewVehiculeChange}
                                                        value={newVehicule.kms === 0 ? "" : newVehicule.kms}
                                                        placeholder="Kms atuais"
                                                        type="text"
                                                        id="kms"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            {Loading(load, 30)}
                                            {load === false &&
                                                <div class="text-end buttons mt-3" >
                                                    <Button
                                                        onClick={() => {
                                                            setNewVehicule(null);
                                                        }}
                                                        type="button"
                                                        buttonStyle="btn-black"
                                                        buttonSize="button-size-large" >

                                                        {t("Keywords.back")}
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            CreateVehicule(newVehicule);
                                                        }}
                                                        type="button"
                                                        buttonSize="button-size-large">
                                                        {t("Keywords.save")}
                                                    </Button>

                                                </div>
                                            }
                                        </>}

                                        {vehiculesDetails !== null &&
                                            <>
                                                <Table className="align-items-center table-flush" responsive>
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">Serviço</th>
                                                            <th scope="col">Descrição</th>
                                                            <th scope="col">Kms</th>
                                                            <th scope="col">Estado</th>
                                                            <th scope="col">Data</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {load === false && vehiculesDetails.map((vehicule, key) => { return <VehiculeDetailRow key={key} vehicule={vehicule} /> })}
                                                    </tbody>
                                                </Table>
                                                {Loading(load, 30)}
                                                <div className="text-end buttons">
                                                    <Button
                                                        onClick={() => {
                                                            setVehiculesDetails(null);
                                                            setCurrentVehicule(null);
                                                        }}
                                                        type="button"
                                                        buttonStyle="btn-black"
                                                        buttonSize="button-size-large" >
                                                        {t("Keywords.back")}
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            RemoveVehicule(currentVehicule);
                                                        }}
                                                        type="button"
                                                        buttonSize="button-size-large">
                                                        Eliminar
                                                    </Button>

                                                </div>
                                            </>
                                        }


                                    </div>
                                </div>
                            }
                            {section === 2 &&

                                <div class="col-md-8 col-lg-8 offset-lg-1">
                                    <div class="title-form fw-bold my-4">
                                        Agenda
                                    </div>
                                    <div class="steps-form mt-3">

                                        {schedulesDetails === null ? <>
                                            <Table className="align-items-center table-flush" responsive>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">Oficina</th>
                                                        <th scope="col">Inicio</th>
                                                        <th scope="col">Fim</th>
                                                        <th scope="col">Estado</th>
                                                        <th scope="col">Matrícula</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {load === false && schedules.map((schedule, key) => { return <ScheduleRow key={key} schedule={schedule} scheduleDetail={scheduleDetail} /> })}
                                                </tbody>
                                            </Table>
                                            {Loading(load, 30)}
                                            <div className="text-end buttons">

                                                <Button
                                                    onClick={() => {
                                                        navigate("/Rede-de-Oficinas");
                                                    }}
                                                    type="button"
                                                    buttonStyle="btn-red"
                                                    buttonSize="button-size-large" >
                                                    Novo Agendamento
                                                </Button>
                                            </div>
                                            {paginationSchedule !== null &&
                                                <Pagination
                                                    currentPage={paginationSchedule?.CurrentPage}
                                                    postsPerPage={paginationSchedule?.PageSize}
                                                    totalPosts={paginationSchedule?.TotalCount}
                                                    paginate={(number) => GetSchedules(number, pageSize)}
                                                    scrollTop={false}
                                                />
                                            }
                                        </>
                                            :
                                            <>
                                                <Table className="align-items-center table-flush" responsive>
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">Serviço</th>
                                                            <th scope="col">Descrição</th>
                                                            <th scope="col">Estado</th>
                                                            <th scope="col">Data</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {load === false && schedulesDetails.map((schedule, key) => { return <ScheduleDetailRow key={key} schedule={schedule} /> })}
                                                    </tbody>
                                                </Table>
                                                <div className="text-end buttons">
                                                    <Button
                                                        onClick={() => {
                                                            setCurrentSchedule(null);
                                                            setSchedulesDetails(null);
                                                        }}
                                                        type="button"
                                                        buttonStyle="btn-black"
                                                        buttonSize="button-size-large" >
                                                        {t("Keywords.back")}
                                                    </Button>
                                                    {[Status.Aceite, Status.Pendente].includes(currentSchedule?.status) &&
                                                        <Button
                                                            onClick={() => { CancelSchedule(currentSchedule); }}
                                                            type="button"
                                                            buttonSize="button-size-large">
                                                            Cancelar
                                                        </Button>
                                                    }
                                                </div>

                                            </>}
                                    </div>

                                </div>
                            }
                            {section === 3 &&

                                <div class="col-md-8 col-lg-8 offset-lg-1">
                                    <div class="title-form fw-bold my-4">
                                        Faturas
                                    </div>
                                    <div class="steps-form mt-5">
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Oficina</th>
                                                    <th scope="col">Documento</th>
                                                    <th scope="col">Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </Table>
                                    </div>

                                </div>
                            }
                            {section === 4 &&

                                <div class="col-md-8 col-lg-8 offset-lg-1">
                                    <div class="title-form fw-bold my-4">
                                        Conta
                                    </div>
                                    <div class="steps-form mt-5">
                                        <div class="steps-form mt-5">

                                            {Loading(load, 30)}
                                            {load === false &&
                                                <div class="row g-3">
                                                    <div class="col-6">
                                                        <div class="label text-gray text-uppercase">Nome</div>
                                                        <div className="Step1-displayshop">
                                                            <input
                                                                class="form-control"
                                                                type="text"
                                                                value={user?.firstName}
                                                                id="firstName"
                                                                disabled={disable}
                                                                onChange={HandleSection4}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="label text-gray text-uppercase">Apelido</div>
                                                        <div className="Step1-displayshop">
                                                            <input
                                                                class="form-control"
                                                                type="text"
                                                                value={user?.lastName}
                                                                id="lastName"
                                                                disabled={disable}
                                                                onChange={HandleSection4}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="label text-gray text-uppercase">Email</div>
                                                        <div className="Step1-inputbox">
                                                            <div className="Step1-datecol">
                                                                <input
                                                                    class="form-control"
                                                                    type="text"
                                                                    value={user?.email}
                                                                    id="email"
                                                                    disabled={true}
                                                                    onChange={HandleSection4}

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="label text-gray text-uppercase">Data de nascimento</div>
                                                        <div className="Step1-inputbox">
                                                            <div className="Step1-datecol">
                                                                <input
                                                                    class="form-control"
                                                                    type="date"
                                                                    value={Moment(user?.born?.split("T")[0])?.format("yyyy-MM-DD")}
                                                                    id="born"
                                                                    disabled={disable}
                                                                    onChange={HandleSection4}

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="label text-gray text-uppercase">Nif</div>
                                                        <div className="Step1-displayshop">
                                                            <input
                                                                class="form-control"
                                                                type="number"
                                                                value={user?.nif}
                                                                id="nif"
                                                                disabled={disable}
                                                                onChange={HandleSection4}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="label text-gray text-uppercase">Telefone</div>
                                                        <div className="Step1-displayshop">
                                                            <input
                                                                class="form-control"
                                                                type="number"
                                                                value={user?.phone}
                                                                id="phone"
                                                                disabled={disable}
                                                                onChange={HandleSection4}

                                                            />
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-8">
                                                        <div class="label text-gray text-uppercase">Morada</div>
                                                        <div className="Step1-inputbox">
                                                            <div className="Step1-datecol">
                                                                <input
                                                                    class="form-control"
                                                                    type="text"
                                                                    value={user?.adress}
                                                                    id="adress"
                                                                    disabled={disable}
                                                                    onChange={HandleSection4}

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="label text-gray text-uppercase">País</div>
                                                        <div className="Step1-inputbox">
                                                            <div className="Step1-datecol">
                                                                <input
                                                                    class="form-control"
                                                                    type="text"
                                                                    value={user?.country}
                                                                    id="country"
                                                                    disabled={disable}
                                                                    onChange={HandleSection4}

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="label text-gray text-uppercase">Código Postal</div>
                                                        <div className="Step1-displayshop">
                                                            <input
                                                                class="form-control"
                                                                type="text"
                                                                value={user?.postalCode}
                                                                id="postalCode"
                                                                disabled={disable}
                                                                onChange={HandleSection4}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="label text-gray text-uppercase">Cidade</div>
                                                        <div className="Step1-displayshop">
                                                            <input
                                                                class="form-control"
                                                                type="text"
                                                                value={user?.city}
                                                                id="city"
                                                                disabled={disable}
                                                                onChange={HandleSection4}

                                                            />
                                                        </div>
                                                    </div>

                                                    {[Role.User].includes(user?.role) &&
                                                        <div class="col-12">
                                                            <div class="label text-gray text-uppercase">Oficina Pré-definida</div>
                                                            <div className="Step1-displayshop">
                                                                <select
                                                                    class="form-select"
                                                                    value={user?.defaultWorkshop}
                                                                    id="defaultWorkshop"
                                                                    onChange={HandleSection4}
                                                                    disabled={disable}>
                                                                    <option value="" disabled >
                                                                        Selecionar uma oficina
                                                                    </option>
                                                                    {workShopList?.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id} label={item.title} />
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className="text-end buttons">
                                                        {disable === true ?
                                                            <Button
                                                                onClick={() => {
                                                                    setDisable(false);
                                                                }}
                                                                type="button"
                                                                buttonSize="button-size-large">
                                                                Editar
                                                            </Button>
                                                            : <>
                                                                <Button
                                                                    onClick={() => {
                                                                        setDisable(true);
                                                                        GetCurrentUser(currentUser?.id);
                                                                    }}
                                                                    type="button"
                                                                    buttonStyle="btn-black"
                                                                    buttonSize="button-size-large" >
                                                                    Cancelar
                                                                </Button>
                                                                <Button
                                                                    onClick={() => {
                                                                        UpdateUser(user);
                                                                    }}
                                                                    type="button"
                                                                    buttonSize="button-size-large">
                                                                    {t("Keywords.save")}
                                                                </Button>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AreaDeCliente;
