import React from 'react';
import { Badge, Media } from "reactstrap";
import Moment from 'moment';
import 'moment/locale/pt'
Moment.locale('pt');



const ScheduleDetailRow = ({ schedule }) => {

    const colorstatus = (status) => {
        switch (status) {
            case 'Cancelado':
                return <div style={{ color: 'red' }}>{schedule?.statusService}</div>
            case 'Validado':
                return <div style={{ color: 'orange' }}>{schedule?.statusService}</div>
            case 'Montado':
                return <div style={{ color: 'green' }}>{schedule?.statusService}</div>
            case 'Sugestao':
                return <div style={{ color: 'orange' }}>{schedule?.statusService}</div>
            case 'Pendente':
                return <div style={{ color: 'black' }}>{schedule?.statusService}</div>
                
            default:
                return status;
        }
    }

    return (
        <tr>
            <th scope="row">
                {schedule?.name}
            </th>
            <td>
                {schedule?.description}
            </td>
            <td>
                {colorstatus(schedule?.statusService)}
            </td>
            <td >
                {Moment(schedule?.created).format('LL')}
            </td>
        </tr>
    );
}

export default ScheduleDetailRow;