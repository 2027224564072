import "./MaintenencePacksCarMain.css";
import BannerInterior from "../../../components/BannerInterior/BannerInterior";
import banner from "../../../img/banners/redes-oficinas.jpg";
import imgPackManutencao from "../../../img/servicos-oficina/pack-manutencao.jpg"
import iconCheck from "../../../img/servicos-oficina/pack-check.svg";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';

function MaintenencePacksCarMain() {
    let navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <div className="App">
            <BannerInterior name={t("WorkShopServices.Maintenance.banner_name")} imagem={banner} />
            <div class="section packs py-5">
                <div class="container py-5">
                    <div class="row g-5 pb-5">
                        <div class="col-lg-6">
                            <div class="section_sub_title text-red text-uppercase mb-4">
                                {t("WorkShopServices.Maintenance.sub_title")}
                            </div>
                            <div class="section_title fw-bold mb-5">
                                {t("WorkShopServices.Maintenance.title")}
                            </div>
                            <Trans i18nKey="WorkShopServices.Maintenance.description" />
                        </div>
                        <div class="col-lg-6 text-end">
                            <img src={imgPackManutencao} />
                        </div>
                    </div>
                    <div class="row pt-5">
                        <div class="col-lg-4">
                            <div class="card pack h-100 border-dark border-2 rounded-0 p-sm-4">
                                <div class="card-body d-flex flex-column">
                                    <div class="title text-center">
                                        Revisão
                                        <br /> Simples
                                        </div>
                                    <div class="price-block text-red mx-auto mt-4 mb-5">
                                        {/*<div class="since text-start">desde</div>
                                        <div class="price fw-bold">
                                            65<span class="euro fw-normal">€</span>
                                        </div>
                                        */}
                                    </div>
                                    <div class="features text-start mb-4">
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>Mudança de Óleo</div>
                                        </div>
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>Mudança de Filtro de Óleo</div>
                                        </div>
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>
                                                Reposição dos níveis de líquido limpa vidros, do líquido
                                                refrigerante
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>Pressão dos pneus</div>
                                        </div>
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>Check-up de 25 Pontos</div>
                                        </div>
                                    </div>
                                    <div class="mt-auto text-center">
                                        <Button
                                            onClick={() => {
                                                navigate("/Servicos-de-Oficina/Manutencao-e-Reparacao/Simples");
                                            }}
                                            type="button"
                                            buttonSize="btn-lg"
                                        >
                                            Fazer marcação
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card pack h-100 border-dark border-2 rounded-0 p-sm-4">
                                <div class="card-body d-flex flex-column">
                                    <div class="title text-center">
                                        Revisão
                                        <br /> Profissional
                                    </div>
                                    <div class="price-block text-red mx-auto mt-4 mb-5">
                                        {/* <div class="since text-start">desde</div>
                                        <div class="price fw-bold">
                                            85<span class="euro fw-normal">€</span>
                                        </div>
                                        */}
                                    </div>
                                    <div class="features text-start mb-4">
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>Mudança de Óleo</div>
                                        </div>
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>Mudança de Filtro de Óleo</div>
                                        </div>
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>Mudança de Filtro de Ar</div>
                                        </div>
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>
                                                Reposição dos níveis de líquido limpa vidros, do líquido
                                                refrigerante
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>Pressão dos pneus</div>
                                        </div>
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>Check-up de 25 Pontos</div>
                                        </div>
                                    </div>
                                    <div class="mt-auto text-center">
                                        <Button
                                            onClick={() => {
                                                navigate("/Servicos-de-Oficina/Manutencao-e-Reparacao/Profissional");
                                            }}
                                            type="button"
                                            buttonSize="btn-lg"
                                        >
                                            Fazer marcação
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card pack h-100 border-dark border-2 rounded-0 p-sm-4">
                                <div class="card-body d-flex flex-column">
                                    <div class="title text-center">
                                        Revisão
                                        <br /> Total
                                    </div>
                                    <div class="price-block text-red mx-auto mt-4 mb-5">
                                        {/* <div class="since text-start">desde</div>
                                        <div class="price fw-bold">
                                            110<span class="euro fw-normal">€</span>
                                        </div>
                                        */}
                                    </div>
                                    <div class="features text-start mb-4">
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>Mudança de Óleo</div>
                                        </div>
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>Mudança de Filtro de Óleo</div>
                                        </div>
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>Mudança de Filtro de Ar</div>
                                        </div>
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>Mudança de Filtro de Combustível</div>
                                        </div>
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>
                                                Reposição dos níveis de líquido limpa vidros, do líquido
                                                refrigerante
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>Pressão dos pneus</div>
                                        </div>
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconCheck} />
                                            <div>Check-up de 45 Pontos</div>
                                        </div>
                                    </div>
                                    <div class="mt-auto text-center">
                                        <Button
                                            onClick={() => {
                                                navigate("/Servicos-de-Oficina/Manutencao-e-Reparacao/Total");
                                            }}
                                            type="button"
                                            buttonSize="btn-lg"
                                        >
                                            Fazer marcação
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MaintenencePacksCarMain;
