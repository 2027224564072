import './Block2.css';
import iconClimatizacao from '../../../img/main/block2/icon-climatizacao.svg';
import iconTravagem from '../../../img/main/block2/icon-travagem.svg';
import iconEletronica from '../../../img/main/block2/icon-eletronica.svg';
import iconVerTudo from '../../../img/main/block2/icon-vertudo.svg';
import imagemRight from '../../../img/main/block2/img-1.png';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function Block2() {
    let navigate = useNavigate();
    const handleClickRedService = () => { navigate("/RedService/Servicos"); };
    const { t } = useTranslation();

    return (
        <div className="section my-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h5 class="section_sub_title text-red">{

                            t('Main.Block2.sub_title')}</h5>
                        <h2 class="section_title">{t('Main.Block2.title')}</h2>
                        <p>{t('Main.Block2.description')}</p>
                        {/* Links */}
                        <div class="row mt-4">
                            <div class="col-6 col-xl-3 mb-3">
                                <Button onClick={handleClickRedService} type="button" buttonStyle="btn-grey icon">
                                    <img alt="" class="icon-btn" src={iconClimatizacao} />
                                    <h6>{t('Main.Block2.service1')}</h6>
                                </Button>
                            </div>
                            <div class="col-6 col-xl-3 mb-3">
                                <Button onClick={handleClickRedService} type="button" buttonStyle="btn-grey icon">
                                    <img alt="" class="icon-btn" src={iconTravagem} />
                                    <h6>{t('Main.Block2.service2')}</h6>
                                </Button>
                            </div>
                            <div class="col-6 col-xl-3 mb-3">
                                <Button onClick={handleClickRedService} type="button" buttonStyle="btn-grey icon">
                                    <img alt="" class="icon-btn" src={iconEletronica} />
                                    <h6>{t('Main.Block2.service3')}</h6>
                                </Button>
                            </div>
                            <div class="col-6 col-xl-3 mb-3">
                                <Button onClick={handleClickRedService} type="button" buttonStyle="btn-grey icon">
                                    <img alt="" class="icon-btn" src={iconVerTudo} />
                                    <h6>{t('Main.Block2.serviceAll')}</h6>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img class="ps-5" src={imagemRight} />
                    </div>
                </div>


            </div>
        </div>

    );
}

export default Block2;