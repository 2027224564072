import { useSelector } from 'react-redux';
import { removeAllAccounts, changeAccount } from 'store/accounts/accounts.js';
import { ClipLoader } from "react-spinners";

export function GetHeader() {
    return { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` };
}
export function GetHeaderPut() {
    return { 'Accept': '*/*', 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('jwtToken')} ` };
}


//Users
export function CurrentUser() {
    const accounts = useSelector(state => state.accounts);
    const currentUser = accounts.length > 0 ? accounts[accounts.length - 1] : null;
    return currentUser !== null && currentUser !== undefined ? currentUser : null;
}

export function UpdateUser(account) {
    global.dispatch(changeAccount(account));
}

//Loader
export function Loading(visible, size) {
    return (visible === true ?
        <div className="pagination justify-content-center mt-5">
            <ClipLoader color={'#E2241A'} css={'border-color: #E2241A;'} size={size !== null ? size : 20} />
        </div>
        : null)
}

export function LoadingFixed(visible, size) {
    return (visible === true ?
        <ClipLoader color={'#E2241A'} css={'border-color: #E2241A'} size={size !== null ? size : 20} /> : null)
}


export function RemoveLoginCockies() {
    localStorage.setItem('refreshToken', '')
    localStorage.setItem('jwtToken', '')
}

export function RemoveUser(dispatch) {
    dispatch(removeAllAccounts());
    RemoveLoginCockies();
}

export const Status = {
    Validacao: 'Validacao',
    Pendente: 'Pendente',
    Aceite: 'Aceite',
    Rejeitado: 'Rejeitado',
    Oficina: 'Oficina',
    Concluido: 'Concluido',
    Entregue: 'Entregue',
    Cancelado: 'Cancelado'
}

export const Role = {
    Admin: "Admin",
    User: "User",
    Oficina: "Oficina",
    GestorOficina: "GestorOficina",
    Mecanico: "Mecanico"
};


