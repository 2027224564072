import "./Join.css";
import BannerInterior from "../../components/BannerInterior/BannerInterior";
import banner from "../../img/banners/redes-oficinas.jpg";
import iconTools from "../../img/junte-se/icon-tools.svg";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import React from "react";
import toast from "react-hot-toast";
import { useTranslation, Trans } from 'react-i18next';
import axios from 'axios';
import { Loading } from "../../utils/Utilities";


function Join() {
    let navigate = useNavigate();
    const [load, setLoad] = useState(false);
    const [join, setJoin] = useState({ name: "", company: "", contact: "", email: "", adress: "", postalcode: "", employees: 0, area: 0, observations: "", terms: false });
    const handleChangeJoinUs = (e) => {
        const { id, value, checked } = e.target;

        if (id === "term") {
            setJoin({ ...join, [id]: checked });
        } else {
            setJoin({ ...join, [id]: value });
        }
    }
    var emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //Conte @ e .


    const CreateJoin = (data) => {
        setLoad(true)
        axios.post(`${global.api_url}JoinUs`, data)
            .then((response) => {
                setLoad(false);
                if (response.status === 200) {
                    toast.success("Registo Feito com sucesso!", { duration: 4000, });
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "instant",
                    });
                    navigate("/");
                }
            }).catch(error => {
                setLoad(false);
                toast.error("Erro a efetuar o registo!", { duration: 4000 });
            })
    }


    const sendData = () => {
        if (validateData()) {
            CreateJoin(join);
        }
    };

    const validateData = () => {
        if (join.name.trim() == "") {
            toast.error("Tem de preencher o nome");
        } else if (join.company.trim() === "") {
            toast.error("Tem de preencher a empresa");
        } else if (join.contact.trim() === "") {
            toast.error("Tem de preencher o contacto");
        } else if (join.email.trim() === "") {
            toast.error("Tem de preencher o email");
        } else if (join.adress.trim() === "") {
            toast.error("Tem de preencher a morada");
        } else if (join.postalcode.trim() === "") {
            toast.error("Tem de preencher o código postal");
        } else if (join.city.trim() === "") {
            toast.error("Tem de preencher a localidade");
        } else if (!join.email.match(emailPattern)) {
            toast.error("Email inválido!");
        } else if (!join.terms) {
            toast.error(
                "Tem de concordar com os Termos & Condições e a Política de Privacidade "
            );
        } else {
            return true;
        }
    };

    const { t } = useTranslation();

    const details = t("Join.details", { returnObjects: true });

    return (
        <div className="App">
            <BannerInterior name={t("Join.banner_name")} imagem={banner} />

            <div class="section join-us py-5">
                <div class="container py-5">
                    <div class="row pb-0">
                        <div class="col-md-12">
                            <div class="section_sub_title text-red text-uppercase mb-4">
                                {t("Join.sub_title")}
                            </div>
                        </div>
                    </div>
                    <div class="row gx-5 gy-lg-0 gy-5">
                        <div class="col-lg-5">
                            <div class="section_title fw-bold mb-4">{t("Join.title")}</div>
                            <div class="detail mb-5">
                                <Trans i18nKey="Join.description" />
                                {/*<p>*/}
                                {/*    Se quiser juntar-se a um grupo de profissionais de alto nível*/}
                                {/*    e usufruir de todas as vantagens de pertencer à Rede*/}
                                {/*    RedService, contate-nos para receber toda a informação*/}
                                {/*    necessária.*/}
                                {/*</p>*/}
                            </div>

                            {Array.isArray(details) && details.map(detail => {
                                return (
                                    <div class="card box-join text-center rounded-0 mb-4">
                                        <div class="card-body py-md-4">
                                            <div class="row align-items-center">
                                                <div class="col-md-4 col-offset-md-1 col-2">
                                                    <img src={iconTools} alt="tools" class="mx-auto" />
                                                </div>
                                                <div class="col-md-7 col-10 text-start">
                                                    <div class="title text-red fw-bold mb-2">{detail.title}</div>
                                                    <div class="content font-roboto">
                                                        <Trans i18nKey={detail.description} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );

                            })}

                            {/*<div class="card box-join text-center rounded-0 mb-4">*/}
                            {/*    <div class="card-body py-md-4">*/}
                            {/*        <div class="row align-items-center">*/}
                            {/*            <div class="col-md-4 col-offset-md-1 col-2">*/}
                            {/*                <img src={iconTools} class="mx-auto" />*/}
                            {/*            </div>*/}
                            {/*            <div class="col-md-7 col-10 text-start">*/}
                            {/*                <div class="title text-red fw-bold mb-2">Formação</div>*/}
                            {/*                <div class="content font-roboto">*/}
                            {/*                    Formação qualificada, a nível técnico e comercial.*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div class="card box-join text-center rounded-0 mb-4">*/}
                            {/*    <div class="card-body py-md-4">*/}
                            {/*        <div class="row align-items-center">*/}
                            {/*            <div class="col-md-4 col-offset-md-1 col-2">*/}
                            {/*                <img src={iconTools} class="mx-auto" />*/}
                            {/*            </div>*/}
                            {/*            <div class="col-md-7 col-10 text-start">*/}
                            {/*                <div class="title text-red fw-bold mb-2">Imagem</div>*/}
                            {/*                <div class="content font-roboto">*/}
                            {/*                    Dispomos de uma imagem moderna e inovadora.*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div class="card box-join text-center rounded-0 mb-4">*/}
                            {/*    <div class="card-body py-md-4">*/}
                            {/*        <div class="row align-items-center">*/}
                            {/*            <div class="col-md-4 col-offset-md-1 col-2">*/}
                            {/*                <img src={iconTools} class="mx-auto" />*/}
                            {/*            </div>*/}
                            {/*            <div class="col-md-7 col-10 text-start">*/}
                            {/*                <div class="title text-red fw-bold mb-2">Marketing</div>*/}
                            {/*                <div class="content font-roboto">*/}
                            {/*                    Utilização das novas ferramentas de marketing.*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div class="col-lg-6 offset-lg-1">
                            <div class="form-join-us">
                                <div class="border-bottom pb-4">
                                    <div class="form-title text-gray text-uppercase mb-3">
                                        <Trans i18nKey="Join.form.title" />
                                    </div>
                                    <div class="row g-3">
                                        <div class="col-md-12">
                                            <input
                                                onChange={handleChangeJoinUs}
                                                type="text"
                                                id="name"
                                                value={join.name}
                                                placeholder={t("Join.form.name")}
                                                className="form-control"
                                            />
                                        </div>
                                        <div class="col-md-12">
                                            <input
                                                onChange={handleChangeJoinUs}
                                                type="text"
                                                id="company"
                                                value={join.company}
                                                placeholder={t("Join.form.company")}
                                                className="form-control"
                                            />
                                        </div>
                                        <div class="col-md-5">
                                            <input
                                                onChange={handleChangeJoinUs}
                                                type="text"
                                                id="contact"
                                                value={join.contact}
                                                placeholder={t("Join.form.contact")}
                                                className="form-control"
                                            />
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                type="text"
                                                id="email"
                                                value={join.email}
                                                onChange={handleChangeJoinUs}
                                                placeholder={t("Join.form.email")}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="border-bottom py-4">
                                    <div class="form-title text-gray text-uppercase mb-3">
                                        <Trans i18nKey="Join.form.title2" />
                                    </div>
                                    <div class="row g-3">
                                        <div class="col-md-12">
                                            <input
                                                onChange={handleChangeJoinUs}
                                                type="text"
                                                id="adress"
                                                value={join.adress}
                                                placeholder={t("Join.form.adress")}
                                                className="form-control"
                                            />
                                        </div>
                                        <div class="col-md-5">
                                            <input
                                                onChange={handleChangeJoinUs}
                                                type="text"
                                                id="postalcode"
                                                value={join.postalcode}
                                                placeholder={t("Join.form.postalcode")}
                                                className="form-control"
                                            />
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                type="text"
                                                id="city"
                                                value={join.city}
                                                onChange={handleChangeJoinUs}
                                                placeholder={t("Join.form.locality")}
                                                className="form-control"
                                            />
                                        </div>
                                        <div class="col-md-7">
                                            <input
                                                onChange={handleChangeJoinUs}
                                                type="number"
                                                id="employees"
                                                value={join.employees !== 0 ? join.employees : ""}
                                                placeholder={t("Join.form.empliyees")}
                                                className="form-control"
                                            />
                                        </div>
                                        <div class="col-md-5">
                                            <input
                                                type="number"
                                                value={join.area !== 0 ? join.area : ""}
                                                id="area"
                                                onChange={handleChangeJoinUs}
                                                placeholder={t("Join.form.size")}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="py-4">
                                    <div class="form-title text-gray text-uppercase mb-3">
                                        <Trans i18nKey="Join.form.title3" />
                                    </div>
                                    <div class="row g-3">
                                        <div class="col-md-12">
                                            <textarea
                                                value={join.observations}
                                                onChange={handleChangeJoinUs}
                                                id="observations"
                                                placeholder={t("Join.form.observation")}
                                                className="form-control"
                                            />
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    value={join.terms}
                                                    id="terms"
                                                    onChange={handleChangeJoinUs}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                />
                                                <label class="form-check-label" for="agreement">
                                                    {t("Join.form.agreement")}

                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            {Loading(load)}
                                            {load === false &&
                                                <Button
                                                    onClick={() => {
                                                        sendData();
                                                    }}
                                                    type="button"
                                                    buttonStyle="button-loginscreen-solid"
                                                    buttonSize="button-size-medium"
                                                >
                                                    {t("Join.form.button")}
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Join;
