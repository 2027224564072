import './Block1.css';
import imagem1 from '../../../img/main/block1/img-1.png';
import Button from '../../../components/Button';
import { useNavigate } from "react-router-dom";
//import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function Block1() {
    let navigate = useNavigate();
    //const handleClickRedeOficinas = () => { navigate("/Rede-de-Oficinas"); };
    //const handleClickCampanhas = () => { navigate("/Campanhas"); };
    const { t } = useTranslation();
    const banners = t("Main.Block1.banner", { returnObjects: true });

    return (
        <div class="banner w-100 d-block p-0 m-0 position-relative">
            <img class="" alt="" src={imagem1} />
            <div class="h-100 w-100 position-absolute top-0">
                <div class="container h-100"> {/*d-flex*/}

                    <div class="row justify-content-center align-self-center" style={{ marginTop: window.innerWidth * 0.10 }}>
                        <Carousel autoPlay={true} infiniteLoop={true} swipeable={true} showArrows={false} centerMode={false}>
                            {Array.isArray(banners) && banners.map(detail => {
                                return (
                                    <div style={{ resize: 'both' }}>
                                        <div class="col-12" style={{ textAlign: 'left' }}>
                                            <h2 class="title-banner-carousel"><Trans i18nKey={detail.title} /><br /><span class="text-red"><Trans i18nKey={detail.titlered} /></span></h2>
                                        </div>
                                        <div class="col-12 mt-4">
                                            <div class="d-flex">
                                                {detail.button !== "" && detail.button !== null && detail.navigate !== "" && detail.navigate !== null &&
                                                    <Button onClick={() => { detail.navigate.startsWith('/') ? navigate(detail.navigate) : window.open(detail.navigate, '_blank') }} type="button" buttonStyle="btn-red">
                                                        {/*<Trans i18nKey={detail.button} />*/}
                                                        {t(detail.button)}
                                                    </Button>
                                                }
                                                <div className='ms-4'></div>
                                                {detail.buttonred !== "" && detail.buttonred !== null && detail.navigatered !== "" && detail.navigatered !== null &&
                                                    <Button onClick={() => { detail.navigatered.startsWith('/') ? navigate(detail.navigatered) : window.open(detail.navigatered, '_blank') }} type="button" buttonStyle="btn-white">
                                                        {/*<Trans i18nKey={detail.buttonred} />*/}
                                                        {t(detail.buttonred)}
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}


                            {/*<div>*/}
                            {/*    <div class="col-12" style={{ textAlign: 'left' }}>*/}
                            {/*        <h2 class="title-banner">{t("Main.Block1.title")}<br /><span class="text-red">{t("Main.Block1.title1")}</span></h2>*/}
                            {/*    </div>*/}
                            {/*    <div class="col-12 mt-4">*/}
                            {/*        <div class="d-flex">*/}
                            {/*            <Button onClick={handleClickRedeOficinas} type="button" buttonStyle="btn-red">*/}
                            {/*                {t("Main.Block1.sub_title")}*/}
                            {/*            </Button>*/}
                            {/*            <div className='ms-4'></div>*/}
                            {/*            <Button onClick={handleClickRedeOficinas} type="button" buttonStyle="btn-white">*/}
                            {/*                {t("Main.Block1.button")}*/}
                            {/*            </Button>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div >*/}
                            {/*    <div class="col-12" style={{ textAlign: 'left' }}>*/}
                            {/*        <h2 class="title-banner">Aplicação móvel grátis<br /><span class="text-red">Android e IOS</span></h2>*/}
                            {/*    </div>*/}
                            {/*    <div class="col-12 mt-4">*/}
                            {/*        <div class="d-flex">*/}
                            {/*            <Button onClick={(e) => {*/}
                            {/*                window.open('http://www.example.com');*/}
                            {/*            }} type="button" buttonStyle="btn-red">*/}
                            {/*                <i class="fab fa-2x fa-android"></i> Android*/}
                            {/*            </Button>*/}
                            {/*            <div className='ms-4'></div>*/}
                            {/*            <Button onClick={(e) => {*/}
                            {/*                window.open('http://www.example.com');*/}
                            {/*            }} type="button" buttonStyle="btn-white">*/}
                            {/*                <i class="fab fa-2x fa-apple"></i> IOS*/}
                            {/*            </Button>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}


                            {/*<div >*/}
                            {/*    <div class="col-12" style={{ textAlign: 'left' }}>*/}
                            {/*        <h2 class="title-banner">Campanha de escovas<br /><span class="text-red">-20%</span></h2>*/}
                            {/*    </div>*/}
                            {/*    <div class="col-12 mt-4">*/}
                            {/*        <div class="d-flex">*/}
                            {/*            <div className='ms-4'></div>*/}
                            {/*            <Button onClick={handleClickCampanhas} type="button" buttonStyle="btn-white">*/}
                            {/*                Ver campanhas*/}
                            {/*            </Button>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </Carousel>
                    </div>



                </div>
            </div>
        </div >
    );
}

export default Block1;