import './Block5.css';
import bgImage from '../../../img/main/block5/bg-img.svg';
import acordo1 from '../../../img/main/block5/logo_bp.svg';
import acordo2 from '../../../img/main/block5/logo_leaseplan.svg';
import acordo3 from '../../../img/main/block5/logo_gras_savoye.svg';
import acordo4 from '../../../img/main/block5/logo_expressglass.svg';
import Button from '../../../components/Button';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Block5() {
    let navigate = useNavigate();
    const handleClickAcordos = () => { navigate("/Acordos"); };
    const { t } = useTranslation();
    return (
        <div className="section my-5">
            <div class="container-fluid p-0 bg-red p-5 overflow-hideen position-relative">
                <img class="img-cover w-100 h-100 position-absolute bottom-0 end-0 overlay-15" src={bgImage} />
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 my-4">
                            <h5 class="section_sub_title text-white">{t("Main.Block5.sub_title")}</h5>
                            <h2 class="section_title text-white font-42">{t("Main.Block5.title")}</h2>
                        </div>
                        <div class="col-md-6 my-4">
                            <div class="d-flex h-100 position-relative overflow-hidden">
                                <div class="d-flex justify-content-end align-self-center w-100">
                                    <Button onClick={handleClickAcordos} type="button" buttonStyle="btn-white">
                                        {t("Main.Block5.button")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="acordos">
                <div class="container-fluid p-0">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-4">
                            <div class="bg-darker">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="d-flex h-100 position-relative overflow-hidden">
                                            <div class="row justify-content-center align-self-center p-2 p-md-3 p-lg-5">
                                                <img class="img-acordo" src={acordo1} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="d-flex h-100 position-relative overflow-hidden">
                                            <div class="row justify-content-center align-self-center p-2 p-md-3 p-lg-5">
                                                <img class="img-acordo" src={acordo2} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="d-flex h-100 position-relative overflow-hidden">
                                            <div class="row justify-content-center align-self-center p-2 p-md-3 p-lg-5">
                                                <img class="img-acordo" src={acordo3} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="d-flex h-100 position-relative overflow-hidden">
                                            <div class="row justify-content-center align-self-center p-2 p-md-3 p-lg-5">
                                                <img class="img-acordo" src={acordo4} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Block5;