import "./FinalStep.css";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from 'axios';
import { CurrentUser, Loading } from "../../../utils/Utilities";
import Moment from 'moment';
import 'moment/locale/pt';

function FinalStep(props) {
    let navigate = useNavigate();
    const currentUser = CurrentUser();
    const timesList = [{ value: 9, text: "Manhã", }, { value: 14, text: "Tarde" }];
    const [schedule, setSchedule] = useState(null);
    const [points, setPoints] = useState([]);
    const [time, setTime] = useState(null);
    useEffect(() => {
        setSchedule(props?.appointmentData?.schedule);
        setTime(Moment(schedule?.start).format('LT').toString().startsWith("14") ? timesList[1] : timesList[0])
        getAllWorkshops();
    }, []);

    const getAllWorkshops = async () => {
        await axios.get(global.api_url + "Workshops/GetAllFront")
            .then((response) => {
                var data = response.data;
                setPoints(data);
            }).catch(error => {
                setPoints([]);
                toast.error(error, { duration: 4000, });
            })
    }

    const [date, setDate] = useState(props.appointmentData.date);
    const [load, setLoad] = useState(false);
    var emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //Conte @ e .

    const [agreement, setAgreement] = useState(props.appointmentData.agreement);

    //Step 1 Handlers & Methods
    const handleTimeChange = (e) => {
        var index = e.nativeEvent.target.selectedIndex;
        var textSelect = e.nativeEvent.target[index].text;
        setTime([{ value: e.target.value, text: textSelect }]);
    };

    const handleDateChange = (e) => { setDate(e.target.value); };
    //Validar data
    const checkDateValidity = () => {
        var today = new Date().setHours(0, 0, 0, 0);
        var dateGiven = new Date(date);
        if (today > dateGiven) {
            return false;
        } else {
            return true;
        }
    };

    const handleAgreementChange = (e) => { setAgreement(!agreement); };

    const handleChangeSchdule = (e) => {
        const { id, value } = e.target;
        setSchedule({ ...schedule, [id]: value });
    }


    const validate = () => {

        if (schedule?.start?.toString().trim() === "") {
            toast.error("Tem de preencher a data.");
        } else if (time === null) {
            toast.error("Tem de preencher o horário.");
        } else if (!checkDateValidity()) {
            toast.error("Introduza uma data futura.");
        } else if (schedule?.brand === "") {
            toast.error("Tem de selecionar a marca.");
        } else if (schedule?.model.trim() === "") {
            toast.error("Tem de preencher o modelo.");
        } else if (schedule?.kms.toString().trim() === "") {
            toast.error("Tem de preencher os kilómetros.");
        } else if (schedule?.firstName.trim() === "") {
            toast.error("Tem de preencher o nome.");
        } else if (schedule?.lastName.trim() === "") {
            toast.error("Tem de preencher o apelido.");
        } else if (schedule?.phone?.toString().trim() === "") {
            toast.error("Tem de preencher o contacto.");
        } else if (schedule?.email.trim() === "") {
            toast.error("Tem de preencher a email.");
        } else if (!agreement) {
            toast.error(
                "Tem de aceitar os termos & condições e a política de privacidade."
            );
        } else if (!schedule.email.match(emailPattern)) {
            toast.error("Email inválido!");
        } else {
            return true;
        }
    };

    const sendData = () => {
        if (validate()) {
            setLoad(true)
            axios.post(`${global.api_url}Schedules/New`, schedule)
                .then((response) => {
                    setLoad(false)
                    if (response.status === 200) {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "instant",
                        });
                        toast.success("Marcação feita com sucesso!", { duration: 4000 });
                        navigate("/")
                    }
                }).catch(error => {
                    setLoad(false)
                    toast.error("Erro a efetuar a marcação!", { duration: 4000 });
                })
        }
    };


    return (
        <div className="Schedule-main">
            <div className="section marcacao py-5">
                <div className="container pb-5">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="title-form fw-bold my-4">
                                Marcação do Serviço de Oficina
                            </div>
                        </div>
                    </div>
                    <div className="row border-bottom g-2 py-4">
                        <div className="col-lg-3">
                            <div className="text-gray text-uppercase mb-4">Oficina</div>
                        </div>
                        <div className="col-lg-9">
                            <div className="row g-3">
                                <div className="col-12">
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={schedule?.title}
                                        aria-label={props.appointmentData.shop}
                                        disabled
                                        readonly
                                    />
                                </div>
                                <div className="col-12">
                                    <select
                                        className="form-select"
                                        value={schedule?.workShopId}
                                        onChange={(e) => { setSchedule({ ...schedule, workShopId: e.target.value }); }}>
                                        <option value="" disabled selected>
                                            Selecionar uma oficina
                                        </option>
                                        {points?.map((item) => {
                                            return (<option key={item.id} value={item.id} label={item.title}>{item.title}</option>);
                                        })}
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        value={Moment(schedule?.start).format('yyyy-MM-DD')}
                                        type="date"
                                        onChange={handleDateChange}
                                        placeholder="dd/mm/aaaa"
                                        id="start"
                                        className="form-control"
                                        disabled
                                    />
                                </div>


                                <div className="col-sm-6">
                                    <select
                                        className="form-select"
                                        value={Moment(schedule?.start).format('LT').toString().startsWith("14") ? timesList[1].value : timesList[0].value}
                                        onChange={handleTimeChange}
                                        disabled
                                    >
                                        <option value="" disabled selected>
                                            Selecionar um Período
                                        </option>
                                        {timesList?.map((item) => {
                                            return (
                                                <option
                                                    key={item.value}
                                                    value={item.value}
                                                    label={item.text}
                                                >
                                                    {item.text}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row border-bottom g-2 py-4">
                        <div className="col-lg-3">
                            <div className="text-gray text-uppercase mb-4">Dados Pessoais</div>
                        </div>
                        <div className="col-lg-9">
                            <div className="row g-3">
                                <div className="col-6">
                                    <input
                                        value={schedule?.firstName}
                                        onChange={handleChangeSchdule}
                                        placeholder="Nome"
                                        type="text"
                                        id="firstName"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-6">
                                    <input
                                        value={schedule?.lastName}
                                        onChange={handleChangeSchdule}
                                        placeholder="Nome"
                                        type="text"
                                        id="lastName"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        value={schedule?.phone}
                                        onChange={handleChangeSchdule}
                                        placeholder="Contacto"
                                        type="text"
                                        id="phone"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        onChange={handleChangeSchdule}
                                        value={schedule?.email}
                                        placeholder="Email"
                                        type="text"
                                        id="email"
                                        disabled={currentUser !== null ? true : false}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row border-bottom g-2 py-4">
                        <div className="col-lg-3">
                            <div className="text-gray text-uppercase mb-4">Dados da Viatura</div>
                        </div>
                        <div className="col-lg-9">
                            <div className="row g-3">
                                <div className="col-sm-6">
                                    <input
                                        value={schedule?.plate}
                                        placeholder="Indique uma matrícula"
                                        type="text"
                                        id="plate"
                                        maxLength={8}
                                        className="form-control"
                                        onChange={handleChangeSchdule}
                                        disabled={schedule?.vehiculeId !== 0 ? true : false}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        value={schedule?.brand}
                                        type="text"
                                        id="brand"
                                        className="form-control"
                                        onChange={handleChangeSchdule}
                                        disabled={schedule?.vehiculeId !== 0 ? true : false}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        value={schedule?.model}
                                        onChange={handleChangeSchdule}
                                        placeholder="Indique o modelo"
                                        type="text"
                                        id="model"
                                        className="form-control"
                                        disabled={schedule?.vehiculeId !== 0 ? true : false}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        onChange={handleChangeSchdule}
                                        value={schedule?.kms}
                                        placeholder=" Km's"
                                        type="text"
                                        id="kms"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row border-bottom g-2 py-4">
                        <div className="col-lg-3">
                            <div className="text-gray text-uppercase mb-4">Pack Manutenção</div>
                        </div>
                        <div className="col-lg-9">
                            <div className="row g-3">
                                <div className="col-sm-12">
                                    <div className="input-group">
                                        <span className="input-group-text">Pack Selecionado</span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={props.appointmentData.pack}
                                            aria-label={props.appointmentData.pack}
                                            aria-describedby={props.appointmentData.pack}
                                            disabled
                                            readonly
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group">
                                        <span className="input-group-text">Tipo de Óleo</span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={props.appointmentData.oilType}
                                            aria-label={props.appointmentData.oilType}
                                            aria-describedby={props.appointmentData.oilType}
                                            disabled
                                            readonly
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group">
                                        <span className="input-group-text">Serviço Adicional</span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={props.appointmentData.extraService}
                                            aria-label={props.appointmentData.extraService}
                                            aria-describedby={props.appointmentData.extraService}
                                            disabled
                                            readonly
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 offset-md-6 col-6 offset-6">
                                    <div className="input-group">
                                        <span className="input-group-text">Valor</span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={props.appointmentData.finalPrice + "€"}
                                            aria-label={props.appointmentData.finalPrice + "€"}
                                            aria-describedby={props.appointmentData.finalPrice + "€"}
                                            disabled
                                            readonly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row border-bottom g-2 py-4">
                        <div className="col-lg-3">
                            <div className="text-gray text-uppercase mb-4">
                                Observações
                                <span className="d-block small my-2">(Opcional)</span>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="row justify-content-between gy-3">
                                {props.appointmentData.serviceTypes !== null && props.appointmentData.serviceTypes.map(serv => {
                                    if (!serv.name.startsWith("Outro")) {

                                        return (
                                            <div className="col-md-4">
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        checked={serv?.check}
                                                        onChange={() => {
                                                            serv.check = serv?.check === false ? true : false;

                                                            if (serv?.check === true) {
                                                                setSchedule({ ...schedule, services: [...schedule.services, { id: serv?.id, name: serv?.name, description: serv?.description }] });
                                                            } else {
                                                                var aux = schedule?.services?.filter(x => x.id !== serv.id);
                                                                setSchedule({ ...schedule, services: aux });
                                                            }
                                                        }}
                                                        type="checkbox"
                                                        id={serv?.id}
                                                        className="form-check-input"
                                                    />
                                                    <label className="form-check-label" for={serv?.id}>
                                                        {serv?.name}
                                                    </label>
                                                </div>
                                            </div>
                                        );


                                    } else {

                                        return (<>
                                            <div className="col-md-12 mt-2">
                                                <div className="form-check form-check-inline w-100 d-flex align-items-center">
                                                    <input
                                                        checked={serv?.check}
                                                        onChange={() => {

                                                            serv.check = serv?.check === false ? true : false;

                                                            if (serv?.check === true) {
                                                                setSchedule({ ...schedule, services: [...schedule.services, { id: serv?.id, name: serv?.name, description: serv?.description }] });
                                                            } else {
                                                                var aux = schedule?.services?.filter(x => x.id !== serv.id);
                                                                setSchedule({ ...schedule, services: aux })
                                                            }
                                                        }}
                                                        type="checkbox"
                                                        id="outro"
                                                        className="form-check-input"
                                                    />
                                                    <label className="form-check-label ms-2" for="outro">
                                                        Outro
                                                    </label>
                                                    <input
                                                        value={serv?.check === true ? serv?.description : ""}
                                                        onChange={(e) => {
                                                            if (serv?.check === true) {
                                                                const newState = schedule?.services?.map(obj => {
                                                                    if (obj.id === serv.id) { return { ...obj, description: e.target.value }; }
                                                                    return obj;
                                                                });
                                                                setSchedule({ ...schedule, services: newState })
                                                            }
                                                        }}
                                                        type="text"
                                                        id="outro"
                                                        className="form-control outro ms-2"
                                                    />
                                                </div>
                                            </div>
                                        </>);
                                    }
                                })}


                            </div>
                            <div className="row gy-3 mt-0">
                                <div className="col-md-12 mt-4">
                                    <p className="text-gray">
                                        <span className="fw-bold text-dark font-poppins">NOTA:</span> Os
                                        valores apresentados podem sofrer alterações consoante a
                                        escolha de serviços adicionais ou existindo alguma alteração
                                        devidamente informada.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-2 py-4">
                        <div className="col-lg-3">
                            <div className="text-gray text-uppercase">
                                Observações
                                <span class="d-block small mt-2">(Opcional)</span>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="row g-3">
                                <div className="col-12">
                                    <textarea
                                        value={schedule?.notes}
                                        onChange={handleChangeSchdule}
                                        placeholder="Observações"
                                        id="notes"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-12">
                                    <div className="form-check form-check-inline">
                                        <input
                                            checked={agreement}
                                            onChange={handleAgreementChange}
                                            type="checkbox"
                                            id="agreement"
                                            className="form-check-input"
                                        />
                                        <label className="form-check-label" for="agreement">
                                            Li e aceito os Termos & Condições e a Política de
                                            Privacidade.
                                        </label>
                                    </div>
                                </div>
                                {Loading(load)}
                                {load === false &&
                                    <div className="col-12 mt-5">
                                        <div className="marcacao-buttons text-end">
                                            <Button
                                                onClick={() => {
                                                    sendData();
                                                }}
                                                type="button"
                                                buttonStyle="button-loginscreen-solid"
                                                buttonSize="button-size-medium"
                                            >
                                                Fazer Marcação
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FinalStep;
