import './Block6.css';
import background from '../../../img/main/block6/background.svg';
import concelho1 from '../../../img/main/block6/concelho-1.jpg';
import concelho2 from '../../../img/main/block6/concelho-2.jpg';
import concelho3 from '../../../img/main/block6/concelho-3.jpg';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import toast, { Toaster } from "react-hot-toast";

function Block6(props) {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState([
        //{
        //    title: "Como escolher o pneu adequado",
        //    category: "PNEUS",
        //    id: 1,
        //    resume: "Aqui fica uma pequena introdução sobre este assunto da RedService, aqui fica uma pequena introdução sobre este assunto da RedService",
        //    body: ` <p>
        //  <strong>Lorem Ipsum</strong> is simply dummy text of the
        //  printing and typesetting industry. Lorem Ipsum has been the
        //  industry's standard dummy text ever since the 1500s, when an
        //  unknown printer took a galley of type and scrambled it to make a
        //  type specimen book. It has survived not only five centuries, but
        //  also the leap into electronic typesetting, remaining essentially
        //  unchanged. It was popularised in the 1960s with the release of
        //  Letraset sheets containing Lorem Ipsum passages, and more
        //  recently with desktop publishing software like Aldus PageMaker
        //  including versions of Lorem Ipsum.
        //</p>
        //<p>
        //  <strong>Lorem Ipsum</strong> is simply dummy text of the
        //  printing and typesetting industry. Lorem Ipsum has been the
        //  industry's standard dummy text ever since the 1500s, when an
        //  unknown printer took a galley of type and scrambled it to make a
        //  type specimen book. It has survived not only five centuries, but
        //  also the leap into electronic typesetting, remaining essentially
        //  unchanged. It was popularised in the 1960s with the release of
        //  Letraset sheets containing Lorem Ipsum passages, and more
        //  recently with desktop publishing software like Aldus PageMaker
        //  including versions of Lorem Ipsum.
        //</p>`,
        //},
        //{
        //    title: "Quer ser RedService?",
        //    category: "MECÂNICA",
        //    id: 2,
        //    resume: "Aqui fica uma pequena introdução sobre este assunto da RedService, aqui fica uma pequena introdução sobre este assunto da RedService",
        //    body: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        //},
        //{
        //    title: "Quer ser RedService?",
        //    category: "MANUTENÇÃO",
        //    id: 3,
        //    resume: "Aqui fica uma pequena introdução sobre este assunto da RedService, aqui fica uma pequena introdução sobre este assunto da RedService",
        //    body: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        //},
    ]);

    useEffect(() => {
        GetRandomAdvices("pt");
    }, []);

    const GetRandomAdvices = (lng) => {
        setLoading(true);
        axios.get(global.api_url + "FrontEnd/Advices/" + lng)
            .then((response) => {
                var data = response.data;
                setPosts(data);
                setLoading(false);
            }).catch(error => {
                setPosts([]);
                setLoading(false);
                toast.error(error, { duration: 4000, });
            })
    }



    const handleClickConselhosPneus = () => {
        navigate("/Conselhos");
    };
    const handleClickConselhosMecanica = () => {
        navigate("/Conselhos");
    };
    const handleClickConselhosManutençao = () => {
        navigate("/Conselhos");
    };

    return (
        <div className="section py-5 conselhos">
            <img class="background-conselhos position-absolute top-0" src={background} />
            <div class="container-fluid ">
                <div class="container">
                    <div class="text-center">
                        <h2 class="title-lines">{t("Main.Block6.title")}</h2>
                    </div>
                    <div class="row mt-5">

                        {posts.length === 3 && <>
                            <div class="concelho col-lg-4">
                                <div class="ratio ratio-4x3">
                                    <img class="img-cover" src={concelho1} />
                                </div>
                                <span class="category bg-red text-white">
                                    {posts[0].category}
                                </span>
                                <div class="p-2">
                                    <h6>{posts[0].title}</h6>
                                    <p>{posts[0].resume}</p>
                                    <Button onClick={handleClickConselhosPneus} type="button" buttonStyle="btn-saber-mais">
                                        {t("Main.Block6.button_more")}
                                    </Button>
                                </div>
                            </div>

                            <div class="concelho col-lg-4 mb-4">
                                <div class="ratio ratio-4x3">
                                    <img class="img-cover" src={concelho2} />
                                </div>
                                <span class="category bg-red text-white">
                                    {posts[1].category}
                                </span>
                                <div class="p-2">
                                    <h6>{posts[1].title}</h6>
                                    <p>{posts[1].resume}</p>
                                    <Button onClick={handleClickConselhosMecanica} type="button" buttonStyle="btn-saber-mais">
                                        {t("Main.Block6.button_more")}
                                    </Button>
                                </div>
                            </div>

                            <div class="concelho col-lg-4 mb-4">
                                <div class="ratio ratio-4x3">
                                    <img class="img-cover" src={concelho3} />
                                </div>
                                <span class="category bg-red text-white">
                                    {posts[2].category}
                                </span>
                                <div class="p-2">
                                    <h6>{posts[2].title}</h6>
                                    <p>{posts[2].resume}</p>
                                    <Button onClick={handleClickConselhosManutençao} type="button" buttonStyle="btn-saber-mais">
                                        {t("Main.Block6.button_more")}
                                    </Button>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Block6;