import React from "react";
import "./Pagination.css";
import imgPaginationArrow from "../../img/conselhos/pagination-arrow.svg";

const Pagination = ({ postsPerPage, totalPosts, currentPage, paginate, scrollTop }) => {
    const pageNumbers = [];


    for (let i = 1; i < Math.ceil(totalPosts / postsPerPage) + 1; i++) {
        pageNumbers.push(i);
    }
    return (
        <div className="pt-5">
            <ul className="pagination justify-content-center mt-5">
                <a
                    className="page-link previous border-0"
                    onClick={() => {
                        if (currentPage - 1 > 0) {
                            paginate(currentPage - 1);
                            {
                                scrollTop !== false &&
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: "instant",
                                    });
                            }
                        }
                    }}>
                    {currentPage == 1
                        ? null
                        : <img alt="previous" src={imgPaginationArrow} className="previous" />
                    }
                </a>

                {pageNumbers.map((number) => (
                    <li
                        key={number}
                        className={`page-item ${currentPage === number ? "active" : ""}`}
                    >
                        <a className="page-link border-0" onClick={() => {
                            paginate(number)
                            {
                                scrollTop !== false &&
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: "instant",
                                    });
                            }
                        }}>
                            {number}
                        </a>
                    </li>
                ))}
                <a
                    className="page-link next border-0"
                    onClick={() => {
                        if (pageNumbers.length - (currentPage + 1) >= 0) {
                            paginate(currentPage + 1);
                            {
                                scrollTop !== false &&
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: "instant",
                                    });
                            }
                        }
                    }}
                >
                    {currentPage == pageNumbers.length
                        ? null
                        : <img src={imgPaginationArrow} alt="next" className="next" />
                    }
                </a>
            </ul>
        </div>
    );
};

export default Pagination;
