import React from 'react';
import Moment from 'moment';
import 'moment/locale/pt'
Moment.locale('pt');


const VehiculeDetailRow = ({ vehicule }) => {

    const colorstatus = (status) => {
        switch (status) {
            case 'Cancelado':
                return <div style={{ color: 'red' }}>{vehicule?.statusService}</div>
            case 'Validado':
                return <div style={{ color: 'orange' }}>{vehicule?.statusService}</div>
            case 'Montado':
                return <div style={{ color: 'green' }}>{vehicule?.statusService}</div>
            case 'Sugestao':
                return <div style={{ color: 'orange' }}>{vehicule?.statusService}</div>
            case 'Pendente':
                return <div style={{ color: 'black' }}>{vehicule?.statusService}</div>
            default:
                return status;
        }
    }
    return (
        <tr>
            <th scope="row">
                {vehicule?.name}
            </th>
            <td>
                {vehicule?.description}
            </td>
            <td>
                {vehicule?.kmsService}
            </td>
            <td>
                {colorstatus(vehicule?.statusService)}
            </td>
            <td>
                
                {Moment(vehicule?.serviceDate).format('LL')}
            </td>
           
        </tr>
    );
}

export default VehiculeDetailRow;