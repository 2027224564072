import "./Contacts.css";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import React from "react";
import BannerInterior from "../../components/BannerInterior/BannerInterior";
import banner from "../../img/banners/redes-oficinas.jpg";
//import iconContact from "../../img/contactos/contact.svg";
import iconEmail from "../../img/contactos/email.svg";
import toast from "react-hot-toast";

function Contacts() {
    let navigate = useNavigate();
    const [name, setName] = useState("");
    const [contact, setContact] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [policyAgreement, setPolicyAgreement] = useState(false);

    var emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //Conte @ e .

    const handleChangeName = (e) => setName(e.target.value);
    const handleChangeContact = (e) => setContact(e.target.value);
    const handleChangeEmail = (e) => setEmail(e.target.value);
    const handleChangeSubject = (e) => setSubject(e.target.value);
    const handleChangeMessage = (e) => setMessage(e.target.value);
    const handleChangePolicyAgreement = (e) => {
        if (policyAgreement) {
            setPolicyAgreement(false);
        } else {
            setPolicyAgreement(true);
        }
    };

    const sendData = () => {
        if (validateData()) {
            toast.success("Formulário enviado com sucesso!", { duration: 4000, });
            navigate("/");
        }
    };

    const validateData = () => {
        if (name.trim() === "") {
            toast.error("Tem de preencher o nome");
        } else if (contact.trim() === "") {
            toast.error("Tem de preencher o contacto");
        } else if (email.trim() === "") {
            toast.error("Tem de preencher o email");
        } else if (subject.trim() === "") {
            toast.error("Tem de preencher o assunto");
        } else if (message.trim() === "") {
            toast.error("Tem de preencher a mensagem");
        } else if (!policyAgreement
        ) {
            toast.error("Tem de concordar com os Termos & Condições e a Política de Privacidade ");
        }
        else if (!email.match(emailPattern)) {
            toast.error("Email inválido!");
        } else {
            return true;
        }
    };

    return (
        <div className="App">
            <BannerInterior name="Contactos" imagem={banner} />
            <div className="section contacts py-5">
                <div className="container py-5">
                    <div className="row pb-0">
                        <div className="col-md-12">
                            <div className="section_sub_title text-red text-uppercase mb-4">
                                REDSERVICE
                            </div>
                        </div>
                    </div>
                    <div class="row gx-5 gy-lg-0 gy-5">
                        <div class="col-lg-6">
                            <div class="section_title fw-bold mb-4">Fale Connosco</div>
                            <div class="detail mb-4">
                                <p>
                                    Pode contactar-nos através de contacto telefónico, de e-mail
                                    ou preenchendo o formulário de contacto que lhe
                                    disponibilizamos.
                                </p>
                                <p>
                                    Pode encontrar a oficina RedService mais perto de si,
                                    utilizando o nosso localizador de oficinas.
                                </p>
                            </div>
                            <Button
                                onClick={() => {
                                    sendData();
                                }}
                                type="button"
                                buttonStyle="btn-black"
                                buttonSize="btn-lg"
                            >
                                Localizar Oficina
                            </Button>
                            <div class="row g-4 mt-5">
                                {/*<div class="col-md-6">*/}
                                {/*    <div class="card box-info text-center rounded-0">*/}
                                {/*        <div class="card-body py-md-4">*/}
                                {/*            <img src={iconContact} class="mx-auto mb-4" />*/}
                                {/*            <div class="title text-uppercase mb-2">Contacto</div>*/}
                                {/*            <div class="content fw-bold">+351 900 000 000</div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div class="col-md-12">
                                    <div class="card box-info text-center rounded-0">
                                        <div class="card-body py-md-4">
                                            <img alt="email" src={iconEmail} class="mx-auto mb-4" />
                                            <div class="title text-uppercase mb-2">Email</div>
                                            <div class="content fw-bold">geral@redservice.pt</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-title text-gray text-uppercase mb-3">
                                Formulário de Contacto
                            </div>
                            <div class="form-contactos">
                                <div class="row g-3">
                                    <div class="col-md-12">
                                        <input
                                            onChange={handleChangeName}
                                            type="text"
                                            placeholder="Nome"
                                            className="form-control"
                                        />
                                    </div>
                                    <div class="col-md-6">
                                        <input
                                            onChange={handleChangeContact}
                                            type="text"
                                            placeholder="Contacto"
                                            className="form-control"
                                        />
                                    </div>
                                    <div class="col-md-6">
                                        <input
                                            type="text"
                                            onChange={handleChangeEmail}
                                            placeholder="Email"
                                            className="form-control"
                                        />
                                    </div>
                                    <div class="col-md-12">
                                        <input
                                            type="text"
                                            onChange={handleChangeSubject}
                                            placeholder="Assunto"
                                            className="form-control"
                                        />
                                    </div>
                                    <div class="col-md-12">
                                        <textarea
                                            value={message}
                                            onChange={handleChangeMessage}
                                            placeholder="Mensagem"
                                            className="form-control"
                                        />
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-check form-check-inline mt-4">
                                            <input
                                                onChange={handleChangePolicyAgreement}
                                                type="checkbox"
                                                id="agreement"
                                                className="form-check-input"
                                            />
                                            <label class="form-check-label" for="agreement">
                                                Li e aceito os Termos & Condições e a Política de
                                                Privacidade.
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <Button
                                            onClick={() => {
                                                sendData();
                                            }}
                                            type="button"
                                            buttonSize="btn-lg"
                                        >
                                            Enviar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contacts;
