import "./Step1.css";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import React from "react";
import toast from "react-hot-toast";
import Moment from 'moment';
import 'moment/locale/pt';
import axios from 'axios';
import { CurrentUser } from "../../../utils/Utilities";
Moment.locale('pt');

function Step1(props) {

    const currentUser = CurrentUser();
    const [points, setPoints] = useState([]);
    const [schedule, setSchedule] = useState(null);
    useEffect(() => {
        getAllWorkshops();
        setSchedule(props?.appointmentData?.schedule !== undefined ? props?.appointmentData?.schedule : {
            title: "Marcação Site",
            start: Moment(new Date().toUTCString()).toISOString().replace('Z', ''),
            end: Moment(new Date().toUTCString()).toISOString().replace('Z', ''),
            accountId: currentUser !== null ? currentUser?.id : 0,
            firstName: currentUser !== null ? currentUser?.firstName : "",
            lastName: currentUser !== null ? currentUser?.lastName : "",
            email: currentUser !== null ? currentUser?.email : "",
            phone: "",
            nif: "",
            vehiculeId: 0,
            plate: "",
            brand: "",
            model: "",
            kms: 0,
            workShopId: currentUser !== null ? currentUser?.defaultWorkshop : "",
            notes: "",
            serviceTypeId: 0,
            services: []
        })
    }, [])

    useEffect(() => {
        if (schedule !== null) {
            setSchedule({ ...schedule, accountId: currentUser?.id, firstName: currentUser.firstName, lastName: currentUser?.lastName, email: currentUser?.email, workShopId: currentUser?.defaultWorkshop })
        }
    }, [currentUser !== null && schedule !== null])

    const timesList = [{ value: 9, text: "Manhã", }, { value: 14, text: "Tarde" }];
    let navigate = useNavigate();
    const [date, setDate] = useState("");
    const [time, setTime] = useState([{ value: "", text: "" }]);
    const getAllWorkshops = async () => {
        await axios.get(global.api_url + "Workshops/GetAllFront")
            .then((response) => {
                var data = response.data;
                setPoints(data);
            }).catch(error => {
                setPoints([]);
                toast.error(error, { duration: 4000, });
            })
    }

    const handleSelectChange = (e) => {
        var index = e.nativeEvent.target.selectedIndex;
        var textSelect = e.nativeEvent.target[index].text;
        setTime([{ value: e.target.value, text: textSelect }]);
        setSchedule({
            ...schedule,
            start: Moment(new Date(schedule.start).setHours(e.nativeEvent.target.value, 0, 0)).format(),
            end: index === 1 ? Moment(new Date(schedule?.end).setHours(11, 0, 0)).format() : Moment(new Date(schedule?.end).setHours(16, 0, 0)).format()
        })
    };

    const handleDateChange = (e) => { setSchedule({ ...schedule, start: e.target.value, end: e.target.value }); };

    const checkDateValidity = () => {
        var today = new Date().setHours(0, 0, 0, 0);
        var dateGiven = new Date(schedule?.start);
        if (today > dateGiven) {
            return false;
        } else {
            return true;
        }
    };

    const validate = () => {
        if (date?.trim() === "" && schedule?.start === null) {
            toast.error("Tem de preencher a data.");
        } else if (schedule?.workShopId === "") {
            toast.error("Tem de selecionar uma oficina.");
        } else if (time[0].value === "") {
            toast.error("Tem de preencher o horário.");
        } else if (!checkDateValidity()) {
            toast.error("Introduza uma data futura.");
        } else {
            return true;
        }
    };

    const nextStep = () => {
        if (validate()) {
            props.setAppointmentData({ ...props.appointmentData, schedule: schedule });
            props.setStepActive(2);
        }
    };

    const previousStep = () => { navigate("/Rede-de-Oficinas"); };

    return (
        <div className="Schedule-main">
            <div class="section marcacao py-20">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-md-4 col-lg-3">
                            <div class="d-flex d-md-block">
                                <div class="step active">
                                    <div class="number">1</div>
                                    <div class="name">Oficina e data</div>
                                </div>
                                <div class="step">
                                    <div class="number">2</div>
                                    <div class="name">Dados da viatura</div>
                                </div>
                                <div class="step">
                                    <div class="number">3</div>
                                    <div class="name">Serviços</div>
                                </div>
                                <div class="step">
                                    <div class="number">4</div>
                                    <div class="name">Dados pessoais</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8 col-lg-8 offset-lg-1">
                            <div class="title-form fw-bold my-4">
                                Marcação do Serviço de Oficina
                            </div>
                            <div class="subtitle text-gray text-uppercase mb-4">Passo 1</div>
                            <div class="title text-red  fw-bold lh-1">
                                Oficina
                                <br /> e Data
                            </div>
                            <div class="steps-form mt-5">
                                <div class="row g-3">
                                    <div class="col-12">
                                        <div class="label text-gray text-uppercase">Oficina</div>
                                        <div className="Step1-displayshop">
                                            <select
                                                class="form-select"
                                                value={schedule?.workShopId}
                                                onChange={(e) => {
                                                    setSchedule({ ...schedule, workShopId: e.target.value })
                                                }}
                                            >
                                                <option value="">
                                                    Selecionar uma oficina
                                                </option>
                                                {points?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id} label={item.title}>
                                                            {item.title}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div class="label text-gray text-uppercase">Data</div>
                                        <div className="Step1-inputbox">
                                            <div className="Step1-datecol">
                                                <input
                                                    onChange={handleDateChange}
                                                    type="date"
                                                    value={Moment(schedule?.start).format('yyyy-MM-DD')}
                                                    placeholder="dd/MM/YYYY"
                                                    id="ThirdOption"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div className="label text-gray text-uppercase">Horário</div>
                                        <div className="Step1-datecol">
                                            <select
                                                class="form-select"
                                                value={time.value}
                                                onChange={handleSelectChange}
                                            >
                                                <option value="">
                                                    Selecionar um Período
                                                </option>
                                                {timesList?.map((item) => {
                                                    return (
                                                        <option key={item.value} value={item.value} label={item.text}>
                                                            {item.text}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="marcacao-buttons text-end">
                                        <Button
                                            onClick={() => {
                                                previousStep();
                                            }}
                                            type="button"
                                            buttonStyle="btn-black"
                                            buttonSize="button-size-large" >
                                            Voltar
                                        </Button>
                                        <Button
                                            onClick={() => { nextStep(); }}
                                            type="button"
                                            buttonSize="button-size-large">
                                            Seguinte
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Step1;
