import logo from "../img/apoio/logo.svg";
import login from "../img/main/login.svg";
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
import Button from "./Button.js";
import { useNavigate } from "react-router-dom";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
} from "react-router-dom";
import { CurrentUser } from "../utils/Utilities";

function NavBar() {
    let navigate = useNavigate();

    const currentUser = CurrentUser();

    const [click, setClick] = useState(false);
    const [currentSection, setCurrentSection] = useState("");
    const { t } = useTranslation();
    const handleClick = () => setClick(!click);

    const handleClickRedService = () => setClick(setCurrentSection("RedService"));
    const handleClickServiçosDeOficina = () =>
        setClick(setCurrentSection("Serviços de Oficina"));
    const handleClickSetNull = () => setClick(setCurrentSection(""));

    const CurrentPath = ({ children }) => {
        const location = useLocation();
        useLayoutEffect(() => {
            setCurrentSection(location.pathname);
        }, [location.pathname]);
        return children;
    };

    return (
        <CurrentPath>
            <>
                <nav className="navbar navbar-expand-xxl py-0 navbar-dark">
                    <div className="container">
                        <NavLink exact to="/" className="navbar-brand">
                            <img src={logo} alt="logo" className="position-relative img-fluid" />
                        </NavLink>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navBar"
                            aria-controls="navBar"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navBar">
                            <ul
                                className={
                                    click
                                        ? "navbar-nav ms-auto mb-2 mb-lg-0 active"
                                        : "navbar-nav ms-auto mb-2 mb-lg-0"
                                }
                            >

                                <li className="nav-item">
                                    <NavLink
                                        to="/RedService/Sobre-Nos"
                                        activeClassName="active"
                                        className="nav-link"
                                        onClick={handleClickSetNull}
                                    >
                                        {t("NavBar.nav1")}
                                    </NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink
                                        to="/Rede-de-Oficinas"
                                        activeClassName="active"
                                        className="nav-link"
                                        onClick={handleClickSetNull}
                                    >
                                        {t("NavBar.nav2")}
                                    </NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    {currentSection ===
                                        "/Servicos-de-Oficina/Manutencao-e-Reparacao" ||
                                        currentSection === "/Servicos-de-Oficina/Revisao-Oficial" ||
                                        currentSection === "/Servicos-de-Oficina/Pneus" ||
                                        currentSection ===
                                        "/Servicos-de-Oficina/Pneus/Etiquetas-de-Pneus" ? (
                                        <a
                                                className="nav-link dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            href="#"
                                            role="button"
                                            aria-expanded="false"
                                            style={{ color: "#e2231a" }}
                                        >
                                            {t("NavBar.nav3")}
                                        </a>
                                    ) : (
                                        <a
                                                className="nav-link dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            href="#"
                                            role="button"
                                            aria-expanded="false"
                                        >
                                            {t("NavBar.nav3")}
                                        </a>
                                    )}
                                    <ul className="dropdown-menu">
                                        <li>
                                            <NavLink
                                                to="/RedService/Servicos"
                                                activeClassName="active"
                                                className="dropdown-item"
                                                onClick={handleClickRedService}
                                            >
                                                {t("NavBar.nav3_sub1")}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/Servicos-de-Oficina/Manutencao-e-Reparacao"
                                                activeClassName="active"
                                                className="dropdown-item"
                                                onClick={handleClickServiçosDeOficina}
                                            >
                                                {t("NavBar.nav3_sub2")}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/Servicos-de-Oficina/Revisao-Oficial"
                                                activeClassName="active"
                                                className="dropdown-item"
                                                onClick={handleClickServiçosDeOficina}
                                            >
                                                {t("NavBar.nav3_sub3")}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/Servicos-de-Oficina/Pneus"
                                                activeClassName="active"
                                                className="dropdown-item"
                                                onClick={handleClickServiçosDeOficina}
                                            >
                                                {t("NavBar.nav3_sub4")}
                                            </NavLink>

                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="/Campanhas"
                                        activeClassName="active"
                                        className="nav-link"
                                        onClick={handleClickSetNull}
                                    >
                                        {t("NavBar.nav4")}
                                    </NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink
                                        to="/Acordos"
                                        activeClassName="active"
                                        className="nav-link"
                                        onClick={handleClickSetNull}
                                    >
                                        {t("NavBar.nav5")}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        exa
                                        to="/Conselhos"
                                        activeClassName="active"
                                        className="nav-link"
                                        onClick={handleClickSetNull}
                                    >
                                        {t("NavBar.nav6")}
                                    </NavLink>
                                </li>
                                {currentUser !== null &&
                                    <li className="nav-item">
                                        <NavLink
                                            exa
                                            to="/Area-de-cliente"
                                            activeClassName="active"
                                            className="nav-link"
                                            onClick={handleClickSetNull}
                                        >
                                            {t("NavBar.nav8")}
                                        </NavLink>
                                    </li>
                                }

                                <li className="nav-item-button mx-xxl-2 my-xxl-0 my-2 mx-0">
                                    <Button
                                        onClick={() => {
                                            setCurrentSection("");
                                            navigate("/Junte-se");
                                        }}
                                        type="button"
                                        buttonStyle="button-loginscreen-solid"
                                        buttonSize="button-size-medium"
                                    >
                                        {t("NavBar.nav7")}
                                    </Button>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact to="/Login" className="nav-link login py-xxl-0">
                                        <span className="d-xxl-none d-inline-block me-2">Login</span>
                                        <img src={login} alt="login" className="img-fluid" />
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </>
        </CurrentPath>
    );
}

export default NavBar;
