import "./Campanhas.css";
import React from "react";
import BannerInterior from "../../components/BannerInterior/BannerInterior";
import banner from "../../img/banners/redes-oficinas.jpg";
import { useNavigate } from "react-router-dom";

import Button from "../../components/Button";
import logoBp from "../../img/acordos/bp.jpg";
import logoExpressGlass from "../../img/acordos/express-glass.jpg";
import logoGrasSavoye from "../../img/acordos/express-glass.jpg";
import logoLeaseplan from "../../img/acordos/leaseplan.jpg";
import iconPackCheck from "../../img/servicos-oficina/pack-check.svg";
import { useTranslation, Trans } from 'react-i18next';



function Campanhas() {
    let navigate = useNavigate();
    const handleCardButton = () => { navigate("/RegistoCartao"); };
    const handleAppointmentButton = () => { navigate("/Rede-de-Oficinas"); };
    const { t } = useTranslation();
    const campaigns = t("Campaigns.campaigns", { returnObjects: true });

    return (
        <div className="section">
            <BannerInterior name={t("Campaigns.banner_name")} imagem={banner} />
            <div class="section campanhas py-20">
                <div class="container">
                    <div class="row g-5">

                        {Array.isArray(campaigns) && campaigns.map(detail => {
                            return (
                                <div class="col-lg-6">
                                    <div class="card acordo text-center border-2 border-dark rounded-0 text-center h-100 position-relative">
                                        <img src={detail.image} style={{ width: 'auto', height: 150 }} alt={detail?.title} class="logo position-absolute" />
                                        <div class="card-body d-flex flex-column">
                                            <div class="title fw-bold mb-4"><Trans i18nKey={detail.title} /></div>
                                            <div class="title fw-bold mb-4"><Trans i18nKey={detail.subtitle} /></div>
                                            <div class="acordo-content font-roboto mb-5">
                                                <p class="mb-2"><Trans i18nKey={detail.description} /></p>

                                                {Array.isArray(detail.points) && detail.points.map(point => {
                                                    return (
                                                        <div class="d-flex align-items-baseline justify-content-center mb-2">
                                                            <img alt={point?.text} class="me-2" src={iconPackCheck} />
                                                            <div><Trans i18nKey={point.text} /></div>
                                                        </div>
                                                    );
                                                })}

                                                <p class="mt-3 mb-0">
                                                    <Trans i18nKey={detail.footer} />
                                                </p>

                                            </div>
                                            <div class="mt-auto">
                                                <Button
                                                    onClick={handleAppointmentButton}
                                                    type="button"
                                                >
                                                    <Trans i18nKey={detail.button} />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {Array.isArray(campaigns) && campaigns.length === 0 && <>
                            {t("Campaigns.no_campaigns")}
                        </>}


                    </div>
                </div>
            </div>

        </div>
    );
}

export default Campanhas;
