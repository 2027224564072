import React from "react";
import "./SimpleMap.css";
import { useState, useEffect } from 'react';
import MyMarker from "./MyMarker";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'


function SimpleMap(props) {
    const [center, setCenter] = useState([39.8695841, -8.9338519])
    const [myLocation, setMyLocation] = useState([{ latLocation: 0, lngLocation: 0, }])

    const onChange = ({ center, zoom }) => {
        this.setState({ center: center, zoom: zoom });
    }

    useEffect(() => {
        if (props.pointClicked !== undefined) {
            setCenter({ lat: props?.pointClicked?.lat, lng: props.pointClicked?.lng });
        }
    }, [props.pointClicked])

    const ChangeMapView = ({ coords }) => {
        const map = useMap();
        if (coords.lat !== undefined && coords.lng !== undefined) {
            map?.setView(coords);
        }
    }


    //Function called when loading to get the location of the user
    useEffect(() => {
        navigator?.geolocation.getCurrentPosition(
            ({ coords: { latitude: lat, longitude: lng } }) => {
                const pos = { lat, lng };
                setMyLocation([{ latLocation: lat, lngLocation: lng, }]);
                setCenter(pos);
            }
        );
    }, []);

    return (
        <div className="SimpleMap" id="map">

            <MapContainer
                center={center}
                zoom={10}
                scrollWheelZoom={true}
            >
                <ChangeMapView coords={center} />
                <TileLayer attribution='RedService' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {props.points.map((point) => {
                        return (
                            <MyMarker key={point.id}
                                point={point}
                                center={center}
                                setCenter={setCenter}
                                setPointClicked={props.setPointClicked} />
                        );
                    
                })}

                {myLocation !== null &&
                    <Marker position={[myLocation[0].latLocation, myLocation[0].lngLocation]}>
                        <Popup>
                            A minha localização
                        </Popup>
                    </Marker>
                }
            </MapContainer>
        </div>
    );
}

export default SimpleMap;

