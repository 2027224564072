import "./Acordos.css";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import React from "react";
import NavBar from "../../components/NavBar";
import BannerInterior from "../../components/BannerInterior/BannerInterior";
import Footer from "../../components/Footer";
import banner from "../../img/banners/redes-oficinas.jpg";
import logoBp from "../../img/acordos/bp.jpg";
import logoExpressGlass from "../../img/acordos/express-glass.jpg";
import logoGrasSavoye from "../../img/acordos/express-glass.jpg";
import logoLeaseplan from "../../img/acordos/leaseplan.jpg";
import iconPackCheck from "../../img/servicos-oficina/pack-check.svg";
import imgSeparatorLeft from '../../img/main/block3/img-1.jpg';
import imgSeparatorRight from '../../img/main/block3/img-2.jpg';
import { useTranslation, Trans } from 'react-i18next';


function Acordos() {
    let navigate = useNavigate();
    const handleCardButton = () => { navigate("/RegistoCartao"); };
    const handleAppointmentButton = () => { navigate("/Rede-de-Oficinas"); };
    const { t } = useTranslation();

    const details = t("Agreements.Section1.details", { returnObjects: true });

    return (
        <div className="App">
            <NavBar />
            <BannerInterior name={t("Agreements.banner_name")} imagem={banner} />
            <div className="section acordos py-20">
                <div className="container">
                    <div className="row g-5">

                        {Array.isArray(details) && details.map(detail => {
                            return (
                                <div className="col-lg-6">
                                    <div className="card acordo text-center border-2 border-dark rounded-0 text-center h-100 position-relative">
                                        <img src={detail.image} alt={"detalhes"} className="logo position-absolute" />
                                        <div className="card-body d-flex flex-column">
                                            <div className="title fw-bold mb-4"><Trans i18nKey={detail.title} /></div>
                                            <div className="acordo-content font-roboto mb-5">
                                                <p className="mb-2"><Trans i18nKey={detail.description} /></p>

                                                {Array.isArray(detail.points) && detail.points.map(point => {
                                                    return (
                                                        <div className="d-flex align-items-baseline justify-content-center mb-2">
                                                            <img className="me-2" src={iconPackCheck} alt={point.text} />
                                                            <div>
                                                                <Trans i18nKey={point.text} />
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                <p className="mt-3 mb-0">
                                                    <Trans i18nKey={detail.footer} />
                                                </p>
                                            </div>
                                            <div className="mt-auto">
                                                <Button
                                                    onClick={detail.button.startsWith("Registar") ? handleCardButton : handleAppointmentButton}
                                                    type="button"
                                                >
                                                    <Trans i18nKey={detail.button} />
                                                </Button>
                                            </div>
                                        </div>
                                        <span className="acordos-overlay-text fw-bold position-absolute start-0 bottom-0 overlay-5">
                                            {t("Agreements.banner_name")}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>


            {/*<div class="container-fluid content-subfooter overflow-hidden p-0">*/}
            {/*    <div class="row g-0 justify-content-center align-self-center">*/}
            {/*        <div class="col-md-6 position-relative">*/}
            {/*            <div class="position-relative">*/}
            {/*                <div class="h-105 w-105 position-absolute top-0 background-cover">*/}
            {/*                    <div class="h-100 w-100 position-absolute top-0 overlay-90 bg-red"></div>*/}
            {/*                    <img src={imgSeparatorLeft} />*/}
            {/*                </div>*/}
            {/*                <div class="content-modulo">*/}
            {/*                    <h5 class="section_sub_title text-white"><Trans i18nKey="Agreements.Section2.left.sub_title" /></h5>*/}
            {/*                    <h2 class="section_title text-white mb-4">*/}
            {/*                        <Trans i18nKey="Agreements.Section2.left.title" />*/}
            {/*                    </h2>*/}
            {/*                    <button class="btn btn-white btn-lg" type="button">*/}
            {/*                        <Trans i18nKey="Agreements.Section2.left.button" />*/}
            {/*                    </button>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div class="col-md-6 position-relative">*/}
            {/*            <div class="h-100 w-100 position-absolute top-0 background-cover">*/}
            {/*                <div class="div-clip-path">*/}
            {/*                    <div class="h-100 w-100 position-absolute top-0 overlay-60 bg-darker"></div>*/}
            {/*                    <img src={imgSeparatorRight} />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div class="content-modulo d-flex h-100">*/}
            {/*                <div class="row justify-content-center align-self-center">*/}
            {/*                    <h5 class="section_sub_title text-white"><Trans i18nKey="Agreements.Section2.right.sub_title" /></h5>*/}
            {/*                    <h2 class="section_title text-white"><Trans i18nKey="Agreements.Section2.right.title" /></h2>*/}
            {/*                    <p class="text-white mb-4">*/}
            {/*                        <Trans i18nKey="Agreements.Section2.right.description" />*/}
            {/*                    </p>*/}
            {/*                    <div class="mt-auto">*/}
            {/*                        <Button*/}
            {/*                            onClick={() => {*/}
            {/*                                // sendData();*/}
            {/*                            }}*/}
            {/*                            type="button"*/}
            {/*                        >*/}
            {/*                            <Trans i18nKey="Agreements.Section2.right.button" />*/}
            {/*                        </Button>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <span class="acordos-overlay-text fw-bold position-absolute start-0 bottom-0 overlay-5">*/}
            {/*                    Acordos*/}
            {/*                </span>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <Footer />
        </div>
    );
}

export default Acordos;
