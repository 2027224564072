import React from 'react';
//import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import './i18n';
import { Provider } from 'react-redux'
import { createStore } from 'redux';
import accountApp from './store/accounts/accounts';

global.api_url = window.location.href.startsWith("http://localhost") ? "http://api.redservice.pt/" : "https://api.redservice.pt/";
global.geo_locat = "https://geoapi.pt/";

const store = createStore(accountApp);

//const rootElement = document.getElementById("root");
//ReactDOM.render(
//    <React.Fragment>
//        <Provider store={store}>
//            <App />
//        </Provider>
//    </React.Fragment>,
//    rootElement
//);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();



