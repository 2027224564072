import './Block4.css';
import iconPneus from '../../../img/main/block4/icon-pneus.svg';
import iconManutencao from '../../../img/main/block4/icon-manutencao-reparacao.svg';
import iconRevisao from '../../../img/main/block4/icon-revisao-oficial.svg';
import imagem1 from '../../../img/main/block4/img-1.jpeg';
import imagem2 from '../../../img/main/block4/img-2.jpeg';
import imagem3 from '../../../img/main/block4/img-3.jpeg';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Block4() {
    let navigate = useNavigate();
    const handleClickManutencao = () => { navigate("/Servicos-de-Oficina/Manutencao-e-Reparacao"); };
    const handleClickPneus = () => { navigate("/Servicos-de-Oficina/Pneus"); };
    const handleClickRevisaoOficial = () => { navigate("/Servicos-de-Oficina/Revisao-Oficial"); };
    const { t } = useTranslation();
    return (
        <div className="section my-5 list-square">
            <div class="container">
                <div class="row g-0">
                    <div class="col-md-6 col-lg-4">
                        <div class="d-flex h-100 position-relative overflow-hidden">
                            <Button onClick={handleClickPneus} type="button" buttonStyle="btn-grey">
                                <div class="row justify-content-center align-self-center p-5">
                                    <img alt="" class="icon-btn mb-4" src={iconPneus} />
                                    <h2 class="mb-4">{t("Main.Block4.title1")}</h2>
                                    <p>{t("Main.Block4.description1")}</p>
                                    <span class="position-absolute bottom-0 end-0 overlay-5 font-90">{t("Main.Block4.span1")}</span>
                                </div>
                            </Button>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="ratio ratio-1x1 h-100">
                            <img alt="" class="img-cover h-100 w-100" src={imagem1} />
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="d-flex h-100 position-relative overflow-hidden">
                            <Button onClick={handleClickManutencao} type="button" buttonStyle="btn-red">
                                <div class="row justify-content-center align-self-center p-5">
                                    <img alt="" class="icon-btn mb-4" src={iconManutencao} />
                                    <h2 class="mb-4">{t("Main.Block4.title2")}</h2>
                                    <p>{t("Main.Block4.description2")}</p>
                                    <span class="position-absolute bottom-0 end-0 overlay-15 font-90">{t("Main.Block4.span2")}</span>
                                </div>
                            </Button>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="ratio ratio-1x1 h-100">
                            <img alt="" class="img-cover h-100 w-100" src={imagem2} />
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="d-flex h-100 position-relative overflow-hidden">
                            <Button onClick={handleClickRevisaoOficial} type="button" buttonStyle="btn-black">
                                <div class="row justify-content-center align-self-center p-5">
                                    <img alt="" class="icon-btn mb-4" src={iconRevisao} />
                                    <h2 class="mb-4">{t("Main.Block4.title3")}</h2>
                                    <p>{t("Main.Block4.description3")}.</p>
                                    <span class="position-absolute bottom-0 end-0 overlay-10 font-90">{t("Main.Block4.span3")}</span>
                                </div>
                            </Button>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="ratio ratio-1x1">
                            <img alt="" class="img-cover" src={imagem3} />
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default Block4;