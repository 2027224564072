import "./Policy.css";
import React from "react";
import BannerInterior from "../../components/BannerInterior/BannerInterior";
import banner from "../../img/banners/redes-oficinas.jpg";

function Policy() {

    return (
        <div className="section">
            <BannerInterior name="Política de Privacidade" imagem={banner} />
            <div class="container my-5">
                <p><strong>TITULAR DA PÁGINA WEB E FUNCIONAMENTO</strong></p>
                <p>A página web <strong>www.redservice.pt</strong> e aplicação móvel <strong>RedService</strong> é propriedade da Leirilis, Acessórios e Peças para Automóveis, S.A., com sede na Rua Paulo VI, 2400 - 147, Leiria, Portugal, com o NIF: 501643559.</p>
                <p><strong>1. RESPONSÁVEIS PELO TRATAMENTO</strong></p>
                <p><strong>1.1. Quem são os responsáveis pelo tratamento?</strong> <br />Leirilis, Acessórios e Peças para Automóveis, S.A., com sede na Rua dos Camponeses, 159 – Marinheiros - Leiria – Portugal, com o NIF: 501643559, e direção de correio protecaodecados@redservice.pt.</p>
                <p><strong>1.2. Existe um Delegado de Proteção de Dados que possa contactar?</strong> <br />Sim; os interessados poderão contactar o Delegado de Proteção de Dados (DPO) através da seguinte direção de correio eletrónico protecaodecados@redservice.pt.</p>
                <p><strong>2. FINALIDAE DO TRATAMENTO E LEGITIMAÇÃO</strong></p>
                <p class="text-gray"><strong>2.1. CLIENTES</strong></p>
                <p><strong>2.1.1. Para que finalidade iremos tratar os seus dados e qual é a base que nos legitima?</strong> <br />A Leirilis trata os dados que nos proporciona através do formulário de compra com a finalidade de gerir a compra realizada bem como qualquer outra atividade de tratamento que deva ser realizada legitimada na relação entre o cliente e a Oficina RedService.</p>
                <p>Informamos que este tratamento de dados deve ser realizado para o correto desenvolvimento da relação contratual entre as partes.</p>
                <p><strong>2.1.2. Será necessário que nos faculte todos os seus dados? O que acontece se os não facultar?</strong> <br />Será necessário que nos proporcione aqueles dados assinalados com um asterisco (*) como obrigatórios para poder proceder à tramitação da compra.</p>
                <p>No caso de não nos facultar os dados assinalados como obrigatórios, não poderá tramitar a sua compra.</p>
                <p><strong>2.1.3. Iremos realizar decisões individualizadas automatizadas e/ou elaboração de perfis, que produzam efeitos jurídicos ou que o afetem significativamente de modo semelhante? </strong> <br />Não.</p>
                <p><strong>2.1.4. Durante quanto tempo iremos tratar os seus dados?</strong> <br />Os dados pessoais proporcionados serão conservados durante o prazo de 6 anos após a finalização da sua relação com a Oficina RedService.</p>
                <p><strong>2.1.5. DESTINATÁRIOS DE COMUNICAÇÕES DE DADOS: Iremos comunicar os seus dados a outras empresas ou organizações?</strong> <br />Os dados serão comunicados às Finanças naqueles casos em que na Oficina RedService esteja obrigada legalmente e às entidades bancárias quando seja necessário para pagamento do produto e/ou serviço prestado.</p>
                <p>Adicionalmente ao anterior, a Oficina RedService também irá comunicar os dados a terceiros quando tal for obrigatoriamente legal.</p>
                <p><strong>2.1.6. DESTINATÁRIOS DE TRANSFERÊNCIAS INTERNACIONAIS:_Os seus dados de caráter pessoal serão transferidos para países terceiros ou organizações internacionais?</strong><br />Não.</p>
                <p class="text-gray"><strong>2.2. COMUNICAÇÃO E MARKETING</strong></p>
                <p><strong>2.2.1. Para que finalidade iremos tratar os seus dados e qual é a base que nos legitima?<br /></strong>A Leirilis trata os dados que nos proporcionou através do formulário de subscrição da newsletter e/ou através da aceitação de receção de tal informação dentro do formulário de compra, com a finalidade de lhe enviar informação sobre atividades, produtos, serviços e ofertas da Oficina RedService.</p>
                <p>O tratamento está legitimado pelo seu consentimento expresso, manifestado através do preenchimento do formulário disposto para tal e posterior subscrição.</p>
                <p><strong>2.2.2. Será necessário que nos faculte todos os seus dados? O que acontece se os não facultar?<br /></strong>Será necessário que nos faculte a sua direção de correio eletrónico (único campo obrigatório).</p>
                <p>Se não nos facultar o seu correio eletrónico, não poderá subscrever a newsletter.</p>
                <p><strong>2.2.3. Iremos realizar decisões individualizadas automatizadas e/ou elaboração de perfis, que produzam efeitos jurídicos ou que o afetem significativamente de modo semelhante?<br /></strong>Não.</p>
                <p><strong>2.2.4. Durante quanto tempo iremos tratar os seus dados?<br /></strong>Os seus dados serão conservados durante um prazo de 3 anos após a remoção do seu consentimento.</p>
                <p><strong>2.2.5. DESTINATÁRIOS DE COMUNICAÇÕES DE DADOS: Iremos comunicar os seus dados a outras empresas ou organizações?<br /></strong>Não.</p>
                <p><strong>2.2.6. DESTINATÁRIOS DE TRANSFERÊNCIAS INTERNACIONAIS: Os seus dados de caráter pessoal serão transferidos para países terceiros ou organizações internacionais?</strong><br />Não</p>
                <p> </p>
                <p><strong>3. DIREITOS DO INTERESSADO</strong></p>
                <p class="text-gray"><strong>3.1. Que direitos o assistem relativamente ao tratamento dos seus dados</strong></p>
                <p >Qualquer interessado tem direito a obter confirmação sobre a existência, ou não, de um tratamento dos seus dados pessoais.</p>
                <p >Além disso, o Regulamento Geral de Proteção de Dados, estipula como direitos do interessado:</p >
                <ul>
                    <li aria-setsize="-1" data-aria-level="1" data-aria-posinset="1" data-font="Courier New">
                        <p >Acesso aos seus dados</p>
                    </li >
                    <li aria-setsize="-1" data-aria-level="1" data-aria-posinset="2" data-font="Courier New" >
                        <p >Solicitar a retificação ou supressão dos seus dados</p >
                    </li >
                    <li aria-setsize="-1" data-aria-level="1" data-aria-posinset="1" data-font="Courier New" >
                        <p >Solicitar a limitação do tratamento dos seus dados</p>
                    </li >
                    <li aria-setsize="-1" data-aria-level="1" data-aria-posinset="2" data-font="Courier New" >
                        <p >A portabilidade dos seus dados</p >
                    </li >
                    <li aria-setsize="-1" data-aria-level="1" data-aria-posinset="3" data-font="Courier New" >
                        <p >Oposição ao tratamento dos seus dados</p >
                    </li >
                </ul >
                <p class="text-gray"><strong>3.2. Quando iremos responder ao seu pedido?</strong></p >
                <p>Iremos responder aos seus pedidos com a maior brevidade possível e, em todo o caso, no prazo de um mês após a receção do seu pedido. Tal prazo poderá ser prolongado por dois meses adicionais caso necessário, tomando em consideração a complexidade e o número de pedidos. O responsável irá informar o interessado do adiamento durante o primeiro mês após o pedido.</p>
                <p class="text-gray"><strong>3.3. Tem direito a retirar o seu consentimento?</strong></p >
                <p >No caso do tratamento &ldquo;Comunicação e Marketing&rdquo; (comunicações comerciais), o interessado tem direito a retirar o consentimento em qualquer momento, sem que tal afete a legalidade do tratamento baseado no consentimento prévio à sua remoção.</p >
                <p class="text-gray"><strong>3.4. Onde deve dirigir-se para exercer os seus direitos?</strong></p>
                <p >Para exercer os direitos reconhecidos, o interessado poderá contactar através da seguinte direção de correio eletrónico protecaodecados@redservice.pt, anexando uma fotocópia do seu CC ou documento semelhante (carta de condução ou passaporte) e indicando que direito deseja exercer.</p >
                <p class="text-gray"><strong>3.5. Tem direito a reclamar?</strong></p>
                <p>Sim. O interessado tem direito a apresentar reclamação perante a&nbsp;Comissão Nacional de Proteção de Dados&nbsp;(CNPD) como autoridade de supervisão e controlo em matéria de proteção de dados no território&nbsp;português.</p>
                <p>&nbsp;</p>
                <p >Com caráter prévio à apresentação de tal reclamação na&nbsp;Comissão Nacional de Proteção de Dados, pode contactar o Delegado de Proteção de Dados (DPO) através da seguinte direção de correio eletrónico protecaodecados@redservice.pt. </p >
                <p></p>
                <p ><strong>Política de cookies</strong></p >
                <p >Este website utiliza cookies próprias e de terceiros por forma a melhorar a experiência dos seus utilizadores, conservando as suas preferências, oferecendo serviços relacionados com as mesmas, e obtendo dados anónimos agregados que permitem serem usados em estatísticas de utilização.</p >
                <p><strong>O que são cookies?</strong></p>
                <p >Cookies são arquivos de texto que contêm pequenas quantidades de informação e que se descarregam no dispositivo do utilizador quando visita uma página web. Depois, as cookies enviam de volta para a página web de origem (cookies de origem) ou para outras páginas web (cookies de terceiros). As cookies permitem que uma página web reconheça o dispositivo de um utilizador, facilitando a navegação, relembrando preferências e, em geral, melhorando a&nbsp;experiência&nbsp;do utilizador, ajudando-o de modo a oferecer-lhe informação e serviços relacionados com os seus interesses.</p >
                <p >Utilização de cookies no website www.redservice.pt</p>
                <p >Como na maioria das páginas web, este site utiliza cookies para melhorar a&nbsp;experiência&nbsp;do utilizador e permite-lhe interagir com redes sociais como o&nbsp;Facebook&nbsp;ou&nbsp;Instagram.</p >
                <p ><strong>A utilização da presente página web supõe a utilização das seguintes cookies:</strong></p >
                <ul>
                    <li aria-setsize="-1" data-aria-level="1" data-aria-posinset="1" data-font="Courier New" >
                        <p >Cookies de Sessão.&nbsp;Este tipo de cookies estão&nbsp;desenhadas para pedir e armazenar dados enquanto o utilizador acede a uma página web.</p>
                    </li >
                    <li aria-setsize="-1" data-aria-level="1" data-aria-posinset="2" data-font="Courier New" >
                        <p >Cookies Técnicas. São aquelas que permitem ao utilizador a navegação através de uma página web, plataforma ou aplicação a utilização das diferentes opções ou serviços que nela existem.</p >
                    </li >
                    <li aria-setsize="-1" data-aria-level="1" data-aria-posinset="3" data-font="Courier New" >
                        <p >Cookies de Análise. São aquelas que permitem ao responsável o seguimento e análise do comportamento dos utilizadores na página web. A informação recolhida é utilizada na medição da&nbsp;atividade&nbsp;das páginas web, aplicações ou plataformas e para a elaboração de perfis de negociação.</p >
                    </li >
                </ul >
                <p> </p>
                <p>As cookies indicadas não reúnem informação pessoal sobre os utilizadores das&nbsp;páginas web. Para conhecer mais detalhes a respeito da utilização de estas cookies de terceiros, pode dirigir-se aos titulares de avaliação. No que diz respeito às cookies do Google, pode consultar o documento <a href="https://www.google.com/analytics/learn/privacy.html?hl=pt" rel="noreferrer" target="_blank"></a>para obter mais informação.</p>
                <p>Recordamos que pode eliminar as cookies em qualquer momento, ou rejeitá-las antes da sua instalação configurando o programa de navegação que utiliza. Por favor, visite a página de ajuda do seu navegador para saber como gerir as cookies no seu dispositivo.</p>
                <p > Deixamos-lhe os links informativos das páginas web mais comuns:</p >
                <ul>
                    <li aria-setsize="-1" data-aria-level="1" data-aria-posinset="1" data-font="Courier New">
                        <p><a href="https://support.google.com/chrome/answer/95647?hl=pt" rel="noreferrer" target="_blank">Google Chrome</a>&nbsp;</p>
                    </li>
                    <li aria-setsize="-1" data-aria-level="1" data-aria-posinset="1" data-font="Courier New" >
                        <p ><a href="https://support.mozilla.org/pt-PT/" rel="noreferrer" target="_blank">Firefox</a>&nbsp;</p>
                    </li>
                    <li aria-setsize="-1" data-aria-level="1" data-aria-posinset="3" data-font="Courier New" >
                        <p ><a href="https://support.apple.com/pt-pt/HT201265" rel="noreferrer" target="_blank">Safari</a>&nbsp;</p>
                    </li>
                </ul>
                <p >A&nbsp;Leirilis, nos termos e para os efeitos do disposto no artigo 18.&ordm; da Lei n.&ordm; 144/2015 de 8 de Setembro, declara que se encontra vinculada à resolução de conflitos com os consumidores perante a seguinte entidade de Resolução Alternativa de Litígios: Centro de Arbitragem do Setor Automóvel (CASA)</p>
            </div >
        </div >
    );
}

export default Policy;
