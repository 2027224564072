import { combineReducers } from 'redux';

const ADD_ACCOUNT = 'ADD_ACCOUNT';
const CHANGE_ACCOUNT = 'CHANGE_ACCOUNT';
const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';
const REMOVE_All_ACCOUNT = 'REMOVE_All_ACCOUNT';

export function addAccount(account) {
    return { type: ADD_ACCOUNT, account, }
}

export function changeAccount(account) {
    return { type: CHANGE_ACCOUNT, account }
}

export function removeAllAccounts() {
    return { type: REMOVE_All_ACCOUNT }
}

const defaultAccount = [];

function accounts(state = defaultAccount, action) {
    switch (action.type) {
        case ADD_ACCOUNT:
            return [
                ...state,
                {
                    created: action.account.created,
                    email: action.account.email,
                    firstName: action.account.firstName,
                    id: action.account.id,
                    isVerified: action.account.isVerified,
                    jwtToken: action.account.jwtToken,
                    lastName: action.account.lastName,
                    role: action.account.role,
                    refreshToken: action.account.refreshToken,
                    defaultWorkshop: action.account.defaultWorkshop
                }
            ];

        case CHANGE_ACCOUNT:
            const account = state.find(b => b?.id === action.account?.id);
            if (account) {
                return [
                    ...state,
                    {
                        created: action.account.created,
                        email: action.account.email,
                        firstName: action.account.firstName,
                        id: action.account.id,
                        isVerified: action.account.isVerified,
                        jwtToken: action.account.jwtToken,
                        lastName: action.account.lastName,
                        role: action.account.role,
                        refreshToken: action.account.refreshToken,
                        defaultWorkshop: action.account.defaultWorkshop
                    }

                ];
            }
            break;
        case REMOVE_All_ACCOUNT:
            return [...defaultAccount]
        case REMOVE_ACCOUNT:
            const index = action.account.id
            return { ...state, card: [...state.card.slice(0, index), ...state.card.slice(index + 1)] }
        default:
            return state;
    }
}

const accountApp = combineReducers({
    accounts
});

export default accountApp;