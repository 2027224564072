import './BannerInterior.css';


function BannerInterior(props) {
    return (
        <div className="banner-int w-100 d-block p-0 m-0 position-relative">
            <img className="" alt={props?.name} src={props.imagem} />
            <div className="h-100 w-100 position-absolute top-0">
                <div className="container d-flex h-100">
                    <div className="row justify-content-center align-self-center">
                        <div className="col-12">
                            <h2 className="title-banner">{props.name}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BannerInterior;