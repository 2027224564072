import "./Page404.css";
import React from "react";
import BannerInterior from "../../components/BannerInterior/BannerInterior";
import banner from "../../img/banners/redes-oficinas.jpg";
;


function Page404() {
    return (
        <div className="App">
            <BannerInterior name={"Não foi possível encontrar a página."} imagem={banner} />
        </div>
    );
}

export default Page404;
