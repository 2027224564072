import "./MaintenencePackPrices.css";
import iconOilType from "../img/servicos-oficina/oil-type.svg";
import iconAditionalServices from "../img/servicos-oficina/aditional-services.svg";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

function MaintenencePackPrices(props) {
    let navigate = useNavigate();
    const [oilOption, setOilOption] = useState(null);
    const [extraOption, setExtraOption] = useState(null);
    const [priceOil, setPriceOil] = useState(0);
    const [priceExtra, setPriceExtra] = useState(0);

    const handleOilChangeOption = (e) => {
        setOilOption(e.target.value);
        if (e.target.value === oilOption) {
            setOilOption(null);
            setPriceOil(0);
        } else if (e.target.value === "Óleo 10W40 Sintético") {
            setOilOption("Óleo 10W40 Sintético");
            setPriceOil(props.oilPriceOption1);
        } else if (e.target.value === "Óleo 5W40 / 5W30 / C2 / C3") {
            setOilOption("Óleo 5W40 / 5W30 / C2 / C3");
            setPriceOil(props.oilPriceOption2);
        } else if (e.target.value === "Óleo Long Life / C4 / Específico") {
            setOilOption("Óleo Long Life / C4 / Específico");
            setPriceOil(props.oilPriceOption3);
        }
    };

    const handleAditionalServicesChange = (e) => {
        if (extraOption == "Substituição do Filtro de Habitáculo") {
            setExtraOption(null);
            setPriceExtra(0);
        } else {
            setExtraOption("Substituição do Filtro de Habitáculo");
            setPriceExtra(props.extraPriceOption1);
        }
    };

    const validate = () => {
        if (oilOption == null) {
            toast.error("Escolha um tipo de óleo.");
        } else {
            return true;
        }
    };

    const sendData = () => {
        if (validate()) {
            props.setAppointmentData({
                ...props.appointmentData,
                pack: props.pack,
                finalPrice: priceOil + priceExtra,
                oilType: oilOption,
                extraService: extraOption,
            });
            navigate("/Rede-de-Oficinas");
        }
    };

    return (
        <div className="DetailsContainer">
            <div className="MainBox">
                <div className="card revision h-100 border-dark border-2 rounded-0 p-sm-4 p-3 mb-3">
                    <div className="d-flex align-items-end mb-3">
                        <img className="icon me-3" alt={"Tipo de Óle"} src={iconOilType} />
                        <div className="title fw-bold">Tipo de Óleo</div>
                    </div>
                    <div className="revision-body ps-md-5 pe-md-5">
                        <div className="CheckBoxes">
                            <div className="form-check price mb-2">
                                <div className="line d-flex align-items-center position-relative">
                                    <input
                                        checked={oilOption === "Óleo 10W40 Sintético"}
                                        onChange={handleOilChangeOption}
                                        type="checkbox"
                                        id="oleo10W40sintetico"
                                        value="Óleo 10W40 Sintético"
                                        className="form-check-input"
                                    />
                                    <label className="form-check-label px-3" for="oleo10W40sintetico">
                                        Óleo 10W40 Sintético
                                    </label>
                                    <div className="price text-red fw-bold font-poppins ms-auto ps-3">
                                        {props.oilPriceOption1 + "€"}
                                    </div>
                                </div>
                            </div>

                            <div className="form-check price mb-2">
                                <div className="line d-flex align-items-center position-relative">
                                    <input
                                        checked={oilOption === "Óleo 5W40 / 5W30 / C2 / C3"}
                                        value="Óleo 5W40 / 5W30 / C2 / C3"
                                        onChange={handleOilChangeOption}
                                        type="checkbox"
                                        id="oleo5W405W30C2C3"
                                        className="form-check-input"
                                    />
                                    <label className="form-check-label px-3" for="oleo5W405W30C2C3">
                                        Óleo 5W40 / 5W30 / C2 / C3
                                    </label>
                                    <div className="price text-red fw-bold font-poppins ms-auto ps-3">
                                        {props.oilPriceOption2 + "€"}
                                    </div>
                                </div>
                            </div>

                            <div className="form-check price mb-2">
                                <div className="line d-flex align-items-center position-relative">
                                    <input
                                        checked={oilOption === "Óleo Long Life / C4 / Específico"}
                                        value="Óleo Long Life / C4 / Específico"
                                        onChange={handleOilChangeOption}
                                        type="checkbox"
                                        id="oleoLongLifeC4Especifico"
                                        className="form-check-input"
                                    />
                                    <label
                                        className="form-check-label px-3"
                                        for="oleoLongLifeC4Especifico"
                                    >
                                        Óleo Long Life / C4 / Específico
                                    </label>
                                    <div className="price text-red fw-bold font-poppins ms-auto ps-3">
                                        {props.oilPriceOption3 + "€"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card revision h-100 border-dark border-2 rounded-0 p-sm-4 p-3 mb-3">
                <div className="d-flex align-items-end mb-3">
                    <img className="icon me-3" alt={"Serviços Adicionais"} src={iconAditionalServices} />
                    <div className="title fw-bold">
                        Serviços Adicionais
                        <span className="text-gray small fw-normal">(Opcional)</span>
                    </div>
                </div>
                <div className="revision-body ps-md-5 pe-md-5">
                    <div className="form-check price mb-2">
                        <div className="line d-flex align-items-center position-relative">
                            <input
                                checked={extraOption === "Substituição do Filtro de Habitáculo"}
                                onChange={handleAditionalServicesChange}
                                type="checkbox"
                                id="SubstituicaoDoFiltroDeHabitaculo"
                                className="form-check-input mb-auto"
                            />
                            <label
                                className="form-check-label px-3"
                                for="SubstituicaoDoFiltroDeHabitaculo"
                            >
                                Substituição do Filtro de Habitáculo
                                <br /> <span className="small-label">(Estão excluídos os filtros de carvão ativo)</span>
                            </label>
                            <div className="price text-red fw-bold font-poppins ms-auto ps-3">
                                {props.extraPriceOption1 + "€"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card revision h-100 border-dark border-2 rounded-0 p-sm-4 p-3 mb-3">
                <div className="revision-body pe-md-5">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="title fw-bold pe-sm-3">Total</div>
                        <div className="line-total w-100"></div>
                        <div className="final-price text-red fw-bold font-poppins ps-sm-3">
                            {priceOil + priceExtra + "€"}
                        </div>
                    </div>
                    <div className="iva text-end">IVA íncluido</div>
                </div>
            </div>

            <div className="SendDataButton text-end mt-4">
                <Button
                    onClick={() => {
                        sendData();
                    }}
                    type="button"
                    buttonSize="btn-lg"
                >
                    Escolher Oficina
                </Button>
            </div>
        </div>
    );
}

export default MaintenencePackPrices;
