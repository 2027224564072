import React from "react";
//import iconMap from '../../img/rede-oficinas/fav-map.svg';
import toast from "react-hot-toast";
import { Marker } from 'react-leaflet'
import L from 'leaflet';


const MyMarker = ({ point, center, setCenter, setPointClicked }) => {
    const handleClick = () => {
        setCenter([point.lat, point.lng]);
        setPointClicked(point);
    };

    const customIcon = new L.Icon({
        iconUrl: require('../../img/rede-oficinas/fav-map.svg').default,
        iconSize: new L.Point(35, 35),
    })
    const customIconBlack = new L.Icon({
        iconUrl: require('../../img/rede-oficinas/fav-map_black.svg').default,
        iconSize: new L.Point(35, 35),
    })

    return (
        <Marker
            position={[point.lat, point.lng]}
            icon={point?.available === true ? customIcon : customIconBlack}
            eventHandlers={{
                click: (e) => {
                    handleClick();
                    toast.success(point?.title + " selecionada.", { duration: 4000 });
                },
            }}
        >
        </Marker>

    );
};

export default MyMarker;
