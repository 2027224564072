import "./AppointmentMain.css";
import { useState } from "react";
import Step1 from "./Step1/Step1.js";
import Step2 from "./Step2/Step2.js";
import Step3 from "./Step3/Step3.js";
import Step4 from "./Step4/Step4.js";
import FinalStep from "./FinalStep/FinalStep.js";
import React from "react";
import BannerInterior from "../../components/BannerInterior/BannerInterior";
import banner from "../../img/banners/redes-oficinas.jpg";

function AppointmentMain(props) {
    const [stepActive, setStepActive] = useState(1);

    function ActiveScreen() {
        switch (stepActive) {
            case 1:
                return (
                    <div>
                        <BannerInterior name="Marcação" imagem={banner} />
                        <Step1
                            setStepActive={setStepActive}
                            appointmentData={props.appointmentData}
                            setAppointmentData={props.setAppointmentData}
                        />
                    </div>
                );
            case 2:
                return (
                    <div>
                        <BannerInterior name="Marcação" imagem={banner} />
                        <Step2
                            setStepActive={setStepActive}
                            appointmentData={props.appointmentData}
                            setAppointmentData={props.setAppointmentData}
                        />
                    </div>
                );
            case 3:
                return (
                    <div>
                        <BannerInterior name="Marcação" imagem={banner} />
                        <Step3
                            setStepActive={setStepActive}
                            appointmentData={props.appointmentData}
                            setAppointmentData={props.setAppointmentData}
                        />
                    </div>
                );
            case 4:
                return (
                    <div>
                        <BannerInterior name="Marcação" imagem={banner} />
                        <Step4
                            setStepActive={setStepActive}
                            appointmentData={props.appointmentData}
                            setAppointmentData={props.setAppointmentData}
                        />
                    </div>
                );
            case 5:
                return (
                    <div>
                        <BannerInterior name="Marcação" imagem={banner} />
                        <FinalStep
                            setStepActive={setStepActive}
                            appointmentData={props.appointmentData}
                            setAppointmentData={props.setAppointmentData}
                        />
                    </div>
                );
            default:
                return <Step1 setStepActive={setStepActive} />;
        }
    }

    return <div>{ActiveScreen()}</div>;
}

export default AppointmentMain;
