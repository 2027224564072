import "./Block3.css";
import imagemLeft from "../../../img/main/block3/img-1.jpg";
import imagemRight from "../../../img/main/block3/img-2.jpg";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';


function Block3() {
    let navigate = useNavigate();

    const handleClickRedeOficinas = () => { navigate("/Rede-de-Oficinas"); };

    const { t } = useTranslation();

    return (
        <div className="section my-5">
            <div class="container-fluid p-0">
                <div class="row g-0  justify-content-center align-self-center">
                    <div class="col-md-6 position-relative">
                        <div class="position-relative my-md-3">
                            <div class="h-100 w-105 position-absolute top-0 background-cover">
                                <div class="h-100 w-100 position-absolute top-0 overlay-90 bg-red"></div>
                                <img alt="" src={imagemLeft} />
                            </div>
                            <div class="content-modulo">
                                <h5 class="section_sub_title text-white">{t('Main.Block3.sub_title')}</h5>
                                <h2 class="section_title text-white mb-4">
                                    {t('Main.Block3.title')}
                                </h2>
                                <Button
                                    onClick={handleClickRedeOficinas}
                                    type="button"
                                    buttonStyle="btn-white-text-dark"
                                >
                                    {t('Main.Block3.location')}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 position-relative">
                        <div class="h-100 w-100 position-absolute top-0 background-cover">
                            <div class="div-clip-path">
                                <div class="h-100 w-100 position-absolute top-0 overlay-60 bg-darker"></div>
                                <img alt="" src={imagemRight} />
                            </div>
                        </div>
                        <div class="content-modulo d-flex h-100">
                            <div class="row justify-content-center align-self-center">
                                <h5 class="section_sub_title text-white">{t('Main.Block3.sub_title_m')}</h5>
                                <h2 class="section_title text-white">{t('Main.Block3.title_m')}</h2>
                                <p class="text-white mb-4">
                                    {t('Main.Block3.description')}
                                </p>
                                <div>
                                    <Button
                                        onClick={() => {
                                            navigate("/Rede-de-Oficinas");
                                        }}
                                        type="button"
                                        buttonStyle="btn-white-text-dark"
                                    >
                                        {t('Main.Block3.schedule')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Block3;
