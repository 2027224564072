import "./Login.css";
import React, { useState } from "react";
import BannerInterior from "../../components/BannerInterior/BannerInterior";
import banner from "../../img/banners/redes-oficinas.jpg";
import Button from "../../components/Button";
import toast, { Toaster } from "react-hot-toast";
import axios from 'axios';
import { useNavigate, } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { addAccount, removeAllAccounts } from 'store/accounts/accounts.js';
import { CurrentUser, Loading, LoadingFixed } from 'utils/Utilities.js';

function Login() {
    var emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //Conte @ e .
    const [login, setLogin] = useState({ email: "", password: "" });
    const [newUser, setNewUser] = useState({ firstName: "", lastName: "", email: "", password: "", confirmPassword: "" });
    const dispatch = useDispatch();
    const [view, setView] = useState("login");
    const [load, setLoad] = useState(false);
    let navigate = useNavigate();
    const currentUser = CurrentUser();

    const handleLoginChange = (e) => {
        var { id, value } = e.target;
        setLogin({ ...login, [id]: value });
    }

    const handleNewUserChange = (e) => {
        var { id, value } = e.target;
        setNewUser({ ...newUser, [id]: value });
    }

    const authenticate = (login) => {
        setLoad(true)
        axios.post(`${global.api_url}Accounts/Authenticate`, login)
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem('refreshToken', response.data.refreshToken)
                    localStorage.setItem('jwtToken', response.data.jwtToken)
                    dispatch(addAccount(response.data));
                    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
                    toast.success("Login efetuado com sucesso!", { duration: 4000 });
                    navigate("/Area-de-cliente");
                }
                setLoad(false);
            }).catch(error => {
                console.log(error);
                setLoad(false);
                toast.error("Erro a efetuar o login.", { duration: 4000 });
            })
    }

    const remove = (token) => {
        setLoad(true)
        var headers = { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` };
        axios.post(`${global.api_url}Accounts/revoke-token`, { token: token }, { headers })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem('jwtToken', "")
                    localStorage.setItem('refreshToken', "")
                    dispatch(removeAllAccounts());
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "instant",
                    });
                    toast.success("Logout efetuado com sucesso!", { duration: 4000 });
                }
                setLoad(false);
            }).catch(error => {
                setLoad(false);
                toast.error(error, { duration: 4000 });
            })
    }

    const register = (user) => {
        setLoad(true)
        axios.post(`${global.api_url}Accounts/register`, user)
            .then((response) => {
                if (response.status === 200) {
                    toast.success(response?.data?.message, { duration: 4000 });
                    setView("login");
                    setNewUser({ firstName: "", lastName: "", email: "", password: "", confirmPassword: "" });
                }
                setLoad(false);
            }).catch(error => {
                setLoad(false);
                toast.error("Ocorreu um erro a efetuar o registo.", { duration: 4000 });
            })
    }

    const validate = () => {

        if (newUser.firstName.trim() === "") {
            toast.error("Tem de preencher o nome.");
        } else if (newUser.lastName.trim() === "") {
            toast.error("Tem de preencher o horário.");
        } else if (!newUser.email.match(emailPattern)) {
            toast.error("Email inválido!");
        } else if (newUser.password.trim() === "") {
            toast.error("Tem de preencher uma password");
        } else if (newUser.confirmPassword.trim() === "") {
            toast.error("Tem de preencher a confirmação da password");
        } else if (newUser.password.trim().length < 6) {
            toast.error("A password tem de conter pelo menos 6 carateres.");
        } else if (newUser.confirmPassword.trim() !== newUser.password.trim()) {
            toast.error("As password não correspondem");
        } else {
            return true;
        }
    };

    return (
        <div className="section">
            <BannerInterior name="Login" imagem={banner} />

            {view === "login" &&
                <div class="section join-us py-5">
                    <div class="container py-5">
                        <div class="row pb-0">
                            <div class="col-md-12">
                                <div class="section_sub_title text-red text-uppercase mb-4">
                                    Autênticação
                                </div>
                            </div>
                        </div>
                        {currentUser === null ?

                            <div class="row g-4 py-4">
                                <div class="col-lg-6 col-md-12">

                                    <div class="col-12 py-3">
                                        <div class="label text-gray text-uppercase">Email</div>
                                        <div className="Step1-displayshop">
                                            <input
                                                onChange={handleLoginChange}
                                                class="form-control"
                                                type="text"
                                                id="email"
                                                value={login?.email}
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="label text-gray text-uppercase">Password</div>
                                        <div className="Step1-inputbox">
                                            <div className="Step1-datecol">
                                                <input
                                                    onChange={handleLoginChange}
                                                    type="password"
                                                    id="password"
                                                    value={login?.password}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div className="buttons">
                                        <div class="d-flex">
                                            {LoadingFixed(load, 30)}
                                            {load === false && <>
                                                <Button
                                                    onClick={() => {
                                                        authenticate(login);
                                                    }}
                                                    type="button"
                                                    buttonStyle="button-loginscreen-solid"
                                                    buttonSize="button-size-large"
                                                >
                                                    Login
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setView("register");
                                                    }}
                                                    type="button"
                                                    buttonStyle="btn-black"
                                                    buttonSize="button-size-large"
                                                >
                                                    Registo
                                                </Button>


                                                <Button
                                                    onClick={() => { navigate("/Recuperar-password"); }}
                                                    type="button"
                                                    buttonStyle="btn-black"
                                                    buttonSize="button-size-large"
                                                >
                                                    Recuperar Password
                                                </Button>
                                            </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div class="row g-4 py-4">
                                <div class="col-lg-6 col-md-12">
                                </div>
                                <div class="col-lg-12">
                                    <Button
                                        onClick={() => {
                                            remove(localStorage.getItem('refreshToken'));
                                        }}
                                        type="button"
                                        buttonStyle="button-loginscreen-solid"
                                        buttonSize="button-size-medium"
                                    >
                                        Logout
                                    </Button>
                                </div>
                            </div>}
                    </div>
                </div>
            }

            {view === "register" &&
                <div class="section join-us py-5">
                    <div class="container py-5">
                        <div class="row pb-0">
                            <div class="col-md-12">
                                <div class="section_sub_title text-red text-uppercase mb-4">
                                    Criar Conta
                                </div>
                            </div>
                        </div>
                        {currentUser === null ?

                            <div class="row g-4 py-4">
                                <div class="col-lg-6 col-md-12">

                                    <div class="col-12 py-1">
                                        <div class="label text-gray text-uppercase">Nome</div>
                                        <div className="Step1-displayshop">
                                            <input
                                                onChange={handleNewUserChange}
                                                class="form-control"
                                                type="text"
                                                id="firstName"
                                                value={newUser?.firstName}
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-12 py-1">
                                        <div class="label text-gray text-uppercase">Apelido</div>
                                        <div className="Step1-inputbox">
                                            <div className="Step1-datecol">
                                                <input
                                                    onChange={handleNewUserChange}
                                                    type="text"
                                                    id="lastName"
                                                    value={newUser?.lastName}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 py-1">
                                        <div class="label text-gray text-uppercase">Email</div>
                                        <div className="Step1-inputbox">
                                            <div className="Step1-datecol">
                                                <input
                                                    onChange={handleNewUserChange}
                                                    type="text"
                                                    id="email"
                                                    value={newUser?.email}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 py-1">
                                        <div class="label text-gray text-uppercase">Password</div>
                                        <div className="Step1-inputbox">
                                            <div className="Step1-datecol">
                                                <input
                                                    onChange={handleNewUserChange}
                                                    type="password"
                                                    id="password"
                                                    value={newUser?.password}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 py-1">
                                        <div class="label text-gray text-uppercase">Password confirmação</div>
                                        <div className="Step1-inputbox">
                                            <div className="Step1-datecol">
                                                <input
                                                    onChange={handleNewUserChange}
                                                    type="password"
                                                    id="confirmPassword"
                                                    value={newUser?.confirmPassword}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 py-1">

                                    <div className="buttons">
                                        <div class="d-flex">
                                            <Button
                                                onClick={() => { if (validate()) { register(newUser); } }}
                                                type="button"
                                                buttonStyle="button-loginscreen-solid"
                                                buttonSize="button-size-large"
                                            >
                                                Criar conta
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setView("login");
                                                }}
                                                type="button"
                                                buttonStyle="btn-black"
                                                buttonSize="button-size-large"
                                            >
                                                Login
                                            </Button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            :
                            <div class="row g-4 py-4">
                                <div class="col-lg-6 col-md-12">
                                </div>
                                <div class="col-lg-12">
                                    <Button
                                        onClick={() => {
                                            remove(localStorage.getItem('refreshToken'));
                                        }}
                                        type="button"
                                        buttonStyle="button-loginscreen-solid"
                                        buttonSize="button-size-medium"
                                    >
                                        Logout
                                    </Button>
                                </div>
                            </div>}
                    </div>
                </div>
            }
        </div>
    );
}

export default Login;
