import "./Step4.css";
import Button from "../../../components/Button";
import { useState, useEffect } from "react";
import React from "react";
import toast from "react-hot-toast";
import { CurrentUser } from "../../../utils/Utilities";


function Step4(props) {
    const currentUser = CurrentUser();
    const [schedule, setSchedule] = useState(null);

    useEffect(() => {
        setSchedule(props?.appointmentData?.schedule);
    }, []);

    useEffect(() => {
        if (schedule !== null) {
            setSchedule({ ...schedule, accountId: currentUser?.id, firstName: currentUser.firstName, lastName: currentUser?.lastName, email: currentUser?.email, workShopId: currentUser?.defaultWorkshop })
        }
    }, [currentUser !== null && schedule !== null])

    const handleDataChange = (e) => {
        const { id, value } = e.target;
        setSchedule({ ...schedule, [id]: value });
    }

    var emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //Conte @ e .

    const validate = () => {
        if (schedule?.firstName?.trim() === "") {
            toast.error("Tem de preencher o nome.");
        } else if (schedule?.lastName?.trim() === "") {
            toast.error("Tem de preencher o apelido.");
        } else if (schedule?.phone?.toString()?.trim() === "") {
            toast.error("Tem de preencher o contacto.");
        } else if (schedule?.email?.trim() === "") {
            toast.error("Tem de preencher a email.");
        } else if (!schedule?.email?.match(emailPattern)) {
            toast.error("Email inválido!");
        } else {
            return true;
        }
    };

    const nextStep = () => {
        if (validate()) {
            props.setAppointmentData({ ...props.appointmentData, schedule: schedule });
            props.setStepActive(5);
        }
    };

    const previousStep = () => {
        props.setStepActive(3);
    };

    return (
        <div className="Schedule-main">
            <div class="section marcacao py-20">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-md-4 col-lg-3">
                            <div class="d-flex d-md-block">
                                <div class="step">
                                    <div class="number">1</div>
                                    <div class="name">Oficina e data</div>
                                </div>
                                <div class="step">
                                    <div class="number">2</div>
                                    <div class="name">Dados da viatura</div>
                                </div>
                                <div class="step">
                                    <div class="number">3</div>
                                    <div class="name">Serviços</div>
                                </div>
                                <div class="step active">
                                    <div class="number">4</div>
                                    <div class="name">Dados pessoais</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-8 col-lg-8 offset-lg-1">
                            <div class="title-form fw-bold my-4">
                                Marcação do Serviço de Oficina
                            </div>
                            <div class="subtitle text-gray text-uppercase mb-4">Passo 3</div>
                            <div class="title text-red fw-bold lh-1">
                                Dados
                                <br />
                                Pessoais
                            </div>
                            <div class="steps-form steps-2-form mt-5">
                                <div class="row g-3">
                                    <div class="col-sm-6">
                                        <div class="label text-gray text-uppercase mb-2">Nome</div>
                                        <input
                                            onChange={handleDataChange}
                                            value={schedule?.firstName}
                                            placeholder="Nome"
                                            type="text"
                                            id="firstName"
                                            className="form-control"
                                        />
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="label text-gray text-uppercase mb-2">Apelido</div>
                                        <input
                                            onChange={handleDataChange}
                                            placeholder="Apelido"
                                            value={schedule?.lastName}
                                            type="text"
                                            id="lastName"
                                            className="form-control"
                                        />
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="label text-gray text-uppercase mb-2">
                                            Contacto
                                        </div>
                                        <input
                                            onChange={handleDataChange}
                                            placeholder="Contacto"
                                            value={schedule?.phone}
                                            type="number"
                                            minLength={8}
                                            id="phone"
                                            className="form-control"
                                        />
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="label text-gray text-uppercase mb-2">Email</div>
                                        <input
                                            onChange={handleDataChange}
                                            placeholder="Email"
                                            type="text"
                                            value={schedule?.email}
                                            id="email"
                                            disabled={currentUser !== null ? true : false}
                                            className="form-control"
                                        />
                                    </div>
                                    <div class="col-12">

                                        <div class="marcacao-buttons text-end">
                                            <Button
                                                onClick={() => {
                                                    previousStep();
                                                }}
                                                type="button"
                                                buttonStyle="btn-black"
                                                buttonSize="button-size-large"
                                            >
                                                Voltar
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    nextStep();
                                                }}
                                                type="button"
                                                buttonSize="button-size-large"
                                            >
                                                Seguinte
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Step4;
