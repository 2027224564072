import "./OficialRevision.css";
import { useNavigate } from "react-router-dom";
import React from "react";
import BannerInterior from "../../../components/BannerInterior/BannerInterior";
import banner from "../../../img/banners/redes-oficinas.jpg";
import imgRevisaoOficial from "../../../img/revisao-oficial/revisao-oficial.png";
import { useTranslation, Trans } from 'react-i18next';

function OficialRevision() {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const details = t("WorkShopServices.OficialRevision.details", { returnObjects: true });

    return (
        <div className="App">
            <BannerInterior name={t('WorkShopServices.OficialRevision.banner_name')} imagem={banner} />
            <div class="section revisao-oficial">
                <div class="container">
                    <div class="row justify-content-center align-items-end g-0">
                        <div class="col-lg-6 col-md-12">
                            <div class="section_sub_title text-red text-uppercase mb-4 mt-5">
                                <Trans i18nKey="WorkShopServices.OficialRevision.sub_title" />
                            </div>
                            <div class="section_title fw-bold mb-4">
                                <Trans i18nKey="WorkShopServices.OficialRevision.title" />
                            </div>
                            <div class="detail mb-4">
                                <Trans i18nKey="WorkShopServices.OficialRevision.description" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-10 position-relative">
                            <div class="img-right position-relative">
                                <img src={imgRevisaoOficial} />
                                <div class="img-content">
                                    <div class="ate text-uppercase">até</div>
                                    <div class="desconto text-uppercase">-40%</div>
                                    <div class="onde">que na marca</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container py-5">
                    <div class="row gx-5 mt-lg-0 my-5">
                        <div class="col-lg-12">
                            <div class="accordion accordion-flush" id="accordionLeft">

                                {Array.isArray(details) && details.map(data => {
                                    const random = Math.round(Math.random() * 100).toString();
                                    return (

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOneLeft">
                                                <button
                                                    class="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={"#flush-collapseOneLeft" + random}//"#flush-collapseOneLeft"
                                                    aria-expanded="false"
                                                    aria-controls={"flush-collapseOneLeft" + random}//"flush-collapseOneLeft"
                                                >
                                                    {data.title}
                                                </button>
                                            </h2>
                                            <div
                                                id={"flush-collapseOneLeft" + random}//"flush-collapseOneLeft"
                                                class="accordion-collapse collapse"
                                                aria-labelledby="flush-headingOneLeft"
                                                data-bs-parent="#accordionLeft"
                                            >
                                                <div class="accordion-body">
                                                    <Trans i18nKey={data.description} />
                                                </div>
                                            </div>
                                        </div>


                                    );
                                })}




                                {/*<div class="accordion-item">*/}
                                {/*    <h2 class="accordion-header" id="flush-headingOneLeft">*/}
                                {/*        <button*/}
                                {/*            class="accordion-button collapsed"*/}
                                {/*            type="button"*/}
                                {/*            data-bs-toggle="collapse"*/}
                                {/*            data-bs-target="#flush-collapseOneLeft"*/}
                                {/*            aria-expanded="false"*/}
                                {/*            aria-controls="flush-collapseOneLeft"*/}
                                {/*        >*/}
                                {/*            De acordo com o livro de manutenção da fábrica*/}
                                {/*        </button>*/}
                                {/*    </h2>*/}
                                {/*    <div*/}
                                {/*        id="flush-collapseOneLeft"*/}
                                {/*        class="accordion-collapse collapse"*/}
                                {/*        aria-labelledby="flush-headingOneLeft"*/}
                                {/*        data-bs-parent="#accordionLeft"*/}
                                {/*    >*/}
                                {/*        <div class="accordion-body">*/}
                                {/*            <p>*/}
                                {/*                A Revisão Oficial Automóvel realiza-se de acordo com as*/}
                                {/*                especificações do livro de manutenção do seu veículo.*/}
                                {/*            </p>*/}

                                {/*            <p>*/}
                                {/*                A RedService trabalha com uma plataforma com informação*/}
                                {/*                e dados técnicos provenientes diretamente do construtor*/}
                                {/*                automóvel. Assim a RedService realiza as suas*/}
                                {/*                intervenções de acordo com o livro de manutenção do*/}
                                {/*                fabricante. A RedService oferece um extenso leque de*/}
                                {/*                garantias nos serviços de revisão, nomeadamente:*/}
                                {/*            </p>*/}

                                {/*            <p>*/}
                                {/*                • Mudança de óleo (tipo de óleo de acordo com as normas*/}
                                {/*                do fabricante).*/}
                                {/*            </p>*/}

                                {/*            <p>*/}
                                {/*                • Substituições necessárias (filtros, velas, correia de*/}
                                {/*                distribuição, gás do ar condicionado).*/}
                                {/*            </p>*/}

                                {/*            <p>• Diagnóstico Eletrónico.</p>*/}

                                {/*            <p>*/}
                                {/*                • Controlos: indica os momentos de troca de peças, tanto*/}
                                {/*                de segurança como de desgaste.*/}
                                {/*            </p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}


                                {/*<div class="accordion-item">*/}
                                {/*    <h2 class="accordion-header" id="flush-headingTwoLeft">*/}
                                {/*        <button*/}
                                {/*            class="accordion-button collapsed"*/}
                                {/*            type="button"*/}
                                {/*            data-bs-toggle="collapse"*/}
                                {/*            data-bs-target="#flush-collapseTwoLeft"*/}
                                {/*            aria-expanded="false"*/}
                                {/*            aria-controls="flush-collapseTwoLeft"*/}
                                {/*        >*/}
                                {/*            Mantém a garantia de origem*/}
                                {/*        </button>*/}
                                {/*    </h2>*/}
                                {/*    <div*/}
                                {/*        id="flush-collapseTwoLeft"*/}
                                {/*        class="accordion-collapse collapse"*/}
                                {/*        aria-labelledby="flush-headingTwoLeft"*/}
                                {/*        data-bs-parent="#accordionLeft"*/}
                                {/*    >*/}
                                {/*        <div class="accordion-body">*/}
                                {/*            <p>*/}
                                {/*                Sabia que é livre para decidir qual a oficina para fazer*/}
                                {/*                a manutenção do seu veículo, desde o primeiro dia, sem*/}
                                {/*                perder a garantia do fabricante?*/}
                                {/*            </p>*/}

                                {/*            <p>*/}
                                {/*                O Regulamento da Comissão Europeia nº 461/2010 de 27 de*/}
                                {/*                Maio, reforça, de forma efetiva, a livre concorrência no*/}
                                {/*                mercado de manutenção automóvel em todos os países da*/}
                                {/*                União Europeia. Esta norma inclui 2 pontos importantes a*/}
                                {/*                favor do consumidor:*/}
                                {/*            </p>*/}

                                {/*            <p>*/}
                                {/*                • Garantia de livre escolha da oficina automóvel pelo*/}
                                {/*                consumidor no que respeita à manutenção do veículo,*/}
                                {/*                incluindo o período em que o carro se encontra dentro da*/}
                                {/*                garantia (1)*/}
                                {/*            </p>*/}

                                {/*            <p>*/}
                                {/*                • Obrigação dos fabricantes em facilitar aos*/}
                                {/*                profissionais que intervêm na reparação automóvel, a*/}
                                {/*                totalidade das características dos veículos, dos*/}
                                {/*                fornecedores e das peças necessárias para a realização*/}
                                {/*                da reparação.*/}
                                {/*            </p>*/}

                                {/*            <p>Saiba mais sobre este regulamento aqui.</p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div class="col-lg-6">
                            {/*<div class="accordion accordion-flush" id="accordionFlushRight">*/}
                            {/*    <div class="accordion-item">*/}
                            {/*        <h2 class="accordion-header" id="flush-headingOne">*/}
                            {/*            <button*/}
                            {/*                class="accordion-button collapsed"*/}
                            {/*                type="button"*/}
                            {/*                data-bs-toggle="collapse"*/}
                            {/*                data-bs-target="#flush-collapseOne"*/}
                            {/*                aria-expanded="false"*/}
                            {/*                aria-controls="flush-collapseOne"*/}
                            {/*            >*/}
                            {/*                Diagnóstico electrónico multimarca*/}
                            {/*            </button>*/}
                            {/*        </h2>*/}
                            {/*        <div*/}
                            {/*            id="flush-collapseOne"*/}
                            {/*            class="accordion-collapse collapse"*/}
                            {/*            aria-labelledby="flush-headingOne"*/}
                            {/*            data-bs-parent="#accordionFlushRight"*/}
                            {/*        >*/}
                            {/*            <div class="accordion-body">*/}
                            {/*                <p>*/}
                            {/*                    A maioria dos veículos fabricados partir do ano 2000*/}
                            {/*                    dispõe de uma caixa eletrónica que melhora a sua*/}
                            {/*                    prestação, garantindo ao mesmo tempo a segurança e*/}
                            {/*                    comodidade durante a condução.*/}
                            {/*                </p>*/}

                            {/*                <p>*/}
                            {/*                    Uma simples avaria eletrónica pode traduzir-se na*/}
                            {/*                    imobilização do seu veículo. Por este motivo, efetuamos*/}
                            {/*                    um diagnóstico eletrónico em cada revisão através da*/}
                            {/*                    leitura dos códigos de autodiagnóstico informático. As*/}
                            {/*                    oficinas RedService contam com ferramentas de*/}
                            {/*                    diagnóstico eletrónico multimarca que nos permitem*/}
                            {/*                    efetuar a manutenção de qualquer veículo.*/}
                            {/*                </p>*/}

                            {/*                <p>*/}
                            {/*                    A Revisão Automóvel RedService é garantia de um trabalho*/}
                            {/*                    de qualidade realizado por especialistas.*/}
                            {/*                </p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div class="accordion-item">*/}
                            {/*        <h2 class="accordion-header" id="flush-headingTwo">*/}
                            {/*            <button*/}
                            {/*                class="accordion-button collapsed"*/}
                            {/*                type="button"*/}
                            {/*                data-bs-toggle="collapse"*/}
                            {/*                data-bs-target="#flush-collapseTwo"*/}
                            {/*                aria-expanded="false"*/}
                            {/*                aria-controls="flush-collapseTwo"*/}
                            {/*            >*/}
                            {/*                Porquê pagar mais se a RedService pode fazer?*/}
                            {/*            </button>*/}
                            {/*        </h2>*/}
                            {/*        <div*/}
                            {/*            id="flush-collapseTwo"*/}
                            {/*            class="accordion-collapse collapse"*/}
                            {/*            aria-labelledby="flush-headingTwo"*/}
                            {/*            data-bs-parent="#accordionFlushRight"*/}
                            {/*        >*/}
                            {/*            <div class="accordion-body">*/}
                            {/*                <p>*/}
                            {/*                    As nossas equipas efetuam com rigor qualquer operação*/}
                            {/*                    recomendada nas especificações do fabricante. Efetuamos*/}
                            {/*                    a revisão do seu carro com a mesma qualidade que o*/}
                            {/*                    concessionário oficial, mas com o preço RedService. Os*/}
                            {/*                    preços da Revisão Oficial RedService são até 40% mais*/}
                            {/*                    baratos que na marca.*/}
                            {/*                </p>*/}

                            {/*                <p>*/}
                            {/*                    <b>Porquê pagar mais se a RedService pode fazer?</b>*/}
                            {/*                </p>*/}

                            {/*                <p>*/}
                            {/*                    Os nossos serviços têm ainda 2 características*/}
                            {/*                    fundamentais:*/}
                            {/*                </p>*/}

                            {/*                <p>*/}
                            {/*                    • <b>Orçamento gratuito:</b> Fazemos um aconselhamento*/}
                            {/*                    acerca das mudanças e controlos necessários ao seu*/}
                            {/*                    veículo. Damos o orçamento grátis, para que saiba*/}
                            {/*                    previamente o custo total dos trabalhos a realizar.*/}
                            {/*                    Quando deixa o carro na RedService, sabe exatamente*/}
                            {/*                    quanto custa uma revisão completa.*/}
                            {/*                </p>*/}

                            {/*                <p>*/}
                            {/*                    • <b>Transparência:</b> comprometemo-nos que o valor da*/}
                            {/*                    fatura seja sempre igual ao valor do orçamento realizado*/}
                            {/*                </p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OficialRevision;
