import "./AboutUs.css";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import React from "react";
import BannerInterior from "../../../components/BannerInterior/BannerInterior";
import banner from "../../../img/banners/redes-oficinas.jpg";
import imgSobreNos from "../../../img/sobre-nos/sobre-nos.png";
import iconPackCheck from "../../../img/servicos-oficina/pack-check.svg";
import iconTools from "../../../img/junte-se/icon-tools.svg";
import bgTools from "../../../img/main/block6/background.svg";
import { useTranslation, Trans } from 'react-i18next';

function AboutUs() {
    let navigate = useNavigate();
    const handleButtonClick = () => { navigate("/Junte-se"); };
    const { t } = useTranslation();

    const benefits = t("RedService.AboutUs.Section1.benefits", { returnObjects: true });

    return (
        <div className="App">
            <BannerInterior /*name="A RedService"*/ name={t("NavBar.nav1")} imagem={banner} />
            <div class="section sobre-nos py-5">
                <div class="container py-5">
                    <div class="row align-items-end g-0">
                        <div class="col-lg-6 col-md-12">
                            <div class="section_sub_title text-red text-uppercase mb-4">
                                {t("RedService.AboutUs.Section1.sub_title")}
                            </div>
                            <div class="section_title fw-bold mb-4">{t("RedService.AboutUs.Section1.title")}</div>
                            <div class="detail mb-4">
                                {/*<p>*/}
                                {/*    A RedService é uma Rede Independente de Oficinas Automóveis*/}
                                {/*    multimarca 100% nacional, especialista em manutenção e*/}
                                {/*    reparação de automóveis que aposta na assistência técnica nas*/}
                                {/*    áreas de eletrónica, mecânica (serviços rápidos, pneus,*/}
                                {/*    avarias e reparações elétricas) e colisão (chapa, pintura e*/}
                                {/*    substituição de vidros).*/}
                                {/*</p>*/}
                                {/*<p>*/}
                                {/*    Nas oficinas RedService vai encontrar técnicos preparados com*/}
                                {/*    acesso aos equipamentos mais avançados para encontrar qualquer*/}
                                {/*    solução, desde os serviços rápidos aos mais complexos.*/}
                                {/*</p>*/}
                                <Trans i18nKey="RedService.AboutUs.Section1.description" />

                                <p class="font-roboto">
                                    <b>{t("RedService.AboutUs.Section1.description_footer")}</b>
                                </p>
                            </div>
                            <div class="box-vantagens bg-red text-white font-roboto py-3 px-4">
                                <div class="title-vantagens text-uppercase font-poppins fw-bold mb-3">
                                    {t("RedService.AboutUs.Section1.benefits_title")}
                                </div>
                                <div class="row">

                                    {Array.isArray(benefits) && benefits.map(benefit => {
                                        return (
                                            <div class="col-md-6">
                                                <div class="d-flex align-items-baseline mb-3">
                                                    <img class="me-2" src={iconPackCheck} />
                                                    <div><Trans i18nKey={benefit.text} /></div>
                                                </div>
                                            </div>
                                        );
                                    })}



                                    {/*<div class="col-md-6">*/}
                                    {/*    <div class="d-flex align-items-baseline mb-3">*/}
                                    {/*        <img*/}
                                    {/*            class="me-2"*/}
                                    {/*            src={iconPackCheck}*/}
                                    {/*        />*/}
                                    {/*        <div>{t("RedService.AboutUs.Section1.benefit1")}</div>*/}
                                    {/*    </div>*/}
                                    {/*    <div class="d-flex align-items-baseline mb-3">*/}
                                    {/*        <img*/}
                                    {/*            class="me-2"*/}
                                    {/*            src={iconPackCheck}*/}
                                    {/*        />*/}
                                    {/*        <div>{t("RedService.AboutUs.Section1.benefit2")}</div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div class="col-md-6">*/}
                                    {/*    <div class="d-flex align-items-baseline mb-3">*/}
                                    {/*        <img*/}
                                    {/*            class="me-2"*/}
                                    {/*            src={iconPackCheck}*/}
                                    {/*        />*/}
                                    {/*        <div>{t("RedService.AboutUs.Section1.benefit3")}</div>*/}
                                    {/*    </div>*/}
                                    {/*    <div class="d-flex align-items-baseline mb-3">*/}
                                    {/*        <img*/}
                                    {/*            class="me-2"*/}
                                    {/*            src={iconPackCheck}*/}
                                    {/*        />*/}
                                    {/*        <div>*/}
                                    {/*            {t("RedService.AboutUs.Section1.benefit4")}*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 position-relative">
                            <div class="sobre-nos-img-right text-lg-start text-center">
                                <img src={imgSobreNos} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section sobre-nos-servicos bg-sobre-nos-servicos">
                <div class="container">
                    <div class="row row-crop g-0">
                        <div class="col-lg-3 col-md-12">
                            <div class="box-crop h-100 d-flex">
                                <div>
                                    <div class="first-title font-poppins text-red fw-bold mb-3">
                                        {t("RedService.AboutUs.Section2.title")}
                                    </div>
                                    <p>
                                        <Trans i18nKey="RedService.AboutUs.Section2.description" />
                                    </p>
                                    <Button
                                        onClick={handleButtonClick}
                                        type="button"
                                        buttonSize="btn-lg"
                                    >
                                        {t("RedService.AboutUs.Section2.button")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-12">
                            <div class="box-crop h-100 d-flex">
                                <div class="row justify-content-center align-self-center">
                                    <img
                                        src={iconTools}
                                        class="me-auto mb-2"
                                    />
                                    <div class="sub-title font-poppins fw-bold d-block mb-3">
                                        <Trans i18nKey="RedService.AboutUs.Section2.first_title" />

                                    </div>
                                    <p class="mb-0">
                                        <Trans i18nKey="RedService.AboutUs.Section2.first_description" />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-12">
                            <div class="box-crop h-100 d-flex">
                                <div class="row justify-content-center align-self-center">
                                    <img
                                        src={iconTools}
                                        class="me-auto mb-2"
                                    />
                                    <div class="sub-title font-poppins fw-bold d-block mb-3">

                                        <Trans i18nKey="RedService.AboutUs.Section2.second_description" />
                                    </div>
                                    <p class="mb-0">
                                        <Trans i18nKey="RedService.AboutUs.Section2.second_description" />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-12">
                            <div class="box-crop h-100 d-flex">
                                <div class="row justify-content-center align-self-center">
                                    <img
                                        src={iconTools}
                                        class="me-auto mb-2"
                                    />
                                    <div class="sub-title font-poppins fw-bold d-block mb-3">
                                        <Trans i18nKey="RedService.AboutUs.Section2.third_description" />
                                    </div>
                                    <p class="mb-0">
                                        <Trans i18nKey="RedService.AboutUs.Section2.third_description" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section compromisso py-20">
                <img class="bg-compromisso position-absolute top-0" src={bgTools} />
                <div class="container">
                    <div class="row align-items-center g-5">
                        <div class="col-lg-6 col-md-12">
                            <div class="row g-3">
                                <div class="col-lg-6">
                                    <div class="h-100 d-flex">
                                        <div class="box-compromisso text-center bg-red text-white">
                                            <div class="title fw-bold mb-3">
                                                <Trans i18nKey="RedService.AboutUs.Section3.Blocks.0.title" />
                                            </div>
                                            <p class="mb-0">
                                                <Trans i18nKey="RedService.AboutUs.Section3.Blocks.0.description" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="h-100 d-flex">
                                        <div class="box-compromisso text-center bg-red text-white">
                                            <div class="title fw-bold mb-3">
                                                <Trans i18nKey="RedService.AboutUs.Section3.Blocks.1.title" />
                                            </div>
                                            <p class="mb-0">
                                                <Trans i18nKey="RedService.AboutUs.Section3.Blocks.1.description" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="h-100 d-flex">
                                        <div class="box-compromisso text-center bg-red text-white">
                                            <div class="title fw-bold mb-3">
                                                <Trans i18nKey="RedService.AboutUs.Section3.Blocks.2.title" />
                                            </div>
                                            <p class="mb-0">
                                                <Trans i18nKey="RedService.AboutUs.Section3.Blocks.2.description" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="h-100 d-flex">
                                        <div class="box-compromisso text-center bg-red text-white">
                                            <div class="title fw-bold mb-3">
                                                <Trans i18nKey="RedService.AboutUs.Section3.Blocks.3.title" />
                                            </div>
                                            <p class="mb-0">
                                                <Trans i18nKey="RedService.AboutUs.Section3.Blocks.3.description" />
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="section_sub_title text-red text-uppercase mb-4">
                                <Trans i18nKey="RedService.AboutUs.Section3.title" />
                            </div>
                            <div class="section_title fw-bold mb-4"><Trans i18nKey="RedService.AboutUs.Section3.title" /></div>
                            <div class="detail mb-4">
                                <Trans i18nKey="RedService.AboutUs.Section3.description" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
