import "./Pneus.css";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import React from "react";
import BannerInterior from "../../../components/BannerInterior/BannerInterior";
import banner from "../../../img/banners/redes-oficinas.jpg";
import marcasPneus from "../../../img/pneus/marcas-pneus.png";
import logoHankook from "../../../img/pneus/brand-logos-pneus/hankook.png";
import logoMichelin from "../../../img/pneus/brand-logos-pneus/michelin.png";
import logoGoodyear from "../../../img/pneus/brand-logos-pneus/goodyear.png";
import logoContinental from "../../../img/pneus/brand-logos-pneus/continental.png";
import logoBridgestone from "../../../img/pneus/brand-logos-pneus/bridgestone.png";
import logoFirestone from "../../../img/pneus/brand-logos-pneus/firestone.png";
import logoDunlop from "../../../img/pneus/brand-logos-pneus/dunlop.png";
import logoOatlas from "../../../img/pneus/brand-logos-pneus/oatlas.png";
import pressaoPneus from "../../../img/pneus/pressao-pneus.png";
import imgSeparatorRight from "../../../img/pneus/separator-right.jpg";
import medidasPneus from "../../../img/pneus/medidas-pneus.png";
import iconPackCheck from "../../../img/servicos-oficina/pack-check.svg";
import imgSeparatorLeft from "../../../img/main/block3/img-1.jpg";
import { useTranslation, Trans } from 'react-i18next';


function Pneus() {
    let navigate = useNavigate();
    const handleAppointmentClick = () => {
        navigate("/Rede-de-Oficinas");
    };
    const handleBudgetClick = () => {
        navigate("/Orcamento");
    };
    const handleTagClick = () => {
        navigate("/Servicos-de-Oficina/Pneus/Etiquetas-de-Pneus");
    };
    const { t } = useTranslation();

    const checks = t("WorkShopServices.Tires.Section2.conditions.checks", { returnObjects: true });
    const details_left = t("WorkShopServices.Tires.Section4.details_left", { returnObjects: true });
    const details_right = t("WorkShopServices.Tires.Section4.details_right", { returnObjects: true });

    return (
        <div className="App">
            <BannerInterior name={t("WorkShopServices.Tires.banner_name")} imagem={banner} />
            <div class="section marcas-pneus">
                <div class="container">
                    <div class="row mb-5">
                        <div class="col-lg-6 col-md-12 mt-5 mb-lg-0 mb-5">
                            <div class="section_sub_title text-red text-uppercase mb-4">
                                <Trans i18nKey="WorkShopServices.Tires.Section1.sub_title" />
                            </div>
                            <div class="section_title fw-bold mb-4">{t("WorkShopServices.Tires.Section1.title")}</div>
                            <div class="detail mb-4">
                                <Trans i18nKey="WorkShopServices.Tires.Section1.description" />
                                {/*<p>*/}
                                {/*    A RedService dispõe de uma seleção de pneus de várias marcas:*/}
                                {/*    pneus Michelin, Bridgestone, Continental, Goodyear, Hankook,*/}
                                {/*    Firestone entre outras. Realizamos diagnósticos gratuitos ao*/}
                                {/*    estado dos seus pneus bem como um orçamento grátis para a*/}
                                {/*    compra e troca de pneus.*/}
                                {/*</p>*/}
                                {/*<p>*/}
                                {/*    Sejam pneus de verão, pneus de inverno, pneus run flat, temos*/}
                                {/*    a melhor seleção de pneus das mais reconhecidas marcas.*/}
                                {/*</p>*/}
                            </div>
                            <div class="buttons mt-5">
                                <div class="d-flex">
                                    <Button
                                        onClick={handleAppointmentClick}
                                        type="button"
                                        buttonSize="btn-lg"
                                    >
                                        {t("WorkShopServices.Tires.Section1.button_schedule")}
                                    </Button>
                                    <Button
                                        onClick={handleBudgetClick}
                                        type="button"
                                        buttonStyle="btn-black"
                                        buttonSize="btn-lg"
                                    >
                                        {t("WorkShopServices.Tires.Section1.button_budget")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 offset-lg-1">
                            <div class="pneus-img-right">
                                <img src={marcasPneus} />
                            </div>
                        </div>
                    </div>
                    <div class="row g-5 py-5">
                        <div class="col-md-3 col-6">
                            <div class="pneus-brands">
                                <img src={logoHankook} />
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="pneus-brands">
                                <img src={logoMichelin} />
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="pneus-brands">
                                <img src={logoGoodyear} />
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="pneus-brands">
                                <img src={logoContinental} />
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="pneus-brands">
                                <img src={logoBridgestone} />
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="pneus-brands">
                                <img src={logoFirestone} />
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="pneus-brands">
                                <img src={logoDunlop} />
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="pneus-brands">
                                <img src={logoOatlas} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section pressao-pneus py-20">
                <div class="container">
                    <div class="row justify-content-center g-5">
                        <div class="col-lg-6 col-md-10 order-lg-0 order-1">
                            <img src={pressaoPneus} />
                        </div>
                        <div class="col-lg-5 offset-lg-1">
                            <div class="section_title fw-bold"><Trans i18nKey="WorkShopServices.Tires.Section2.title" /></div>
                            <div class="subtitle mb-4"><Trans i18nKey="WorkShopServices.Tires.Section2.sub_title" /></div>
                            <div class="detail mb-4">
                                <Trans i18nKey="WorkShopServices.Tires.Section2.description" />
                            </div>
                            <div class="title-conditions text-uppercase fw-bold mb-3">
                                <Trans i18nKey="WorkShopServices.Tires.Section2.conditions.title" />
                            </div>
                            <div class="conditions font-roboto">
                                <p><Trans i18nKey="WorkShopServices.Tires.Section2.conditions.sub_title" /></p>

                                {Array.isArray(checks) && checks.map((chec) => {
                                    return (
                                        <div class="d-flex align-items-baseline mb-3">
                                            <img class="me-2" src={iconPackCheck} />
                                            <div><Trans i18nKey={chec.text} /></div>
                                        </div>
                                    );
                                })}

                                {/*<div class="d-flex align-items-baseline mb-3">*/}
                                {/*    <img class="me-2" src={iconPackCheck} />*/}
                                {/*    <div>A pressão dos pneus é a ideal;</div>*/}
                                {/*</div>*/}
                                {/*<div class="d-flex align-items-baseline mb-3">*/}
                                {/*    <img class="me-2" src={iconPackCheck} />*/}
                                {/*    <div>Os sulcos dos pneus são suficientemente profundos;</div>*/}
                                {/*</div>*/}
                                {/*<div class="d-flex align-items-baseline mb-3">*/}
                                {/*    <img class="me-2" src={iconPackCheck} />*/}
                                {/*    <div>A geometria e o equilíbrio estão corretos.</div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section separator">
                <div class="container-fluid p-0">
                    <div class="row g-0 justify-content-center align-self-center">
                        <div class="col-md-6 position-relative">
                            <div class="position-relative my-md-3">
                                <div class="h-100 w-105 position-absolute top-0 background-cover">
                                    <div class="h-100 w-100 position-absolute top-0 overlay-90 bg-red"></div>
                                    <img src={imgSeparatorLeft} />
                                </div>
                                <div class="content-modulo">
                                    <h5 class="section_sub_title text-white"><Trans i18nKey="WorkShopServices.Tires.Section3.left.sub_title" /></h5>
                                    <h2 class="section_title text-white mb-4">

                                        <Trans i18nKey="WorkShopServices.Tires.Section3.left.title" />
                                    </h2>
                                    <Button
                                        buttonStyle="btn-white-text-dark"
                                        type="button"
                                        onClick={handleAppointmentClick}
                                    >
                                        <Trans i18nKey="WorkShopServices.Tires.Section3.left.button" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 position-relative">
                            <div class="h-100 w-100 position-absolute top-0 background-cover">
                                <div class="div-clip-path">
                                    <div class="h-100 w-100 position-absolute top-0 overlay-60 bg-darker"></div>
                                    <img src={imgSeparatorRight} />
                                </div>
                            </div>
                            <div class="content-modulo d-flex h-100">
                                <div class="row justify-content-center align-self-center">
                                    <h5 class="section_sub_title text-white"><Trans i18nKey="WorkShopServices.Tires.Section3.right.sub_title" /></h5>
                                    <h2 class="section_title text-white">
                                        <Trans i18nKey="WorkShopServices.Tires.Section3.right.title" />
                                    </h2>
                                    <p class="text-white mb-4">
                                        <Trans i18nKey="WorkShopServices.Tires.Section3.right.description" />
                                    </p>
                                    <div>
                                        <Button
                                            buttonStyle="btn-white-text-dark"
                                            type="button"
                                            onClick={handleTagClick}
                                        >

                                            <Trans i18nKey="WorkShopServices.Tires.Section3.right.button" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section medidas py-20">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-7 text-center mb-4">
                            <div class="section_title fw-bold mb-4"><Trans i18nKey="WorkShopServices.Tires.Section4.title" /></div>
                            <div class="detail mb-4">
                                <p>
                                    <Trans i18nKey="WorkShopServices.Tires.Section4.description" />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-8 text-center">
                            <img src={medidasPneus} />
                        </div>
                    </div>
                    <div class="row gx-5 my-5">
                        <div class="col-lg-6">
                            <div class="accordion accordion-flush" id="accordionLeft">
                                {Array.isArray(details_left) && details_left.map(detail => {
                                    const random = Math.round(Math.random() * 100).toString();
                                    return (
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOneLeft">
                                                <button
                                                    class="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={"#flush-collapseOneLeft" + random}
                                                    aria-expanded="false"
                                                    aria-controls={"flush-collapseOneLeft" + random}
                                                >
                                                    <Trans i18nKey={detail.title} />
                                                </button>
                                            </h2>
                                            <div
                                                id={"flush-collapseOneLeft" + random}
                                                class="accordion-collapse collapse"
                                                aria-labelledby="flush-headingOneLeft"
                                                data-bs-parent="#accordionLeft"
                                            >
                                                <div class="accordion-body">
                                                    <p>
                                                        <Trans i18nKey={detail.description} />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}



                                {/*<div class="accordion-item">*/}
                                {/*    <h2 class="accordion-header" id="flush-headingOneLeft">*/}
                                {/*        <button*/}
                                {/*            class="accordion-button collapsed"*/}
                                {/*            type="button"*/}
                                {/*            data-bs-toggle="collapse"*/}
                                {/*            data-bs-target="#flush-collapseOneLeft"*/}
                                {/*            aria-expanded="false"*/}
                                {/*            aria-controls="flush-collapseOneLeft"*/}
                                {/*        >*/}
                                {/*            A. (205)*/}
                                {/*        </button>*/}
                                {/*    </h2>*/}
                                {/*    <div*/}
                                {/*        id="flush-collapseOneLeft"*/}
                                {/*        class="accordion-collapse collapse"*/}
                                {/*        aria-labelledby="flush-headingOneLeft"*/}
                                {/*        data-bs-parent="#accordionLeft"*/}
                                {/*    >*/}
                                {/*        <div class="accordion-body">*/}
                                {/*            <p>*/}
                                {/*                Corresponde à largura do pneu em mm. No nosso exemplo, o*/}
                                {/*                pneu tem 225 mm de largura.*/}
                                {/*            </p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}



                                {/*<div class="accordion-item">*/}
                                {/*    <h2 class="accordion-header" id="flush-headingTwoLeft">*/}
                                {/*        <button*/}
                                {/*            class="accordion-button collapsed"*/}
                                {/*            type="button"*/}
                                {/*            data-bs-toggle="collapse"*/}
                                {/*            data-bs-target="#flush-collapseTwoLeft"*/}
                                {/*            aria-expanded="false"*/}
                                {/*            aria-controls="flush-collapseTwoLeft"*/}
                                {/*        >*/}
                                {/*            B. (55)*/}
                                {/*        </button>*/}
                                {/*    </h2>*/}
                                {/*    <div*/}
                                {/*        id="flush-collapseTwoLeft"*/}
                                {/*        class="accordion-collapse collapse"*/}
                                {/*        aria-labelledby="flush-headingTwoLeft"*/}
                                {/*        data-bs-parent="#accordionLeft"*/}
                                {/*    >*/}
                                {/*        <div class="accordion-body">*/}
                                {/*            <p>*/}
                                {/*                Indica a série do pneu, ou seja, a relação entre a*/}
                                {/*                altura do flanco e a largura da secção do pneu. No nosso*/}
                                {/*                exemplo, a altura do flanco corresponde a 55% da largura*/}
                                {/*                do pneu. Quanto menor a proporção de elevação do pneu,*/}
                                {/*                menor a altura do flanco. Observações: Quanto menor a*/}
                                {/*                altura do flanco, melhor a aderência nas curvas; neste*/}
                                {/*                caso, a condução também é menos confortável.*/}
                                {/*            </p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div class="accordion-item">*/}
                                {/*    <h2 class="accordion-header" id="flush-headingThreeLeft">*/}
                                {/*        <button*/}
                                {/*            class="accordion-button collapsed"*/}
                                {/*            type="button"*/}
                                {/*            data-bs-toggle="collapse"*/}
                                {/*            data-bs-target="#flush-collapseThreeLeft"*/}
                                {/*            aria-expanded="false"*/}
                                {/*            aria-controls="flush-collapseThreeLeft"*/}
                                {/*        >*/}
                                {/*            B. (R)*/}
                                {/*        </button>*/}
                                {/*    </h2>*/}
                                {/*    <div*/}
                                {/*        id="flush-collapseThreeLeft"*/}
                                {/*        class="accordion-collapse collapse"*/}
                                {/*        aria-labelledby="flush-headingThreeLeft"*/}
                                {/*        data-bs-parent="#accordionLeft"*/}
                                {/*    >*/}
                                {/*        <div class="accordion-body">*/}
                                {/*            <p>*/}
                                {/*                Trata-se da construção interna do pneu. R indica que a*/}
                                {/*                construção é “radial”. Praticamente todos os pneus em*/}
                                {/*                circulação têm atualmente uma construção radial. Isto*/}
                                {/*                significa que os cabos têxteis da carcaça atravessam o*/}
                                {/*                pneu de uma pega (também designada “calcanhar”) à outra.*/}
                                {/*            </p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="accordion accordion-flush" id="accordionFlushRight">


                                {Array.isArray(details_right) && details_right.map(detail => {
                                    const random = Math.round(Math.random() * 100).toString();
                                    return (
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOne">
                                                <button
                                                    class="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={"#flush-collapseOne" + random}
                                                    aria-expanded="false"
                                                    aria-controls={"flush-collapseOne" + random}
                                                >
                                                    <Trans i18nKey={detail.title} />
                                                </button>
                                            </h2>
                                            <div
                                                id={"flush-collapseOne" + random}
                                                class="accordion-collapse collapse"
                                                aria-labelledby="flush-headingOne"
                                                data-bs-parent="#accordionFlushRight"
                                            >
                                                <div class="accordion-body">
                                                    <p>
                                                        <Trans i18nKey={detail.description} />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        );

                                })}




                                {/*<div class="accordion-item">*/}
                                {/*    <h2 class="accordion-header" id="flush-headingOne">*/}
                                {/*        <button*/}
                                {/*            class="accordion-button collapsed"*/}
                                {/*            type="button"*/}
                                {/*            data-bs-toggle="collapse"*/}
                                {/*            data-bs-target="#flush-collapseOne"*/}
                                {/*            aria-expanded="false"*/}
                                {/*            aria-controls="flush-collapseOne"*/}
                                {/*        >*/}
                                {/*            C. (16)*/}
                                {/*        </button>*/}
                                {/*    </h2>*/}
                                {/*    <div*/}
                                {/*        id="flush-collapseOne"*/}
                                {/*        class="accordion-collapse collapse"*/}
                                {/*        aria-labelledby="flush-headingOne"*/}
                                {/*        data-bs-parent="#accordionFlushRight"*/}
                                {/*    >*/}
                                {/*        <div class="accordion-body">*/}
                                {/*            <p>*/}
                                {/*                Trata-se do diâmetro interno do pneu, por outras*/}
                                {/*                palavras, a altura da jante. Este diâmetro é indicado em*/}
                                {/*                polegadas. No nosso exemplo, o diâmetro é de 17*/}
                                {/*                polegadas.*/}
                                {/*            </p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div class="accordion-item">*/}
                                {/*    <h2 class="accordion-header" id="flush-headingTwo">*/}
                                {/*        <button*/}
                                {/*            class="accordion-button collapsed"*/}
                                {/*            type="button"*/}
                                {/*            data-bs-toggle="collapse"*/}
                                {/*            data-bs-target="#flush-collapseTwo"*/}
                                {/*            aria-expanded="false"*/}
                                {/*            aria-controls="flush-collapseTwo"*/}
                                {/*        >*/}
                                {/*            D. (91)*/}
                                {/*        </button>*/}
                                {/*    </h2>*/}
                                {/*    <div*/}
                                {/*        id="flush-collapseTwo"*/}
                                {/*        class="accordion-collapse collapse"*/}
                                {/*        aria-labelledby="flush-headingTwo"*/}
                                {/*        data-bs-parent="#accordionFlushRight"*/}
                                {/*    >*/}
                                {/*        <div class="accordion-body">*/}
                                {/*            <p>*/}
                                {/*                Valor que corresponde ao índice de carga. Indica a carga*/}
                                {/*                máxima que o pneu pode suportar quando enche devido à*/}
                                {/*                pressão máxima de segurança. No nosso exemplo, isso não*/}
                                {/*                significa que o pneu pode suportar 97kg, mas que o*/}
                                {/*                índice é de 97; neste caso, é preciso consultar a tabela*/}
                                {/*                de correspondências que determina a carga em kg para*/}
                                {/*                cada índice. Para um índice de 97, a carga máxima*/}
                                {/*                suportada é de 730 kg/pneu.*/}
                                {/*            </p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div class="accordion-item">*/}
                                {/*    <h2 class="accordion-header" id="flush-headingThree">*/}
                                {/*        <button*/}
                                {/*            class="accordion-button collapsed"*/}
                                {/*            type="button"*/}
                                {/*            data-bs-toggle="collapse"*/}
                                {/*            data-bs-target="#flush-collapseThree"*/}
                                {/*            aria-expanded="false"*/}
                                {/*            aria-controls="flush-collapseThree"*/}
                                {/*        >*/}
                                {/*            E. (V)*/}
                                {/*        </button>*/}
                                {/*    </h2>*/}
                                {/*    <div*/}
                                {/*        id="flush-collapseThree"*/}
                                {/*        class="accordion-collapse collapse"*/}
                                {/*        aria-labelledby="flush-headingThree"*/}
                                {/*        data-bs-parent="#accordionFlushRight"*/}
                                {/*    >*/}
                                {/*        <div class="accordion-body">*/}
                                {/*            <p>*/}
                                {/*                Corresponde ao índice de velocidade e indica a*/}
                                {/*                velocidade máxima de certificação do pneu para poder*/}
                                {/*                suportar uma carga com toda a segurança.*/}
                                {/*            </p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Pneus;
