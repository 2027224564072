import "./App.css";
import { Toaster } from "react-hot-toast";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
} from "react-router-dom";
import { useState, useLayoutEffect } from "react";


// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import Main from "./pages/Main/Main.js";
import RedeDeOficinas from "./pages/RedeDeOficinas/RedeDeOficinas.js";
import Services from "./pages/RedService/Services/Services";
import AboutUs from "./pages/RedService/AboutUs/AboutUs";
import NavBar from "./components/NavBar";
import Contacts from "./pages/Contacts/Contacts.js";
import AppointmentMain from "./pages/Appointment/AppointmentMain.js";
import ProfessionalRevision from "./pages/ServiçosDeOficina/MaintenencePacks/ProfessionRevision/ProfessionalRevision";
import Join from "./pages/Join/Join.js";
import RegisterCard from "./pages/RegisterCard/RegisterCard.js";
import SimpleRevision from "./pages/ServiçosDeOficina/MaintenencePacks/SimpleRevision/SimpleRevision.js";
import TotalRevision from "./pages/ServiçosDeOficina/MaintenencePacks/TotalRevision/TotalRevision";
import MaintenencePacksCarMain from "./pages/ServiçosDeOficina/MaintenencePacks/MaintenencePacksCarMain.js";
import Conselhos from "./pages/Conselhos/Conselhos.js";
import Budget from "./pages/Budget/Budget";
import Campanhas from "./pages/Campanhas/Campanhas";
import Acordos from "./pages/Acordos/Acordos";
import OficialRevision from "./pages/ServiçosDeOficina/OficialRevision/OficialRevision";
import Pneus from "./pages/ServiçosDeOficina/Pneus/Pneus";
import Footer from "./components/Footer";
import Tag from "./pages/ServiçosDeOficina/Pneus/Tag/Tag";
import Policy from "./pages/Policy/Policy";
import Details from "./pages/Conselhos/Details/Details";
import Login from "./pages/Login/Login";
import AreaDeCliente from './pages/AreaDeCliente/AreaDeCliente';
import RecuperarPassword from './pages/Login/Forgot/Forgot';
import Page404 from './pages/Page404/Page404';
import { useEffect } from "react";
import axios from 'axios';
import { useDispatch } from 'react-redux'
import { addAccount } from 'store/accounts/accounts.js';
import ReactGA from 'react-ga';



function App() {
    const dispatch = useDispatch();
    useEffect(() => {
        ReactGA.initialize('G-5F9CJNK2BT');
        ReactGA.pageview('Init page view');
    }, []);

    useEffect(() => {
        //Refresh login after load (F5)
        const refreshLogin = () => {
            if (localStorage.getItem('refreshToken') !== null && localStorage.getItem('refreshToken') !== undefined && localStorage.getItem('refreshToken') !== "" && localStorage.getItem('jwtToken') !== null && localStorage.getItem('jwtToken') !== undefined && localStorage.getItem('jwtToken') !== "") {
                var headers = { 'Accept': '*', 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
                axios.post(global.api_url + "Accounts/refresh-token", { 'token': localStorage.getItem('refreshToken') }, { headers }).then(response => {
                    if (response.status === 200) {
                        localStorage.setItem('refreshToken', response.data.refreshToken);
                        localStorage.setItem('jwtToken', response.data.jwtToken);
                        dispatch(addAccount(response.data));
                    };

                }).catch(error => {
                    localStorage.setItem('refreshToken', "");
                    localStorage.setItem('jwtToken', "")
                });
            }
        }
        refreshLogin();
    }, []);


    const ScrollToTop = ({ children }) => {
        const location = useLocation();
        useLayoutEffect(() => {
            document.documentElement.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant",
            });
        }, [location.pathname]);
    };

    const appointmentDataExample = [
        {
            date: "",
            time: [{ value: "", text: "" }],
            brand: [{ value: "", text: "" }],
            model: "",
            version: "",
            year: "",
            kms: "",
            name: "",
            contact: "",
            email: "",
            agreement: false,
            shop: "oficinaTeste",
            shopId: 1,
            pack: "Nenhum",
            oilType: "Nenhum",
            extraService: "Nenhum",
            finalPrice: 0,
            amortecedores: false,
            arCondicionado: false,
            bateria: false,
            mudançaDeOleosENiveis: false,
            pneus: false,
            travões: false,
            ipo: false,
            revisãoPeriódica: false,
            outro: false,
            outroText: "",
            obersvações: "",
        },
    ];

    const [appointmentData, setAppointmentData] = useState(
        appointmentDataExample[0]
    );

    return (
        <div className="App">
            <Toaster
                position="bottom-center"
                toastOptions={{
                    className: "",
                    style: {
                        border: "1px solid",
                        padding: "16px",
                    },
                }}
            />
            <Router>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <Main />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        path="/Acordos"
                        element={
                            <>
                                <ScrollToTop />
                                <Acordos />
                            </>
                        }
                    />
                    <Route
                        path="/Campanhas"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <Campanhas />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        path="/Login"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <Login />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        path="/Conselhos"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <Conselhos />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        path="/Conselhos/Detalhe"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <Details />
                                <Footer />
                            </>
                        }
                    />

                    <Route path="*" element={
                        <>
                            {/*<h1>Erro página não encontrada!</h1>*/}
                            <NavBar />
                            <ScrollToTop />
                            <Page404 />
                            <Footer />
                        </>
                    } />
                    <Route
                        path="/RedService/Sobre-Nos"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <AboutUs />
                                <Footer />
                            </>
                        }
                    />
                    <Route
                        path="/RedService/Servicos"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <Services />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        path="/Rede-de-Oficinas"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />{" "}
                                <RedeDeOficinas
                                    appointmentData={appointmentData}
                                    setAppointmentData={setAppointmentData}
                                />
                                <Footer />
                            </>
                        }
                    />
                    <Route
                        exact
                        to
                        path="/Marcacao"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <AppointmentMain
                                    appointmentData={appointmentData}
                                    setAppointmentData={setAppointmentData}
                                />
                                <Footer />
                            </>
                        }
                    />
                    <Route
                        path="/Junte-se"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <Join />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        path="/RegistoCartao"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <RegisterCard />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        path="/Servicos-de-Oficina/Manutencao-e-Reparacao"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <MaintenencePacksCarMain />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        exact
                        to
                        path="/Servicos-de-Oficina/Manutencao-e-Reparacao/Simples"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />{" "}
                                <SimpleRevision
                                    appointmentData={appointmentData}
                                    setAppointmentData={setAppointmentData}
                                />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        exact
                        to
                        path="/Servicos-de-Oficina/Manutencao-e-Reparacao/Profissional"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />{" "}
                                <ProfessionalRevision
                                    appointmentData={appointmentData}
                                    setAppointmentData={setAppointmentData}
                                />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        exact
                        to
                        path="/Servicos-de-Oficina/Manutencao-e-Reparacao/Total"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />{" "}
                                <TotalRevision
                                    appointmentData={appointmentData}
                                    setAppointmentData={setAppointmentData}
                                />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        path="/Servicos-de-Oficina/Revisao-Oficial"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <OficialRevision />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        path="/Servicos-de-Oficina/Pneus"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <Pneus />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        path="/Servicos-de-Oficina/Pneus/Etiquetas-de-Pneus"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <Tag />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        path="/PoliticaPrivacidade"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <Policy />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        exact
                        to
                        path="/Orcamento"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />{" "}
                                <Budget
                                    appointmentData={appointmentData}
                                    setAppointmentData={setAppointmentData}
                                />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        path="/Contactos"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <Contacts />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        path="/Area-de-cliente"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <AreaDeCliente />
                                <Footer />
                            </>
                        }
                    />

                    <Route
                        path="/Recuperar-password"
                        element={
                            <>
                                <NavBar />
                                <ScrollToTop />
                                <RecuperarPassword />
                                <Footer />
                            </>
                        }
                    />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
