import logo from '../img/apoio/logo.svg';
import icon1 from '../img/footer/icon-1.svg';
import icon2 from '../img/footer/icon-2.svg';
import icon3 from '../img/footer/icon-3.svg';
import redesocial1 from '../img/footer/icon-facebook.svg';
import redesocial2 from '../img/footer/icon-insta.svg';
import background from '../img/footer/background.svg';
import Button from './Button';
import { NavLink } from "react-router-dom";
import './Footer.css';
import React from "react";
import { useNavigate, } from 'react-router-dom';
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import axios from 'axios';


function Footer() {

    let navigate = useNavigate();
    const [emailNewsletterTemporary, setEmailNewsletterTemporary] = useState("");
    const [emailNewsletter, setEmailNewsletter] = useState("");
    const { t } = useTranslation();

    const handleEmailChange = (e) => {
        setEmailNewsletterTemporary(e.target.value);
    };

    const handleSubscribeButton = () => {
        var emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //Conte @ e .
        if (emailNewsletterTemporary !== "") {

            if (emailNewsletterTemporary.match(emailPattern)) {
                setEmailNewsletter(emailNewsletterTemporary);
                sendNewsletter(emailNewsletterTemporary);
            } else {
                toast.error("Email num formato incorreto!");
            }
        }
        else {
            toast.error("Email Vazio!");
        }
    };


    const sendNewsletter = (email) => {
        axios.post(`${global.api_url}Newsletter`, { email: email })
            .then((response) => {
                toast.success(emailNewsletterTemporary + " subscrito com sucesso", { duration: 4000 });
                setEmailNewsletterTemporary("");
            }).catch(error => {
                toast.error("Erro a subscrever o email" + emailNewsletterTemporary, { duration: 4000 });
            })
    }

    return (
        <div className="section">
            <footer className="bg-darker">
                <div className="container">
                    <div className="row border-bottom pb-5">
                        <div className="col-md-6 col-lg-3 mb-4">
                            <img alt="" className="logo-footer" alt={"logo"} src={logo} />
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4">
                            <img alt="" className="icon-footer mb-3" alt={t("Footer.title1")} src={icon1} />
                            <h4 className="mb-2">{t("Footer.title1")}</h4>
                            <Button onClick={() => { navigate("/Junte-se"); }} type="button" buttonStyle="btn-saber-mais">
                                {t("Footer.button1")}
                            </Button>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4">
                            <img alt="" className="icon-footer mb-3" alt={t("Footer.title2")} src={icon3} />
                            <h4 className="mb-2">{t("Footer.title2")}</h4>
                            <Button onClick={() => { navigate("/Rede-de-Oficinas"); }} type="button" buttonStyle="btn-saber-mais">
                                {t("Footer.button2")}
                            </Button>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4">
                            <img alt={t("Footer.title3")} className="icon-footer mb-3" src={icon2} />
                            <h4 className="mb-2">{t("Footer.title3")}</h4>
                            <Button onClick={() => { navigate("/Contactos"); }} type="button" buttonStyle="btn-saber-mais">
                                {t("Footer.button3")}
                            </Button>
                        </div>
                    </div>
                    <div className="row pt-5 pb-3">
                        <div className="col-md-6 col-lg-3 mb-4">
                            <h5>{t("Footer.title_contact")}</h5>
                            {/*<p>*/}
                            {/*    Rua de Exemplo, nº 0<br />*/}
                            {/*    0000-000 Localidade*/}
                            {/*</p>*/}
                            {/*<p>*/}
                            {/*    +351 900 000 000*/}
                            {/*</p>*/}
                            <p>
                                geral@redservice.pt
                            </p>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4">
                            <h5>{t("Footer.info")}</h5>
                            <div className="menu-footer">
                                <NavLink to="/PoliticaPrivacidade" className="nav-link pb-2"  >
                                    {t("Footer.politic")}
                                </NavLink>
                                {/*<NavLink to="/PoliticaPrivacidade" className="nav-link pb-2" >*/}
                                {/*    {t("Footer.terms")}*/}
                                {/*</NavLink>*/}
                                <a target="_blank" rel="noopener noreferrer" href="https://www.livroreclamacoes.pt/Inicio/" className="nav-link pb-2" >
                                    {t("Footer.complaint")}
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4">
                            <h5>Links</h5>
                            <div className="menu-footer">
                                <a target="_blank" rel="noopener noreferrer" href="https://www.redservice.pt/Portals/2/Docs/regulamento_461-2010.pdf" className="nav-link pb-2" >
                                    {t("Footer.warranty")}
                                </a>
                                <NavLink to="/RegistoCartao" className="nav-link pb-2" >
                                    {t("Footer.card")}
                                </NavLink>
                                <NavLink to="/Contactos" className="nav-link pb-2" >
                                    {t("Footer.contact")}
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4">
                            <h5>{t("Footer.newsletter")}</h5>
                            <div className="input-group mb-4">
                                <input type="text" className="form-control newsletter" placeholder="Email" onChange={handleEmailChange} value={emailNewsletterTemporary} />
                                <button className="btn btn-red btn-newsletter" type="button" onClick={handleSubscribeButton}>{t("Footer.subscription")}</button>
                            </div>
                            <div className="social-networks d-inline-block">
                                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/OficinasRedService/">
                                    <img alt="Facebook RedService" className="me-2" src={redesocial1} />
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/redservice_oficina/">
                                    <img alt="Instagram RedService" className="me-2" src={redesocial2} />
                                </a>
                            </div>
                            <img alt="" className="footer-bg position-absolute" src={background} />
                        </div>
                    </div>
                </div>
            </footer>
            <div className="copyright bg-red text-white position-relative py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <p className="small mb-0">RedService © {new Date().getFullYear()}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;