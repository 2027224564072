import "./Step3.css";
import Button from "../../../components/Button";
//import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from 'axios';

function Step3(props) {
    const [schedule, setSchedule] = useState(null);
    const [observacoes, setObservacoes] = useState("");
    const [serviceTypes, setServiceTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setSchedule(props?.appointmentData?.schedule);
        GetServiceTypes();
    }, []);

    const handleObersvacoesChange = (e) => { setObservacoes(e.target.value); };

    const nextStep = () => {
        if (schedule?.services?.length > 0) {
            //console.log(schedule);
            props.setAppointmentData({ ...props.appointmentData, serviceTypes: serviceTypes, schedule: schedule });
            props.setStepActive(4);
        } else {
            toast.error("Necessário selecionar pelo menos um serviço.");
        }
    };

    const previousStep = () => { props.setStepActive(2); };

    const GetServiceTypes = () => {
        setLoading(true);
        axios.get(global.api_url + "ServiceTypes")
            .then((response) => {
                var data = response.data;
                data.map(d => {
                    if (props.appointmentData.schedule?.services?.length > 0) {
                        if (props.appointmentData.schedule.services.find(x=> x.id === d.id)) {
                            d.check = true;
                        } else {
                            d.check = false;
                        }
                    } else {
                        d.check = false;
                    }
                })
                setLoading(false);
                setServiceTypes(data);
            }).catch(error => {
                setServiceTypes([]);
                setLoading(false);
                toast.error(error, { duration: 4000, });
            })
    }


    return (
        <div className="Schedule-main">
            <div class="section marcacao py-20">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-md-4 col-lg-3">
                            <div class="d-flex d-md-block">
                                <div class="step">
                                    <div class="number">1</div>
                                    <div class="name">Oficina e data</div>
                                </div>
                                <div class="step">
                                    <div class="number">2</div>
                                    <div class="name">Dados da viatura</div>
                                </div>
                                <div class="step active">
                                    <div class="number">3</div>
                                    <div class="name">Serviços</div>
                                </div>
                                <div class="step">
                                    <div class="number">4</div>
                                    <div class="name">Dados pessoais</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8 col-lg-8 offset-lg-1">
                            <div class="title-form fw-bold my-4">
                                Marcação do Serviço de Oficina
                            </div>
                            <div class="subtitle text-gray text-uppercase mb-4">Passo 3</div>
                            <div class="title text-red fw-bold lh-1">Serviços</div>
                            <div class="steps-form steps-2-form mt-5">
                                <div class="row g-3">
                                    <div class="col-12">
                                        <div class="label text-gray text-uppercase mb-2">
                                            Indique o(s) serviço(s) que pretende:
                                        </div>
                                        <div class="row justify-content-between gy-3">

                                            {serviceTypes !== null && serviceTypes.map(serv => {
                                                if (!serv.name.startsWith("Outro")) {

                                                    return (
                                                        <div class="col-md-4">
                                                            <div class="form-check form-check-inline">
                                                                <input
                                                                    checked={serv?.check}
                                                                    onChange={() => {
                                                                        serv.check = serv?.check === false ? true : false;

                                                                        if (serv?.check === true) {
                                                                            setSchedule({ ...schedule, services: [...schedule.services, { id: serv?.id, name: serv?.name, description: serv?.description }] });
                                                                        } else {
                                                                            var aux = schedule?.services?.filter(x => x.id !== serv.id);
                                                                            setSchedule({ ...schedule, services: aux });
                                                                        }
                                                                    }}
                                                                    type="checkbox"
                                                                    id={serv?.id}
                                                                    className="form-check-input"
                                                                />
                                                                <label class="form-check-label" for={serv?.id}>
                                                                    {serv?.name}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    );


                                                } else {

                                                    return (<>
                                                        <div class="col-md-12 mt-2">
                                                            <div class="form-check form-check-inline w-100 d-flex align-items-center">
                                                                <input
                                                                    checked={serv?.check}
                                                                    onChange={() => {

                                                                        serv.check = serv?.check === false ? true : false;

                                                                        if (serv?.check === true) {
                                                                            setSchedule({ ...schedule, services: [...schedule.services, { id: serv?.id, name: serv?.name, description: serv?.description }] });
                                                                        } else {
                                                                            var aux = schedule?.services?.filter(x => x.id !== serv.id);
                                                                            setSchedule({ ...schedule, services: aux })
                                                                        }
                                                                    }}
                                                                    type="checkbox"
                                                                    id="outro"
                                                                    className="form-check-input"
                                                                />
                                                                <label class="form-check-label ms-2" for="outro">
                                                                    Outro
                                                                </label>
                                                                <input
                                                                    value={serv?.check === true ? serv?.description : ""}
                                                                    onChange={(e) => {
                                                                        if (serv?.check === true) {
                                                                            const newState = schedule?.services?.map(obj => {
                                                                                if (obj.id === serv.id) { return { ...obj, description: e.target.value }; }
                                                                                return obj;
                                                                            });
                                                                            setSchedule({ ...schedule, services: newState })
                                                                        }
                                                                    }}
                                                                    type="text"
                                                                    id="outro"
                                                                    className="form-control outro ms-2"
                                                                />
                                                            </div>
                                                        </div>
                                                    </>);
                                                }
                                            })}

                                            <div class="col-12 mt-5">
                                                <textarea
                                                    value={observacoes}
                                                    onChange={handleObersvacoesChange}
                                                    placeholder="Observações"
                                                    className="form-control"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div class="marcacao-buttons text-end">
                                        <Button
                                            onClick={() => {
                                                previousStep();
                                            }}
                                            type="button"
                                            buttonStyle="btn-black"
                                            buttonSize="button-size-large"
                                        >
                                            Voltar
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                nextStep();
                                            }}
                                            type="button"
                                            buttonSize="button-size-large"
                                        >
                                            Seguinte
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Step3;





